import React from 'react'
import LogoHeader from './LogoHeader'
import UserMenu from './UserMenu'
import LogoutButton from './LogoutButton'
import { Trans, t } from '@lingui/macro'
import IconHome from '../icons/IconHome'
import { NavLink } from 'react-router-dom'

interface HeaderProps {
    handleToggleMenu: () => void
    toggleMenu: boolean
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
    const { handleToggleMenu, toggleMenu } = props

    return (
        <>
            <div className='container flex justify-between items-center lg:pt-[21px] lg:pb-[19px] lg-max:bg-light-gray lg-max:mb-16'>
                <div className='lg:ml-[50px] h-[34px] w-[94px] lg-max:absolute lg-max:top-12 lg-max:m-auto lg-max:left-0 lg-max:right-0'>
                    <LogoHeader />
                </div>
                <div className='flex mr-[50px] items-center lg-max:w-full lg-max:justify-between lg-max:mx-[30px]'>
                    <UserMenu />
                    <div>
                        <LogoutButton />
                    </div>
                    <div className='lg:hidden flex items-center'>
                        <NavLink
                            to='/'
                            title={t`Domov`}
                            className='mr-3'>
                            <IconHome/>
                        </NavLink>
                        <p className='font-basic-black mr-3'><Trans>Admin panel</Trans></p>
                        <button
                            onClick={(e) => {
                                e.preventDefault()
                                handleToggleMenu()
                            }}
                            id="nav-icon"
                            className={`button ${(toggleMenu ? '' : 'open')}`} title="Menu">
                            <div className="span-box">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header
