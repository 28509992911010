import React from 'react'
import IconAvatarWoman from '../icons/IconAvatarWoman'
import IconAvatarMan from '../icons/IconAvatarMan'
import IconAvatarHotel from '../icons/IconAvatarHotel'

interface AvatarProps {
    imageUrl?: string | null
    classAvatar: string
    type?: string
}

const Avatar: React.FC<AvatarProps> = ({ imageUrl, classAvatar, type }) => {
    let avatarContent

    if (imageUrl === null) {
        switch (type) {
            case 'male':
                avatarContent = <IconAvatarMan />
                break
            case 'female':
                avatarContent = <IconAvatarWoman />
                break
            case 'hotel':
                avatarContent = <IconAvatarHotel />
                break
        }
    } else {
        avatarContent = <img className={`${classAvatar} rounded-full`} src={'/storage/' + imageUrl} alt="" />
    }

    return <div className={classAvatar}>{avatarContent}</div>
}

export default Avatar
