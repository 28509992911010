/* eslint-disable lingui/no-unlocalized-strings */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { getLanguage } from '../../helpers'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch, type RootState } from '../../redux/store'
import { fetchChangeLanguage } from '../../redux/thunks/loginThunks'
import { useLingui } from '@lingui/react'

const Languages: React.FC = () => {
    const profilUser = useSelector((state: RootState) => state.login.user)
    const lingui = useLingui()
    const languages = getLanguage()
    const dispatch = useDispatch<AppDispatch>()
    const getDefaultLanguage = (currentLocale: string): string => {
        const allowedLocales = ['en', 'cs', 'sk']
        return allowedLocales.includes(currentLocale) ? currentLocale : 'sk'
    }

    const currentLocale = getDefaultLanguage(lingui.i18n.locale)
    const language = languages.find(l => l.short === currentLocale)

    const setLanguage = (lang: string): void => {
        if (profilUser != null) {
            void dispatch(fetchChangeLanguage(lang))
        } else {
            lingui.i18n.activate(lang)
        }
    }

    return (
        <Menu as="div" className="flex justify-center pb-8 pt-7 relative ">
            <div className='flex '>
                <img
                    src={require('../../assets/img/icons/' + language?.image)}
                    alt='person'
                    className='mr-2 w-[18px] h-[18px]'
                />
                {/* <Menu.Button className="inline-flex relative bg-transparent  font-basic">
                    {language?.name}
                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                </Menu.Button> */}
                <div className="inline-flex relative bg-transparent  font-basic">
                    {language?.name}
                </div>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute z-10 -top-24 w-[180px] list-item-drop divide-y divide-main-brown divide-opacity-15 rounded-20  bg-white shadow-lg cursor-pointer ">
                    {languages.map((l, i) => {
                        return <div key={l.name}>
                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        onClick={(e) => {
                                            setLanguage(l.short)
                                        }}
                                        className={'flex font-basic  py-2 px-3 h-transition' + l.class + (active ? '' : '')}
                                    >
                                        <div className='w-5 '>
                                            <img
                                                src={require('../../assets/img/icons/' + l.image)}
                                                alt={l.name}
                                                className='mr-2'
                                            />
                                        </div>
                                        <span className='pl-[10px]'>{l.name}</span>
                                    </a>
                                )}
                            </Menu.Item>
                        </div>
                    })}
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default Languages
