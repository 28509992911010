/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import { Trans } from '@lingui/macro'
import { NavLink } from 'react-router-dom'
import IconEye from '../icons/IconEye'
import Avatar from '../global/Avatar'
import Loader from '../global/Loader'

interface itemList {
    id: number
    title: string
    subTitle?: string
    imageUrl: string | null
    sex?: string
    email?: string
    deleteItem: string | null
}

interface UniversalListProps {
    itemList: itemList[]
    locationUrl: string
    loading: boolean
    type?: string
    onScrollToTop: () => void
}

const ListItems: React.FC<UniversalListProps> = ({ itemList, locationUrl, loading, type, onScrollToTop }) => {
    const classAvatar = 'h-8 w-8'

    if (loading ?? false) {
        return (
            <div className="flex justify-center items-center">
                <Loader />
            </div>
        )
    }

    return (
        <ul className="divide-y divide-main-brown divide-opacity-15 ">
            {itemList.map((item, index) => (
                <li key={index} className={`${item.deleteItem != null ? 'opacity-50' : ''}`}>
                    <NavLink
                        onClick={onScrollToTop}
                        className={({ isActive }) =>
                            `${isActive ? 'bg-light-gray  ' : ''} flex  items-center list-items gap-x-6 py-4 pl-20 lg-max:px-[25px] `
                        }
                        to={`${item.id}`}
                    >
                        <div
                            className={
                                `flex min-w-0 gap-x-4 items-center
                                    ${locationUrl === '/facilities' || locationUrl === '/administrators' ? 'md:w-2/6 w-full' : 'w-full'}`
                            }
                        >
                            <Avatar
                                imageUrl={item.imageUrl}
                                classAvatar={classAvatar}
                                type={type === 'user' ? item.sex : 'hotel'}
                            />
                            <div className="min-w-0 flex-auto">
                                <p className="font-basic-black leading-[18px] font-montserrat-bold">
                                    {item.title}
                                </p>
                                <p className="font-basic-black leading-[18px]">
                                    {item.email}
                                </p>
                                <p className="font-basic-black leading-[18px]">
                                    {item.subTitle}
                                </p>
                            </div>
                        </div>
                        <div className='md:block hidden'>
                            <button
                                className={`btn btn-s btn-white shadow-btn-shadow font-basic font-think opacity-0 flex items-center 
                                ${locationUrl === '/administrators' || locationUrl === '/facilities' ? '' : 'hidden'} `}

                            >
                                <IconEye />
                                <Trans>Zobraziť detail</Trans>
                            </button>
                        </div>
                    </NavLink>
                </li>

            ))}
        </ul>

    )
}

export default ListItems
