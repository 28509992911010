import React from 'react'

const IconCross: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className='transform transition duration-500 hover:scale-[1.8]'
            width="7.244" height="7.244" viewBox="0 0 7.244 7.244">
            <path
                id="reject"
                d="M11.994,5.771a.51.51,0,1,0-.721-.721L8.522,7.8,5.771,5.049a.51.51,0,1,0-.721.721L7.8,8.522,5.049,11.273a.51.51,0,1,0,.721.721L8.522,9.243l2.751,2.751a.51.51,0,1,0,.721-.721L9.243,8.522Z"
                transform="translate(-4.9 -4.9)"
                fill="#6c6146"
            />
        </svg>
    )
}

export default IconCross
