/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { type SubmitHandler, useForm } from 'react-hook-form'
import { fetchForgotPassword } from '../../redux/thunks/loginThunks'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../redux/store'
import Loader from '../global/Loader'
import FadeAnimation from '../global/FadeAnimation'

interface EmailResetProps {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface EmailResetInput {
    email: string
}

const FormSendEmailResetPassword: React.FC<EmailResetProps> = (props: EmailResetProps) => {
    const { setOpen } = props
    const dispatch = useDispatch<AppDispatch>()
    const [message, setMessage] = useState('')
    const [susscessMessage, setSusscessMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<EmailResetInput>({})

    const onSubmit: SubmitHandler<EmailResetInput> = (data) => {
        setLoading(true)
        void dispatch(fetchForgotPassword({
            email: data.email
        }))
            .then((response: any) => {
                if (response.payload === true) {
                    setSusscessMessage(t`E-mail bol odoslaný. Prosím, skontrolujte si vašu schránku.`)
                    setLoading(false)
                    setTimeout(() => {
                        setOpen(false)
                    }, 2000)
                } else if (response.error !== undefined) {
                    setMessage(response.payload)
                    setLoading(false)
                }
            })
    }

    return (
        <>
            <form className='mt-[10px]  sm:w-1/3 w-full m-auto pb-6' onSubmit={handleSubmit(onSubmit)}>
                <div >
                    <label htmlFor="email" className="sm-font-b">
                        <Trans>E-mail*</Trans>
                    </label>
                    <input
                        {...register('email', {
                            required: {
                                value: true,
                                message: t`Pole je povinné`
                            },
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: t`Zadajte platnú e-mailovú adresu`
                            }
                        })}
                        name="email"
                        type='email'
                        className='input-form py-[5px] input-focus input-transition input-text-l input-placeholder  tracking-1.6'
                    />
                    {(errors.email != null) && (
                        <FadeAnimation type="up" key={errors.email.message}>
                            <div className="error-text">
                                {errors.email.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <button
                    type="submit"
                    className='btn btn-brown w-full py-[10.5px] mt-8 '
                >
                    <Trans>Odoslať</Trans>
                </button>
            </form>
            <div className='flex justify-center pb-2'>
                {(message !== '') && (
                    <FadeAnimation type="up" key={message}>
                        <div className="text-11 error-text">
                            {message}
                        </div>
                    </FadeAnimation>
                )}
            </div>
            <div className='flex justify-center pb-2'>
                {(susscessMessage !== '') && (
                    <FadeAnimation type="up" key={susscessMessage}>
                        <div className="text-11 text-green-500">
                            {susscessMessage}
                        </div>
                    </FadeAnimation>
                )}
            </div>
            {loading &&
                <div className='flex justify-center absolute mx-auto left-0 right-0 bottom-2'>
                    <Loader/>
                </div>
            }
        </>
    )
}

export default FormSendEmailResetPassword
