import React, { useState } from 'react'
import LogoHeader from '../components/header/LogoHeader'
import { Trans, t } from '@lingui/macro'
import FormLogin from '../components/forms/FormLogin'
import { Link } from 'react-router-dom'
import Languages from '../components/global/Languages'
import ModalWindowEmail from '../components/global/ModalWindowEmail'

const PageLogin: React.FC = () => {
    const [open, setOpen] = useState(false)
    const titleModalForm = t`Obnovenie hesla`
    const subtitleModalForm = t`Prosím, zadajte platný e-mail na obnovenie hesla. Po stlačení tlačidla 'Odoslať' vám bude na e-mail doručený odkaz na obnovu hesla.`
    return (
        <>
            <ModalWindowEmail
                titleModalForm={titleModalForm}
                subtitleModalForm={subtitleModalForm}
                open={open}
                setOpen={setOpen}
            />
            <div className="pt-[82px] sm:px-0 px-[30px] 3xl:h-screen">
                <div className='w-[152px] h-[55] mx-auto animate-fade-down animate-once animate-duration-800 animate-delay-300'>
                    <LogoHeader />
                </div>
                <div className='bg-white rounded-20  sm:w-[380px]  mx-auto shadow-lg-shadow mt-11 pt-12 pb-[35px] sm:px-[50px] px-[25px]'>
                    <div className='text-center'>
                        <h4 className='uppercase md:text-base text-main-brown font-montserrat-bold  tracking-1.6'>
                            <Trans>PRIHLÁSENIE</Trans>
                        </h4>
                        <p className='font-basic-black pt-15'>
                            <Trans>Pre prihlásenie do admin zóny zadajte svoje údaje:</Trans>
                        </p>
                    </div>
                    <div>
                        <FormLogin/>
                    </div>
                    <div className='py-5 text-center'>
                        <button
                            onClick={() => { setOpen(true) }}
                            className='font-basic-black  link-hover link-black'>
                            <Trans>Zabudli ste heslo?</Trans>
                        </button>
                    </div>
                    <div className='text-center '>
                        <Link
                            to='mailto:admin@emporaclub.com'
                            className='text-xs text-light-black underline hover:no-underline'
                        >
                            <Trans>Máte problém s prihlásením?</Trans>
                        </Link>
                    </div>
                </div>
                <div className='mt-[60px]'>
                    <Languages/>
                </div>
            </div>
        </>
    )
}

export default PageLogin
