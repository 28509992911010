import { Trans } from '@lingui/macro'
import React from 'react'
import { logout } from '../../redux/thunks/loginThunks'
import { type AppDispatch } from '../../redux/store'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const LogoutButton: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleLogout = (): void => {
        void dispatch(logout({
            email: '',
            password: ''
        })).then(() => {
            navigate('/', { replace: true })
        })
    }
    return (
        <div >
            <button onClick={handleLogout} className="text-11 text-main-brown lg:block hidden">
                <Trans>Odhlásiť sa</Trans>
            </button>
        </div>
    )
}

export default LogoutButton
