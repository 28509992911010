/* eslint-disable lingui/no-unlocalized-strings */
import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Trans } from '@lingui/macro'
import IconLogOut from '../icons/IconLogOut'
import IconPadLock from '../icons/IconPadLock'
import IconPerson from '../icons/IconPerson'
import IconUser from '../icons/IconUser'
import { type AppDispatch, type RootState } from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../../redux/thunks/loginThunks'
import { useNavigate } from 'react-router-dom'

const UserMenu: React.FC = () => {
    const userLogin = useSelector((state: RootState) => state.login.user)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const handleLogout = (): void => {
        void dispatch(logout({
            email: '',
            password: ''
        })).then(() => {
            navigate('/', { replace: true })
        })
    }

    return (
        <Menu as="div" className="inline-block text-left relative'">
            <div className='flex'>
                <IconUser/>
                <Menu.Button className="inline-flex user relative bg-transparent font-montserrat-bold font-basic mr-6 capitalize">
                    {userLogin?.name}
                    <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="absolute  z-10 mt-3 w-[180px] list-item-drop divide-y divide-main-brown divide-opacity-15 rounded-20  bg-white shadow-lg ">
                    <div >
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="/profil"
                                    className={'flex font-basic rounded-t-20 py-2 hover:rounded-t-20 h-transition'}
                                >
                                    <div className='w-6 ml-[23px]'>
                                        <IconPerson/>
                                    </div>
                                    <span >
                                        <Trans>Profil používateľa</Trans>
                                    </span>
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                    <div>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="/changepassword"
                                    className={'flex font-basic py-2 h-transition'}
                                >
                                    <div className='w-6 ml-[23px]'>
                                        <IconPadLock/>
                                    </div>
                                    <span >
                                        <Trans>Zmena hesla</Trans>
                                    </span>
                                </a>
                            )}
                        </Menu.Item>

                    </div>
                    <div>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    onClick={handleLogout}
                                    className={'flex font-basic rounded-b-20 cursor-pointer py-2 hover:rounded-b-20 h-transition'}
                                >
                                    <div className='w-6 ml-[23px]'>
                                        <IconLogOut/>
                                    </div>
                                    <span >
                                        <Trans>Odhlásenie</Trans>
                                    </span>
                                </a>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

export default UserMenu
