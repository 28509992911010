import React from 'react'

export const IconGroup: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.677" height="12.45" viewBox="0 0 14.677 12.45">
            <g id="group" transform="translate(0 -38.84)">
                <g id="Group_16" data-name="Group 16" transform="translate(11.423 45.562)">
                    <g id="Group_15" data-name="Group 15">
                        <path id="Path_6" data-name="Path 6" d="M399.629,273.32h-1.135a2.952,2.952,0,0,1,.179,1.015v4.29a1.265,1.265,0,0,1-.073.424h1.877a1.273,1.273,0,0,0,1.271-1.271v-2.339A2.121,2.121,0,0,0,399.629,273.32Z" transform="translate(-398.494 -273.32)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_18" data-name="Group 18" transform="translate(0 45.562)">
                    <g id="Group_17" data-name="Group 17">
                        <path id="Path_7" data-name="Path 7" d="M3.075,274.336a2.953,2.953,0,0,1,.179-1.015H2.119A2.121,2.121,0,0,0,0,275.44v2.339a1.273,1.273,0,0,0,1.271,1.271H3.148a1.265,1.265,0,0,1-.073-.424Z" transform="translate(0 -273.321)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_20" data-name="Group 20" transform="translate(3.922 44.458)">
                    <g id="Group_19" data-name="Group 19">
                        <path id="Path_8" data-name="Path 8" d="M141.543,234.815h-2.595a2.121,2.121,0,0,0-2.119,2.119v4.29a.424.424,0,0,0,.424.424h5.985a.424.424,0,0,0,.424-.424v-4.29A2.121,2.121,0,0,0,141.543,234.815Z" transform="translate(-136.829 -234.815)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_22" data-name="Group 22" transform="translate(4.791 38.84)">
                    <g id="Group_21" data-name="Group 21">
                        <path id="Path_9" data-name="Path 9" d="M169.662,38.84a2.548,2.548,0,1,0,2.548,2.548A2.551,2.551,0,0,0,169.662,38.84Z" transform="translate(-167.114 -38.84)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_24" data-name="Group 24" transform="translate(0.959 41.215)">
                    <g id="Group_23" data-name="Group 23" transform="translate(0 0)">
                        <path id="Path_10" data-name="Path 10" d="M35.349,121.689a1.9,1.9,0,1,0,1.906,1.906A1.908,1.908,0,0,0,35.349,121.689Z" transform="translate(-33.443 -121.689)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_26" data-name="Group 26" transform="translate(9.907 41.215)">
                    <g id="Group_25" data-name="Group 25" transform="translate(0 0)">
                        <path id="Path_11" data-name="Path 11" d="M347.513,121.689a1.906,1.906,0,1,0,1.906,1.906A1.908,1.908,0,0,0,347.513,121.689Z" transform="translate(-345.607 -121.689)" fill="#c6c0b2" />
                    </g>
                </g>
            </g>
        </svg>

    )
}
