import { Trans } from '@lingui/macro'
import React from 'react'

interface GlobalButtonProps {
    handleOpenModalWindow: () => void
}

const GlobalButton: React.FC<GlobalButtonProps> = (props: GlobalButtonProps) => {
    const { handleOpenModalWindow } = props
    return (
        <div>
            <button
                onClick={handleOpenModalWindow}
                className={'btn btn-red py-[5.5px] mt-8 mb-4 whitespace-nowrap  w-full xs:w-auto px-2 xs:px-7 flex items-center justify-center cursor-pointer '}
            >
                <Trans>Generovať voucher</Trans>
            </button>
        </div>
    )
}

export default GlobalButton
