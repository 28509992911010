import React from 'react'

const IconLogOut: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.031" height="11.031" viewBox="0 0 11.031 11.031" >
            <g id="logout" transform="translate(-0.014)">
                <g id="Group_63" data-name="Group 63" transform="translate(0.014)">
                    <g id="Group_62" data-name="Group 62">
                        <path id="Path_41" data-name="Path 41" d="M303.241,151a.464.464,0,0,0-.1-.15l-1.378-1.378a.46.46,0,0,0-.65.65l.594.594H299.14a.46.46,0,1,0,0,.919h2.568l-.594.594a.46.46,0,1,0,.65.65l1.378-1.378a.455.455,0,0,0,.1-.15A.46.46,0,0,0,303.241,151Z" transform="translate(-292.245 -146.121)" fill="#c6c0b2" />
                        <path id="Path_42" data-name="Path 42" d="M7.828,6.435a.46.46,0,0,0-.46.46v2.3H5.53V1.839A.46.46,0,0,0,5.2,1.4L3.606.919H7.368v2.3a.46.46,0,0,0,.919,0V.46A.46.46,0,0,0,7.828,0H.474A.41.41,0,0,0,.426.009.454.454,0,0,0,.231.077C.221.083.209.084.2.091S.194.1.19.1A.455.455,0,0,0,.068.247.375.375,0,0,0,.055.285.436.436,0,0,0,.019.393a.286.286,0,0,0,0,.04C.02.442.014.45.014.46V9.652a.459.459,0,0,0,.37.45l4.6.919a.428.428,0,0,0,.09.009.46.46,0,0,0,.46-.46v-.46h2.3a.46.46,0,0,0,.46-.46V6.895A.46.46,0,0,0,7.828,6.435Z" transform="translate(-0.014 0)" fill="#c6c0b2" />
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default IconLogOut
