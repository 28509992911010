import { Trans, t } from '@lingui/macro'
import FooterSideBar from '../footer/FooterSideBar'
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import type { SideBarItemNavigation } from '../../types'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useIsAdmin } from '../../helpers'

interface SideBarProps {
    handleToggleMenu: () => void
}

function getNavigation (isAdmin: boolean): SideBarItemNavigation[] {
    return [
        { name: t`Domov`, href: '/', icon: 'home.svg', id: 'home' },
        {
            id: 'members',
            name: t`Členovia`,
            icon: 'group.svg',
            children: [
                { name: t`Zoznam členov`, href: '/members' },
                { name: t`Pridanie nového člena`, href: '/addnewmember' }
            ]
        },
        {
            id: 'vouchers',
            name: t`Vouchery`,
            icon: 'voucher.svg',
            children: [
                { name: t`Zoznam voucherov`, href: '/vouchers' },
                { name: t`Overenie voucherov`, href: '/#' }
            ]
        },
        {
            id: 'administrators',
            name: t`Administrátori`,
            icon: 'group.svg',
            children: [
                { name: t`Zoznam administrátorov`, href: '/administrators' },
                ...(isAdmin ? [{ name: t`Pridanie nového administrátora`, href: '/addnewadministrator' }] : [])
            ]
        },

        {
            id: 'facilities',
            name: t`Ubytovacie zariadenia`,
            icon: 'hotel.svg',
            children: [
                { name: t`Zoznam zariadení`, href: '/facilities' },
                ...(isAdmin ? [{ name: t`Pridanie nového zariadenia`, href: '/addnewfacility' }] : [])

            ]
        },

        {
            id: 'emaillogs',
            name: t`Email logy`,
            icon: 'email-dark.svg',
            children: [
                { name: t`Zoznam logov`, href: '/emaillog' }
            ]
        }

    ]
}

function classNames (...classes: any): any {
    return classes.filter(Boolean).join(' ')
}

const SideBar: React.FC<SideBarProps> = (props: SideBarProps) => {
    const { handleToggleMenu } = props
    const isAdmin = useIsAdmin()
    const [openItem, setOpenItem] = useState(null)
    const [activeHome, setActiveHome] = useState(true)

    const handleToggle = (itemName: any): void => {
        if (itemName === 'home') {
            setOpenItem(null)
            setActiveHome(true)
        } else {
            setActiveHome(false)
        }
        setOpenItem(openItem === itemName ? null : itemName)
    }

    const navigation = getNavigation(isAdmin)

    return (
        <>
            <div className='mb-[31px]'>
                <h4 className='text-11 text-light-black'><Trans>Admin panel</Trans></h4>
            </div>

            <nav className="flex flex-1 flex-col">
                <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                        <ul className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                                <li key={item.name}>
                                    {(item.children == null)
                                        ? (
                                            <NavLink
                                                onClick={() => { handleToggle(item.id) }}
                                                to={(item.href ?? '') }
                                                className={
                                                    `${activeHome ? 'bg-item' : ''} group flex gap-x-[10px]  p-2 font-basic font-montserrat-bold bg-item-hov h-transition`
                                                }
                                            >
                                                <img
                                                    src={require('../../assets/img/icons/' + item.icon)}
                                                    alt={item.name}
                                                />
                                                {item.name}
                                            </NavLink>
                                        )
                                        : (
                                            <Disclosure as="div">
                                                {({ open }) => (
                                                    <>
                                                        <Disclosure.Button
                                                            onClick={() => { handleToggle(item.name) }}
                                                            className={classNames('flex items-center w-full text-left  p-2 gap-x-[10px] font-basic font-montserrat-bold',
                                                                openItem === item.name ? 'bg-item ' : 'bg-item-hov h-transition'
                                                            )}
                                                        >
                                                            <img
                                                                src={require('../../assets/img/icons/' + item.icon)}
                                                                alt={item.name}
                                                            />
                                                            {item.name}
                                                            <ChevronRightIcon
                                                                className={classNames(
                                                                    openItem === item.name ? 'rotate-[270deg] ' : '',
                                                                    'ml-auto h-4 w-4 shrink-0 rotate-90'
                                                                )}
                                                                aria-hidden="true"
                                                            />
                                                        </Disclosure.Button>
                                                        {openItem === item.name && (
                                                            <ul className="px-2">
                                                                {item.children?.map((subItem) => (
                                                                    <li key={subItem.name}>
                                                                        <NavLink
                                                                            onClick={handleToggleMenu}
                                                                            to={(subItem.href.length > 0) ? subItem.href : ''}
                                                                            className={({ isActive }) =>
                                                                                `${isActive ? 'font-montserrat-bold' : ''} transition-font-weight  hover:font-montserrat-bold duration-500 block  py-1  pl-6  font-basic`
                                                                            }
                                                                        >
                                                                            {subItem.name}
                                                                        </NavLink>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        )}
                                                    </>
                                                )}
                                            </Disclosure>
                                        )}
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li className="mt-auto">
                        <FooterSideBar />
                    </li>
                </ul>
            </nav>
        </>
    )
}

export default SideBar
