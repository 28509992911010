/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'

const IconAvatarMan: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            width={'100%'}
            height={'100%'}
            fill="#c6c0b2"
            data-name="Layer 1"
            viewBox="0 0 256 256"
        >
            <g id="SVGRepo_iconCarrier">
                <defs>
                    <style>
                        {'.cls-4{fill:#fff}.cls-5{fill:#2d2d2d}.cls-6{opacity:.2}'}
                    </style>
                </defs>
                <g id="Male_2" data-name="Male 2">
                    <path
                        id="Wallpaper"
                        d="M250.57 128.13a122 122 0 0 1-37.91 88.44H44.76a101.85 101.85 0 0 1-9-9l-.83-1c-7-8.35-29.75-38-28.57-78.51C7.94 72 55.14 6 128.44 6a122.13 122.13 0 0 1 122.13 122.13Z"
                        style={{
                            fill: '#c6c0b2'
                        }}
                    />
                    <path
                        id="Sweater"
                        d="M222 206.68a122.41 122.41 0 0 1-93.54 43.58c-39.71 0-69.44-21.09-83.67-33.69a104.1 104.1 0 0 1-9-9c-.25-.28-.54-.6-.82-1C48.52 192.9 66.85 182.24 88 176.19c5.92 14.71 21.81 25.25 40.45 25.25 17.33 0 32.27-9.09 39-22.16.54-1 1-2 1.43-3.09l1.15.33c20.63 6.12 38.56 16.65 51.97 30.16Z"
                        style={{
                            fill: '#000'
                        }}
                    />
                    <path
                        id="Neckband"
                        d="M168.86 176.19c-.43 1.05-.89 2.07-1.43 3.09-6.75 13.07-21.69 22.16-39 22.16-18.64 0-34.53-10.54-40.45-25.25l2.67-.73.22-.07s1.78-.47 3.43-.84a37.95 37.95 0 0 0 68.27 0 28.13 28.13 0 0 1 6.29 1.64Z"
                        style={{
                            fill: '#000'
                        }}
                    />
                    <path
                        id="Neck"
                        d="M162.55 174.55a37.94 37.94 0 0 1-68.27 0c1.35-.34 2.71-.63 4.08-.91l1-.2c.17-.05.35-.09.51-.12l.87-.17 1.2-.2c.28-.07.6-.11.91-.18h.15c.35-.87.68-1.8 1-2.82.11-.34.22-.71.33-1.07a40.67 40.67 0 0 0 .89-4.07 39.88 39.88 0 0 0 6.91 4.6c.18.09.35.18.53.29l.62.31.76.36c.16.07.31.13.44.2l.38.18.47.2.44.17a.69.69 0 0 0 .2.09 4.75 4.75 0 0 0 .54.2l.66.27.73.27h.16l.51.18.78.22c.11 0 .22.07.35.09l.71.2a38.17 38.17 0 0 0 4.54.8h3.17a30.33 30.33 0 0 0 9.74-1.63.07.07 0 0 0 .06 0 1.7 1.7 0 0 0 .36-.11c.8-.27 1.51-.56 2.13-.8l.85-.36.22-.08a38.68 38.68 0 0 0 9.89-6.14c.31 1.38.69 2.85 1.16 4.38.42 1.38.86 2.67 1.31 3.89.37.07.73.11 1.11.2l1.09.2 1.09.2c.26 0 .53.11.8.15a4.54 4.54 0 0 0 .53.12c1.4.46 2.75.75 4.09 1.09Z"
                        className="cls-4"
                    />
                    <path
                        id="Hairs"
                        d="M79.85 94.5a67.36 67.36 0 0 0-1 15.29c0 1 .11 2.06.22 3.09 0 .37.06.77.11 1.15-2.11-1.11-3-6-4.65-15.31-1-5.87-1.55-8.8-1.4-12a37 37 0 0 1 4.09-14.23 17.8 17.8 0 0 0 6.25 9.34 69 69 0 0 0-3.62 12.67Z"
                        className="cls-5"
                    />
                    <path
                        id="Hairs-2"
                        d="M182.24 98.72c-.8 4.53-1.43 8-2.09 10.51-.71 2.71-1.49 4.29-2.63 4.82.18-1.6.32-3.17.38-4.77a65 65 0 0 0-3.51-24.65s0-.07 0-.09a83.27 83.27 0 0 0 7.75-5.86 36.07 36.07 0 0 1 1.52 8c.15 3.26-.38 6.2-1.42 12.04Z"
                        className="cls-5"
                        data-name="Hairs"
                    />
                    <path
                        id="NeckShadow"
                        d="M170 176.52c-.82 1-1.69 1.87-2.58 2.76a57.05 57.05 0 0 1-6.28 5.47 44.46 44.46 0 0 1-9.52 5.4 35.78 35.78 0 0 1-6.62 1.95 30.8 30.8 0 0 1-6.07.54 32.7 32.7 0 0 1-11.62-2.43 4.63 4.63 0 0 1-.64-.29 41.9 41.9 0 0 1-8.34-4.73 59.9 59.9 0 0 1-12.69-12.87c-.57-.77-1.13-1.55-1.68-2.37.11-.34.22-.71.33-1.07a40.67 40.67 0 0 0 .89-4.07c.58.47 1.18.94 1.8 1.38a45.8 45.8 0 0 0 5.11 3.22c.18.09.35.18.53.29l.62.31.76.36c.16.07.31.13.44.2l.38.18.47.2.44.17a.69.69 0 0 0 .2.09l.54.2.66.27.73.27h.16l.51.18.78.22c.11 0 .22.07.35.09l.71.2a31.48 31.48 0 0 0 4.54.8h3.17a31.93 31.93 0 0 0 9.74-1.63.07.07 0 0 0 .06 0 1.7 1.7 0 0 0 .36-.11c.67-.22 1.31-.47 2-.73l.2-.07c.26-.11.53-.22.82-.36l.22-.08a46.4 46.4 0 0 0 8.27-4.89c.55-.4 1.09-.83 1.62-1.25.31 1.38.69 2.85 1.16 4.38.42 1.38.86 2.67 1.31 3.89.37.07.73.11 1.11.2l1.09.2 1.09.2c.26 0 .53.11.8.15a4.54 4.54 0 0 0 .53.12c1.38.28 2.73.57 4.07.91 2.13.49 4.24 1 6.31 1.64Z"
                        className="cls-6"
                    />
                    <path
                        id="RightEar"
                        d="M186.6 120.16c-.16 5.2-3.48 10.47-7.19 12.41a7.11 7.11 0 0 1-1.58.6h-.12a5.07 5.07 0 0 1-2.05.06 5 5 0 0 1-2-.88l-.28-.21a7.39 7.39 0 0 1-1.13-1.11c-2.68-3.23-3.77-9.29-2-14.13a14.78 14.78 0 0 1 3.23-5.06 9.08 9.08 0 0 1 4.44-2.6 5.91 5.91 0 0 1 2.37 0c4.41.91 6.45 6.57 6.31 10.92Z"
                        className="cls-4"
                    />
                    <path
                        id="RightEarShadow"
                        d="M186.6 120.16c-.16 5.2-3.48 10.47-7.2 12.41a7 7 0 0 1-1.57.6h-.12a5.07 5.07 0 0 1-2.05.06 5 5 0 0 1-2-.88l-.28-.21a7 7 0 0 1-1.13-1.11c-2.68-3.23-3.77-9.29-2-14.13a14.78 14.78 0 0 1 3.23-5.06 9.08 9.08 0 0 1 4.44-2.6 5.91 5.91 0 0 1 2.37 0c4.41.91 6.45 6.57 6.31 10.92Z"
                        style={{
                            opacity: 0.3
                        }}
                    />
                    <path
                        id="LeftEar"
                        d="M84.57 131.06a7.28 7.28 0 0 1-1.41 1.32 5.13 5.13 0 0 1-2 .88 5.07 5.07 0 0 1-2.05-.06h-.12a7.11 7.11 0 0 1-1.58-.6c-3.71-1.94-7-7.21-7.2-12.41-.13-4.35 1.91-10 6.33-10.9 2.51-.51 4.91.71 6.8 2.61a14.66 14.66 0 0 1 3.24 5.06c1.76 4.81.66 10.87-2.01 14.1Z"
                        className="cls-4"
                    />
                    <path
                        id="Head"
                        d="M177.9 109.28c-.06 1.6-.2 3.17-.38 4.77a80.14 80.14 0 0 1-4.17 18.12c-4.67 13.11-12.52 24.73-22 32.33-.53.42-1.07.85-1.62 1.25a46.4 46.4 0 0 1-8.27 4.89l-.22.08c-.29.14-.56.25-.82.36l-.2.07c-.65.26-1.29.51-2 .73a1.7 1.7 0 0 1-.36.11.07.07 0 0 1-.06 0 31.93 31.93 0 0 1-9.74 1.63h-3.17a31.48 31.48 0 0 1-4.54-.8l-.71-.2c-.13 0-.24-.07-.35-.09l-.78-.22-.51-.18h-.16l-.73-.27-.66-.27-.54-.2a.69.69 0 0 1-.2-.09l-.44-.17-.47-.2-.38-.18c-.13-.07-.28-.13-.44-.2l-.76-.36-.62-.31c-.18-.11-.35-.2-.53-.29a45.8 45.8 0 0 1-5.11-3.22c-.62-.44-1.22-.91-1.8-1.38a57.24 57.24 0 0 1-8.6-8.53 78.57 78.57 0 0 1-13.67-25.09A81.2 81.2 0 0 1 79.21 114c0-.38-.09-.78-.11-1.15-.11-1-.18-2-.22-3.09a67.36 67.36 0 0 1 1-15.29 69 69 0 0 1 3.62-12.68c.66-1.76 1.42-3.51 2.24-5.25a65.61 65.61 0 0 1 17.09-22.49c.31-.26.62-.51.94-.75a39.76 39.76 0 0 1 24.49-8.89C138 44.39 146.7 48.12 154 54a58.4 58.4 0 0 1 10.24 10.91 74.31 74.31 0 0 1 8.64 15.51c.54 1.38 1.07 2.71 1.54 4.09v.09a65 65 0 0 1 3.51 24.65Z"
                        className="cls-4"
                    />
                    <path
                        id="HairsShadow"
                        d="M183.64 86.68a26.09 26.09 0 0 1-3.43 3c-.09.09-.55.4-1.15.82-1 .65-2 1.29-3 1.91a74.33 74.33 0 0 1-13.42 6.43 76.87 76.87 0 0 1-62.09-4.47 71.968 71.968 0 0 1-6.52-4c-.71-.49-1.4-1-2.09-1.49a64.17 64.17 0 0 1-5.28-4.29c-.8-.71-1.6-1.44-2.36-2.2a40.61 40.61 0 0 0 8.78 5c6.46 2.85 14.18 5 21.67 7.07a73.55 73.55 0 0 0 19.93 3 65.77 65.77 0 0 0 20.07-3 56.6 56.6 0 0 0 8.58-3.57c1.31-.65 2.62-1.36 4-2.09 2.63-1.43 4.91-2.8 7.05-4.25a82.89 82.89 0 0 0 7.67-5.8 32.88 32.88 0 0 1 1.59 7.93Z"
                        className="cls-6"
                    />
                    <path
                        id="Hairs-3"
                        d="M199.37 57c-.07 5.6-5.33 10.71-6.87 12.15-3.93 3.74-7.18 6.8-10.38 9.52l-.08.06a82.89 82.89 0 0 1-7.67 5.8c-2.14 1.45-4.42 2.82-7.05 4.25-1.35.73-2.66 1.44-4 2.09a56.6 56.6 0 0 1-8.58 3.57 65.77 65.77 0 0 1-20.07 3 73.55 73.55 0 0 1-19.93-3c-7.49-2.09-15.21-4.22-21.67-7.07a40.61 40.61 0 0 1-8.78-5c-.27-.22-.54-.42-.8-.64a17.8 17.8 0 0 1-6.25-9.34c-2.36-9.39 4.55-18.83 5.59-20.23a30.41 30.41 0 0 1 12.49-9.42c9.36-4 13.69-.87 34 0 18.07.78 27.12 1.18 35.65-2.16 3.44-1.35 9.89-4.33 11-2.73.91 1.29-1.74 5.11-2.36 6-2.33 3.36-4.29 4.27-4 5.07.51 1.29 6.16.66 10.87-.71 5-1.47 6.89-3.2 7.78-2.36 1.2 1.13-.6 6-3.63 9.22-2.17 2.34-4.2 3-4 3.8.29 1.07 4.17 1.45 7.77.54 5.69-1.43 8.58-5.58 10.18-4.54.5.23.81.8.79 2.13Z"
                        className="cls-5"
                        data-name="Hairs"
                    />
                </g>
            </g>
        </svg>
    )
}

export default IconAvatarMan
