/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../redux/store'
import { type SubmitHandler, useForm, Controller } from 'react-hook-form'
import { Trans, t } from '@lingui/macro'
import { fetchResetPassword } from '../../redux/thunks/loginThunks'
import { useLocation, useNavigate } from 'react-router-dom'
import Loader from '../global/Loader'
import ModalWindow from '../global/ModalWindow'
import { type ModalWindowData } from '../../types'
import FadeAnimation from '../global/FadeAnimation'
import IconSuccess from '../icons/IconSuccess'
import IconWarning from '../icons/IconWarning'

export interface ResetPasswordInputs {
    email: string
    password: string
    password_confirmation: string
    token: string
}

const FormResetPassword: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '' })
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        getValues,
        control,
        formState: { errors }
    } = useForm<ResetPasswordInputs>({})

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get('token') ?? ''

    const closeWindow = (): void => {
        setOpen(false)
    }

    const onSubmit: SubmitHandler<ResetPasswordInputs> = (data) => {
        setLoading(true)
        void dispatch(fetchResetPassword({
            email: data.email,
            password: data.password,
            password_confirmation: data.password_confirmation,
            token
        }))
            .then((response: any) => {
                if (response.payload === true) {
                    setLoading(false)
                    setOpen(true)
                    setDataModal({
                        title: t`Úspešne ste zmenili heslo`,
                        subtitle: t`Pre prihlásenie budete presmerovaný na prihlasovaciu stránku`,
                        textButton: t`Zatvoriť`,
                        icon: <IconSuccess/>,
                        action: () => { setOpen(false) }
                    })
                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                } else if (response.error !== undefined) {
                    setLoading(false)
                    setOpen(true)
                    setDataModal({
                        title: t`Chyba`,
                        subtitle: response.payload,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: () => { setOpen(false) }
                    })
                }
            })
    }

    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                closeWindow={closeWindow}
            />
            <form className='mt-[30px]' onSubmit={handleSubmit(onSubmit)}>
                <div className='pb-3'>
                    <label htmlFor="email" className="sm-font-b">
                        <Trans>Email*</Trans>
                    </label>
                    <input
                        {...register('email', {
                            required: {
                                value: true,
                                message: t`Pole je povinné`
                            }
                        })}
                        name="email"
                        type='email'
                        className='input-form py-[5px] input-focus input-transition input-text-l input-placeholder  tracking-1.6'
                    />
                    {(errors.email != null) && (
                        <FadeAnimation type="up" key={errors.email.message}>
                            <div className="error-text">
                                {errors.email.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <div className='pb-3'>
                    <label htmlFor="password" className="sm-font-b">
                        <Trans>Nové heslo*</Trans>
                    </label>
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t`Pole je povinné`,
                            minLength: {
                                value: 8,
                                message: t`Heslo musí obsahovať aspoň 8 znakov`
                            },
                            validate: (value) => {
                                const hasUppercase = /[A-Z]/.test(value)
                                const hasLowercase = /[a-z]/.test(value)
                                const hasNumber = /\d/.test(value)
                                if (!hasUppercase) {
                                    return t`Heslo musí obsahovať aspoň jedno veľké písmeno`
                                }
                                if (!hasLowercase) {
                                    return t`Heslo musí obsahovať aspoň jedno malé písmeno`
                                }
                                if (!hasNumber) {
                                    return t`Heslo musí obsahovať aspoň jedno číslo`
                                }
                                return true
                            }
                        }}
                        render={({ field }) =>
                            <input
                                className="input-form py-[5px] mt-[6px] input-focus input-transition input-text-l input-placeholder"
                                type="password" {...field}
                                placeholder='*******'
                                autoComplete="off"
                            />
                        }
                    />
                    {(errors.password != null) && (
                        <FadeAnimation type="up" key={errors.password.message}>
                            <div className="error-text">
                                {errors.password.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <div >
                    <label htmlFor="password" className="sm-font-b">
                        <Trans>Zopakujte nove heslo*</Trans>
                    </label>
                    <Controller
                        name="password_confirmation"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t`Pole je povinné`,
                            minLength: {
                                value: 8,
                                message: t`Heslo musí obsahovať aspoň 8 znakov`
                            },
                            validate: {
                                matchesNewPassword: (value) => {
                                    const newPassword = getValues('password')
                                    return newPassword === value || t`Heslá sa nezhodujú`
                                },
                                hasUppercase: (value) => /[A-Z]/.test(value) || t`Heslo musí obsahovať aspoň jedno veľké písmeno`,
                                hasLowercase: (value) => /[a-z]/.test(value) || t`Heslo musí obsahovať aspoň jedno malé písmeno`,
                                hasNumber: (value) => /\d/.test(value) || t`Heslo musí obsahovať aspoň jedno číslo`
                            }
                        }}
                        render={({ field }) =>
                            <input
                                className="input-form py-[5px] mt-[6px] input-focus input-transition input-text-l input-placeholder"
                                type="password" {...field}
                                placeholder='*******'
                                autoComplete="off"
                            />
                        }
                    />
                    {(errors.password_confirmation != null) && (
                        <FadeAnimation type="up" key={errors.password_confirmation.message}>
                            <div className="error-text">
                                {errors.password_confirmation.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <div>

                </div>
                <button
                    type="submit"
                    className='btn btn-brown w-full py-[10.5px] mt-8'
                >
                    <Trans>Zmeniť heslo</Trans>
                </button>
            </form>
            {loading &&
                <div className='flex justify-center py-4'>
                    <Loader/>
                </div>
            }
        </>
    )
}

export default FormResetPassword
