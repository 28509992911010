import React from 'react'

export const IconVoucher: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.677" height="8.75" viewBox="0 0 14.677 8.75" style={{ width: '100%', height: '100%' }}>
            <g id="voucher" transform="translate(0 -103.385)">
                <g id="Group_28" data-name="Group 28" transform="translate(0 103.385)">
                    <path id="Path_13" data-name="Path 13" d="M14.254,106.49a.423.423,0,0,0,.423-.423v-2.258a.423.423,0,0,0-.423-.423H4.939V105.5a.423.423,0,1,1-.847,0v-2.117H.423a.423.423,0,0,0-.423.423v2.258a.423.423,0,0,0,.423.423,1.27,1.27,0,0,1,0,2.54.423.423,0,0,0-.423.423v2.258a.423.423,0,0,0,.423.423H4.093v-2.117a.423.423,0,1,1,.847,0v2.117h9.314a.423.423,0,0,0,.423-.423v-2.258a.423.423,0,0,0-.423-.423,1.27,1.27,0,1,1,0-2.54Zm-9.314,1.835a.423.423,0,1,1-.847,0V107.2a.423.423,0,0,1,.847,0Z" transform="translate(0 -103.385)" fill="#6b6145" />
                </g>
            </g>
        </svg>

    )
}
