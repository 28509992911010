import React from 'react'

const IconSuccess: React.FC = () => {
    return (

        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="exclamation">
                <circle id="background" cx="12" cy="12" r="12" fill="#4fb142"/>
                <g id="_3-Check" data-name="3-Check" transform="translate(-25.656 -44.215)">
                    <path id="Path_55" data-name="Path 55" d="M36.077,62.5a.274.274,0,0,1-.237-.136c-1.026-1.748-3.76-5.468-3.787-5.505a.274.274,0,0,1,.028-.358l.84-.83a.274.274,0,0,1,.35-.03l2.748,1.919a33.936,33.936,0,0,1,4.641-4.922,17.186,17.186,0,0,1,2.08-1.571.274.274,0,0,1,.141-.039h1.36a.274.274,0,0,1,.183.479,38.764,38.764,0,0,0-5.514,6.724c-1.525,2.257-2.584,4.111-2.594,4.13a.274.274,0,0,1-.237.139Z" transform="translate(0 0)" fill="#fff"/>
                </g>
            </g>
        </svg>

    )
}

export default IconSuccess
