import React, { useMemo } from 'react'
import { type Client } from '../../types'
import { IconFemale } from '../icons/IconFemale'
import { IconMale } from '../icons/IconMale'
import { Trans, t } from '@lingui/macro'
import { IconEmail } from '../icons/IconEmail'
import { IconDate } from '../icons/IconDate'
import { IconAddress } from '../icons/IconAddress'
import { IconBuild } from '../icons/IconBuild'
import { formatDate } from '../../helpers'
import { useSelector } from 'react-redux'
import { type RootState } from '../../redux/store'
import { Link } from 'react-router-dom'
import { IconPhone } from '../icons/IconPhone'

interface DetailItemMemberProps {
    oneItemDetail: Client
    textTileDetail: string
}

const DetailInfoItemMember: React.FC<DetailItemMemberProps> = (props: DetailItemMemberProps) => {
    const { oneItemDetail, textTileDetail } = props
    const hotels = useSelector((state: RootState) => state.hotel.data)

    const hotelName = useMemo(() => {
        return hotels.find((hotel) => {
            return hotel.id !== undefined && hotel.id === oneItemDetail.hotel_id
        })
    }, [hotels, oneItemDetail])

    const getGenderDescription = (gender: string): string => {
        if (gender === 'female') {
            return t`žena`
        } else {
            return t`muž`
        }
    }

    return (
        <>
            <div className='mt-2 mb-2'>
                <h4 className='font-basic font-montserrat-bold'>
                    {textTileDetail}
                </h4>
                <div/>
            </div>
            <div className='mt-[2px]  mb-5 flex flex-wrap gap-y-7'>
                <div className='w-full sm:w-1/2 flex items-baseline'>
                    <div className='relative top-[4px]'>
                        {oneItemDetail?.sex === 'female' ? <IconFemale /> : <IconMale/>}
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Pohlavie</Trans></p>
                        <p className='font-basic-black'>{getGenderDescription(oneItemDetail.sex)}</p>
                    </div>
                </div>
                <div className='w-full sm:w-1/2 flex items-baseline'>
                    <div className='relative top-[3px]'>
                        <IconDate />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Dátum narodenia </Trans></p>
                        <p className='font-basic-black'>{formatDate(oneItemDetail.birth_date)}</p>
                    </div>
                </div>
                <div className='w-full sm:w-1/2 flex items-baseline'>
                    <div className='relative top-[4px]'>
                        <IconAddress />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Adresa bydliska</Trans></p>
                        <p className='font-basic-black'>
                            {oneItemDetail.address.street}
                            <span className='ml-1'>
                                {oneItemDetail.address.postcode}
                            </span>
                        </p>
                        <p className='font-basic-black'> {oneItemDetail.address.city}</p>
                        <p className='font-basic-black'> {oneItemDetail.address.state}</p>
                    </div>
                </div>
                <div className='w-full sm:w-1/2 flex items-baseline'>
                    <div>
                        <IconEmail />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Email</Trans></p>
                        <p className='font-basic-black'>
                            <Link to={`mailto:${oneItemDetail.email}`}>
                                {oneItemDetail.email}
                            </Link>
                        </p>
                    </div>
                </div>
                {hotelName?.name !== undefined &&
                <div className={`w-1/2 flex items-baseline ${hotelName.deleted_at !== null ? 'opacity-50' : ''}`}>
                    <div className='relative top-[2px]'>
                        <IconBuild />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Zariadenie</Trans></p>
                        <p className='font-basic-black'>{hotelName?.name}</p>
                    </div>
                </div>
                }
                {oneItemDetail?.phone_number !== null &&
                <div className='w-1/2 flex items-baseline'>
                    <div className='relative top-[4px]'>
                        <IconPhone />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Telefón</Trans></p>
                        <p className='font-basic-black'>{oneItemDetail?.phone_number}</p>
                    </div>
                </div>
                }
                {oneItemDetail.comment !== null &&
                    <div className='w-full'>
                        <p className='font-basic font-montserrat-bold '><Trans>Poznámka</Trans></p>
                        <p className='font-basic-black'>{oneItemDetail.comment}</p>
                    </div>
                }
            </div>
        </>
    )
}

export default DetailInfoItemMember
