/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import userIcon from '../../assets/img/icons/user-edit.svg'
import { Trans } from '@lingui/macro'
import { formatDate } from '../../helpers'
import Avatar from '../global/Avatar'

interface ProfilHeaderProps {
    profilUserName: string | undefined
    profilUserRegister: string | undefined
    handleOpenModalForm?: () => void
    imageUrl?: string
    type?: string
}

const ProfilHeader: React.FC<ProfilHeaderProps> = (props: ProfilHeaderProps) => {
    const { profilUserName, profilUserRegister, handleOpenModalForm, imageUrl, type } = props
    const classAvatar = 'h-[95px] w-95px'
    return (
        <div className='flex sm:flex-row flex-col min-w-0 gap-x-7 items-end'>
            <Avatar
                imageUrl={imageUrl}
                classAvatar={classAvatar}
                type={type}
            />
            <div className="min-w-0 flex-auto sm:w-0 w-full ">
                <p className="font-basic-black leading-[18px] font-montserrat-bold">{profilUserName}</p>
                <div className='flex '>
                    <p className="font-basic-black leading-[18px]">
                        <span className='mr-1'><Trans>Dátum registrácie:</Trans></span>
                        {(profilUserRegister != null) && formatDate(profilUserRegister)}
                    </p>
                </div>
                <button
                    className='btn btn-s btn-gray font-basic font-think flex items-center mt-4'
                    onClick={handleOpenModalForm}
                >
                    <img
                        src={userIcon}
                        alt="user"
                        className='pr-1'
                    />

                    <Trans>Upraviť údaje</Trans>
                </button>
            </div>
        </div>
    )
}

export default ProfilHeader
