import React, { useEffect, useState } from 'react'
import IconMagnifier from '../icons/IconMagnifier'
import { useDispatch } from 'react-redux'
import { setInputValue } from '../../redux/slices/searchInputSlice'
import FadeAnimation from '../global/FadeAnimation'
import { Trans } from '@lingui/macro'

interface FormSearchProps {
    placeHolderSearchText: string
}

const FormSearch: React.FC<FormSearchProps> = (props: FormSearchProps) => {
    const { placeHolderSearchText } = props
    const [searchErrorText, setsearchErrorText] = useState(false)
    const [hideIcon, setHideIcon] = useState(false)
    const [searchWord, setSearchWord] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setInputValue(''))
    }, [dispatch])

    const handleChange = (e: any): void => {
        setsearchErrorText(true)
        if (e.target.value.length >= 3 || e.target.value.length === 0) {
            setSearchWord(e.target.value)
            setsearchErrorText(false)
        } else {
            setSearchWord('')
        }

        if (e.target.value.length === 0) {
            setHideIcon(false)
            setsearchErrorText(false)
        } else {
            setHideIcon(true)
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setInputValue(searchWord))
        }, 500)

        return () => {
            clearTimeout(timer)
        }
    }, [searchWord, dispatch])

    return (
        <form role="search" action="#" method="get" >
            <div className='flex items-center'>
                <div className='w-full relative'>
                    <input
                        className="rounded-20 py-[3px] input-placeholder-serach font-basic w-full pl-3 input-focus input-transition border-none"
                        placeholder={placeHolderSearchText}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault()
                            }
                        }}
                    />
                    {!hideIcon &&
                            <IconMagnifier/>
                    }
                </div>
            </div>
            {
                searchErrorText &&
                <div className='flex justify-center items-center flex-col '>
                    <FadeAnimation type={'down'} class={'pt-8'}>
                        <p className='font-basic-black '>
                            <Trans>Zadajte viac než tri znaky.</Trans>
                        </p>
                    </FadeAnimation>
                </div>
            }
        </form>

    )
}

export default FormSearch
