import React from 'react'

const IconInfo: React.FC = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="exclamation">
                <circle id="background" cx="12" cy="12" r="12" fill="#c6c0b2"/>
                <g id="info" transform="translate(-149.872 5.595)">
                    <g id="Group_297" data-name="Group 297" transform="translate(159.65)">
                        <path id="Path_56" data-name="Path 56" d="M161.733,0a1.512,1.512,0,1,1-1.512,1.512A1.511,1.511,0,0,1,161.733,0Zm1.376,3.972h-2.56a.9.9,0,0,0-.9.9h0a.9.9,0,0,0,.9.9h.089V11.01h-.988v1.8h4.444v-1.8h-.983V3.972Z" transform="translate(-159.65)" fill="#fff"/>
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default IconInfo
