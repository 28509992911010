import React from 'react'

const IconPerson: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="6.832" height="12.451" viewBox="0 0 6.832 12.451">
            <g id="Group_61" data-name="Group 61" transform="translate(-1085.922 -93.977)">
                <g id="Group_59" data-name="Group 59" transform="translate(1085.922 99.595)">
                    <g id="Group_19" data-name="Group 19">
                        <path id="Path_8" data-name="Path 8" d="M141.543,234.815h-2.595a2.121,2.121,0,0,0-2.119,2.119v4.29a.424.424,0,0,0,.424.424h5.985a.424.424,0,0,0,.424-.424v-4.29A2.121,2.121,0,0,0,141.543,234.815Z" transform="translate(-136.829 -234.815)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_60" data-name="Group 60" transform="translate(1086.791 93.977)">
                    <g id="Group_21" data-name="Group 21">
                        <path id="Path_9" data-name="Path 9" d="M169.662,38.84a2.548,2.548,0,1,0,2.548,2.548A2.551,2.551,0,0,0,169.662,38.84Z" transform="translate(-167.114 -38.84)" fill="#c6c0b2" />
                    </g>
                </g>
            </g>
        </svg>

    )
}

export default IconPerson
