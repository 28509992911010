import React from 'react'

const IconHome: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.677" height="11.68" viewBox="0 0 14.677 11.68">
            <g id="home" transform="translate(0.001 -46.994)">
                <g id="Group_14" data-name="Group 14" transform="translate(-0.001 46.994)">
                    <path id="Path_4" data-name="Path 4" d="M70.942,120.939l-5.235,4.315a.124.124,0,0,1,0,.027.125.125,0,0,0,0,.027v4.37a.591.591,0,0,0,.583.583h3.5v-3.5h2.331v3.5h3.5a.592.592,0,0,0,.583-.583v-4.37a.128.128,0,0,0-.009-.054Z" transform="translate(-63.603 -118.581)" fill="#6b6145"/>
                    <path id="Path_5" data-name="Path 5" d="M14.575,52.684l-1.994-1.657V47.313a.281.281,0,0,0-.291-.291H10.543a.28.28,0,0,0-.291.291v1.775L8.03,47.231a1.129,1.129,0,0,0-1.384,0L.1,52.684a.264.264,0,0,0-.1.2.3.3,0,0,0,.064.214l.564.674a.311.311,0,0,0,.191.1.338.338,0,0,0,.219-.064l6.3-5.253,6.3,5.253a.281.281,0,0,0,.191.063h.027a.311.311,0,0,0,.191-.1l.564-.674a.3.3,0,0,0,.063-.214A.265.265,0,0,0,14.575,52.684Z" transform="translate(0.001 -46.994)" fill="#6b6145"/>
                </g>
            </g>
        </svg>

    )
}

export default IconHome
