import { Trans } from '@lingui/macro'
import React from 'react'
import LogoHeader from '../components/header/LogoHeader'
import Languages from '../components/global/Languages'
import FormResetPassword from '../components/forms/FormResetPassword'

const PageResetPassword: React.FC = () => {
    return (
        <div className="pt-[82px] sm:px-0 px-[30px] h-screen">
            <div className='w-[152px] h-[55] mx-auto'>
                <LogoHeader />
            </div>
            <div className='bg-white rounded-20  sm:w-[380px]   mx-auto shadow-lg-shadow mt-11 pt-12 pb-[35px] sm:px-[50px] px-[25px]'>
                <div className='text-center'>
                    <h4 className='uppercase text-base text-main-brown font-montserrat-bold  tracking-1.6'>
                        <Trans>Zmena hesla</Trans>
                    </h4>
                    <p className='font-basic-black pt-15'>
                        <Trans>Pre zmenu hesla zadajte svoje údaje:</Trans>
                    </p>
                </div>
                <div>
                    <FormResetPassword/>
                </div>
            </div>
            <div className='mt-[60px]'>
                <Languages/>
            </div>
        </div>
    )
}

export default PageResetPassword
