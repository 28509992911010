import { Trans } from '@lingui/macro'
import React from 'react'
import IconLog from '../icons/IconLog'

interface DetailMemberLogsProps {
    handleOpenLog: (value: boolean) => void
}

const DetailMemberLogs: React.FC<DetailMemberLogsProps> = (props: DetailMemberLogsProps) => {
    const { handleOpenLog } = props
    return (
        <div className='mt-4 mb-2 '>
            <h4 className='font-basic font-montserrat-bold mb-1'>
                <Trans>Logy</Trans>
            </h4>

            <button
                className={'font-basic-black flex cursor-pointer link-hover link-black'}
                onClick={() => { handleOpenLog(true) }}
            >
                <IconLog/>
                <p className='ml-2'><Trans>Zobraziť logy</Trans></p>
            </button>
        </div>
    )
}

export default DetailMemberLogs
