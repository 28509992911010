/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { type RootState, type AppDispatch } from '../../redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../redux/thunks/loginThunks'
import Loader from '../global/Loader'
import FadeAnimation from '../global/FadeAnimation'

interface IFormInput {
    email: string
    password: string
}

const FormLogin: React.FC = () => {
    const errorLogin = useSelector((state: RootState) => state.login.error)
    const [errorMessage, setErrorMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<IFormInput>({})

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        setLoading(true)
        void dispatch(login({
            email: data.email,
            password: data.password
        }))
    }
    useEffect(() => {
        if (errorLogin !== null) {
            setErrorMessage(t`Zlý e-mail alebo heslo. Skúste znova.`)
            setLoading(false)
        }
    }, [errorLogin])

    return (
        <>
            <form className='mt-[30px]' onSubmit={handleSubmit(onSubmit)}>
                <div className='mb-5'>
                    <label htmlFor="email" className="sm-font-b">
                        <Trans>Admin*</Trans>
                    </label>
                    <input
                        {...register('email', {
                            required: {
                                value: true,
                                message: t`Pole je povinné`
                            }
                        })}
                        name="email"
                        type='email'
                        className='input-form py-[5px] input-focus input-transition input-text-l input-placeholder  tracking-1.6'
                    />
                    {(errors.email != null) && (
                        <FadeAnimation type="up" key={errors.email.message}>
                            <div className="error-text">
                                {errors.email.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <div >
                    <label htmlFor="password" className="sm-font-b">
                        <Trans>Heslo*</Trans>
                    </label>
                    <input
                        {...register('password', {
                            required: {
                                value: true,
                                message: t`Pole je povinné`
                            }
                        })}
                        name="password"
                        type='password'
                        autoComplete='off'
                        className='input-form py-[5px] input-focus input-transition input-text-l input-placeholder  tracking-1.6'
                    />
                    {(errors.password != null) && (
                        <FadeAnimation type="up" key={errors.password.message}>
                            <div className="error-text">
                                {errors.password.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <button
                    type="submit"
                    className='btn btn-brown w-full py-[10.5px] mt-6'
                >
                    <Trans>Prihlásiť sa</Trans>
                </button>
                <div className='flex justify-center mt-1'>
                    {(errorMessage != null) && (
                        <FadeAnimation type="up" key={errorMessage}>
                            <div className="error-text">
                                {errorMessage}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
            </form>
            {loading &&
                <div className='flex justify-center py-4'>
                    <Loader/>
                </div>
            }
        </>
    )
}

export default FormLogin
