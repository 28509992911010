/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect, useRef, useState } from 'react'
import HeaderSubPages from '../components/header/HeaderSubPages'
import ListItems from '../components/listItems/ListItems'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import type { HeaderSupPage } from '../types'
import { type RootState, type AppDispatch } from '../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { fetchPaginationHotles } from '../redux/thunks/hotelThunks'
import { selectInputValue } from '../redux/slices/searchInputSlice'
import Pagination from '../components/global/Pagination'
import SelectPage from '../components/global/SelectPage'
import Loader from '../components/global/Loader'
import { useIsAdmin } from '../helpers'

const PageDevices: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const totalHotels = useSelector((state: RootState) => state.hotel.total)
    const isAdmin = useIsAdmin()

    const lastPage = useSelector((state: RootState) => state.hotel.lastPage)
    const page = useSelector((state: RootState) => state.hotel.currentPage)
    const loading: boolean = useSelector((state: RootState) => state.hotel.loading)
    const inputValue = useSelector(selectInputValue)
    const navigate = useNavigate()

    const [displayedHotels, setDisplayedHotels] = useState(hotels)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [detailOpen, setDetailOpen] = useState(false)
    const topItemRef = useRef<HTMLDivElement>(null)

    const deviceText = (number: number): string => {
        if (number === 1) {
            return t`Zariadenie`
        } else if (number > 1 && number < 5) {
            return t`Zariadenia`
        } else {
            return t`Zariadení`
        }
    }

    const location = useLocation()
    const type = 'hotel'
    const locationUrl = location.pathname
    const dataHeader: HeaderSupPage = {
        titleHeader: t`Zariadenia`,
        countHeader: totalHotels,
        textCountHeader: deviceText(totalHotels),
        textButtonHeader: t`Pridať nové zariadenie`,
        placeHolderSearchText: t`Vyhľadať zariadenie`,
        class: isAdmin ? 'opacity-100' : 'hidden'
    }

    const textSelectPage = t`Zobraziť počet zariadení:`

    useEffect(() => {
        setDisplayedHotels(hotels)
    }, [hotels, totalHotels])

    useEffect(() => {
        if (inputValue !== '') {
            setCurrentPage(1)
        }
    }, [inputValue])

    useEffect(() => {
        void dispatch(fetchPaginationHotles({ page: currentPage, perPage: itemsPerPage, keyword: inputValue }))
    }, [currentPage, itemsPerPage, inputValue])

    useEffect(() => {
        setDetailOpen(location.pathname === '/facilities')
    }, [location.pathname])

    const handleAdd = (): void => {
        navigate('/addnewfacility')
    }

    const handleNextPage = (): void => {
        setCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
        setDetailOpen(true)
        navigate('/facilities')
    }

    const handleBackPage = (): void => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
        setDetailOpen(true)
        navigate('/facilities')
    }

    const handlePerPageChange = (selectNumber: any): void => {
        setItemsPerPage(selectNumber)
        setCurrentPage(1)
    }

    const onScrollToTop = (): void => {
        if (topItemRef.current != null) {
            topItemRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <>
            <HeaderSubPages
                dataHeader ={dataHeader}
                onAdd={handleAdd}
                hideInput = {false}

            />
            {loading
                ? <div className='flex justify-center items-center mt-5 flex-col'>
                    <Loader/>
                    <p className='font-basic-black'>
                        <Trans>Načítavam data...</Trans>
                    </p>
                </div>
                : <>
                    <div ref={topItemRef} className={`${hotels.length > 10 ? 'h-auto' : ' 3xl:h-full'} ${detailOpen ? '' : 'flex'}`}>
                        {
                            displayedHotels.length > 0
                                ? <div className={`${detailOpen ? '' : 'md:w-1/2 md:block hidden '}`}>
                                    <div>
                                        <div className={`${detailOpen ? 'lg:mx-[50px] lg-max:px-[25px] pl-7 pr-5' : 'ml-[50px] mr-[10px] pr-1'} flex items-center flex-wrap justify-between mt-7 mb-4  lg-max:gap-y-2`}>
                                            <SelectPage
                                                textSelectPage={textSelectPage}
                                                handlePerPageChange={handlePerPageChange}
                                                itemsPerPage={itemsPerPage}
                                            />
                                            {totalHotels > 10 && lastPage > 1 && (
                                                <Pagination
                                                    handleNextPage = {handleNextPage}
                                                    handleBackPage = {handleBackPage}
                                                    totalPages = {lastPage}
                                                    currentPage ={page}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <ListItems
                                        onScrollToTop={onScrollToTop}
                                        locationUrl={locationUrl}
                                        loading={loading}
                                        type={type}
                                        itemList={displayedHotels.map(h => ({
                                            id: h.id,
                                            title: h.name,
                                            subTitle: h.address.city,
                                            imageUrl: h.image_path,
                                            deleteItem: h.deleted_at
                                        }))}
                                    />
                                </div>
                                : <div className={`flex justify-center mt-12 ${detailOpen ? ' ' : 'w-1/2'}`}>
                                    <p className="text-base text-main-brown">
                                        <Trans>Nenašlo sa žiadne zariadenie</Trans>
                                    </p>
                                </div>
                        }
                        <div className={`${detailOpen ? null : 'md:w-1/2 w-full md:border-l-2  border-opacity-15 border-main-brown pt-8 pl-7 pb-9 pr-[50px] flex flex-col'}`}>
                            <Outlet />
                        </div>
                    </div>
                    {displayedHotels.length > 0 && lastPage > 1 && (
                        <div className={` ${detailOpen ? 'block' : 'hidden'}`}>
                            { totalHotels > 10 &&
                            <div className='flex items-center lg:mx-[50px] pl-7 pr-5 justify-end mb-6'>
                                <Pagination
                                    handleNextPage={handleNextPage}
                                    handleBackPage={handleBackPage}
                                    totalPages={lastPage}
                                    currentPage={page}
                                />
                            </div>
                            }
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default PageDevices
