import React, { useState } from 'react'
import GlobalButton from '../global/GlobalButton'
import ModalWindow from '../global/ModalWindow'
import { type ModalWindowData } from '../../types'
import { t } from '@lingui/macro'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../redux/store'
import { generateVoucher } from '../../redux/thunks/voucherThunks'
import IconSuccess from '../icons/IconSuccess'
import IconWarning from '../icons/IconWarning'
import IconInfo from '../icons/IconInfo'
import { useIsAdmin } from '../../helpers'

interface DetailGenerateVoucherProps {
    foundVoucherNumbers: Array<{ number: string, state: string }>
    numericId: number
}

const DetailGenerateVoucher: React.FC<DetailGenerateVoucherProps> = (props: DetailGenerateVoucherProps) => {
    const { foundVoucherNumbers, numericId } = props
    const [open, setOpen] = useState(false)
    const dispatch = useDispatch<AppDispatch>()
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const [loading, setLoading] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const isAdmin = useIsAdmin()

    const handleGenerateVoucher = (): void => {
        setDisabledButton(true)
        setLoading(true)
        setOpen(true)
        void dispatch(generateVoucher(numericId))
            .then((response: any) => {
                setDisabledButton(false)
                setLoading(false)
                if (response.error !== undefined) {
                    setDataModal(
                        {
                            title: t`Chyba`,
                            subtitle: t`Nastala neočakávaná chyba.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning/>,
                            action: () => { setOpen(false) }
                        }
                    )
                } else {
                    setDataModal(
                        {
                            title: t`Voucher`,
                            subtitle: t`Voucher bol úspešne vygenerovaný.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconSuccess/>,
                            action: () => { setOpen(false) }
                        }
                    )
                }
            })
    }
    const handleOpenModalWindow = (): void => {
        setOpen(true)
        setDataModal(
            {
                title: t`Generovať voucher`,
                subtitle: t`Naozaj chcete vygenerovať voucher?`,
                textButton: t`Potvrdiť`,
                icon: <IconInfo/>,
                action: handleGenerateVoucher
            }
        )
    }

    const closeWindow = (): void => {
        setOpen(false)
    }

    return (
        <>
            <div>
                <ModalWindow
                    open={open}
                    setOpen={setOpen}
                    dataModalWindow={dataModal}
                    disabledButton={disabledButton}
                    loading={loading}
                    closeWindow={closeWindow}
                />
            </div>

            {foundVoucherNumbers.length === 0 && isAdmin &&
                <GlobalButton
                    handleOpenModalWindow={handleOpenModalWindow}
                />
            }
        </>
    )
}

export default DetailGenerateVoucher
