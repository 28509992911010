import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { type User } from '../../types'
import * as loginThunks from '../thunks/loginThunks'

interface LoginState {
    user: User | null
    loading: boolean
    error: string | null
}

const initialState: LoginState = {
    user: null,
    loading: false,
    error: null
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(loginThunks.login.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(loginThunks.login.fulfilled, (state, action: PayloadAction<User>) => {
                state.loading = false
                state.user = action.payload
            })
            .addCase(loginThunks.login.rejected, (state, action) => {
                state.loading = false
                state.error = action.payload as string
            })
            .addCase(loginThunks.logout.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(loginThunks.logout.fulfilled, (state, action) => {
                state.loading = true
                state.error = null
                state.user = null
            })
            .addCase(loginThunks.fetchUpdateUserProfile.fulfilled, (state, action: PayloadAction<{ data: User }>) => {
                state.loading = false
                state.user = { ...state.user, ...action.payload.data }
            })
            .addCase(loginThunks.fetchChangeLanguage.fulfilled, (state, action: PayloadAction<{ data: User }>) => {
                state.loading = false
                state.user = { ...state.user, ...action.payload.data }
            })
            .addCase(loginThunks.fetchChangeLanguage.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(loginThunks.fetchChangeLanguage.pending, (state) => {
                state.loading = true
            })
    }
})

export default loginSlice.reducer
