import React from 'react'
import { NavLink } from 'react-router-dom'

const LogoHeader: React.FC = () => {
    return (

        <div >
            <NavLink to={'/'}>
                <img
                    src={require('../../assets/img/logo/Image 1.png')}
                    className="logo w-full h-full"
                    alt='logo'
                />
            </NavLink>
        </div>
    )
}

export default LogoHeader
