/* eslint-disable lingui/no-unlocalized-strings */
import { Trans } from '@lingui/macro'
import { Link } from 'react-router-dom'
import React from 'react'
import IconHelp from '../icons/IconHelp'

const FooterSideBar: React.FC = () => {
    return (
        <div>
            <div>
                <IconHelp/>
                <div className='mt-2'>
                    <p className='text-[10px] text-light-black'>
                        <Trans>Máte problém? </Trans>
                    </p>
                    <p className='text-[10px] text-light-black'>
                        <Trans>Kontaktuje administrátora:</Trans>
                    </p>
                </div>
            </div>
            <div className='flex flex-col mt-[14px] -mx-2 space-y-1 '>
                <Link
                    to='mailto:admin@emporaclub.com'
                    className='text-[11px] text-light-black leading-5 pl-2 bg-item-hov h-transition'
                >
                    <p>admin@emporaclub.com</p>
                </Link>
            </div>
        </div>
    )
}

export default FooterSideBar
