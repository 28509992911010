/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { formatDate, getStatusEmail } from '../../helpers'
import { type EmailLogModel } from '../../types'
import Avatar from '../global/Avatar'
import { IconEmail } from '../icons/IconEmail'
import { IconDate } from '../icons/IconDate'
import { Trans } from '@lingui/macro'
import IconSubject from '../icons/IconSubject'
import IconEye from '../icons/IconEye'

interface IEmailLogProps {
    listLogs: EmailLogModel[]
}

const ListEmailLogs: React.FC<IEmailLogProps> = ({ listLogs }) => {
    const classAvatar = 'h-8 w-8'

    return (
        <div>
            <ul className="divide-y divide-main-brown divide-opacity-15">
                {listLogs.map((item, index) => {
                    return (
                        <li key={index}>
                            <NavLink
                                className={({ isActive }) =>
                                    `${isActive ? 'bg-light-gray block ' : ''} flex items-center list-items gap-x-6 py-4 pl-20 pr-10 lg-max:px-[25px] relative`
                                }
                                to={`/members/${item.client_id}`}
                            >
                                <div className="flex min-w-0 gap-x-4 items-center w-full">
                                    <div className="min-w-0 flex-auto">
                                        <div className='grid grid-cols-4 gap-5 items-baseline m-max:grid-cols-1'>
                                            <div className='flex flex-row'>
                                                <div className='h-8 w-8'>
                                                    <Avatar
                                                        imageUrl={item.image_path}
                                                        classAvatar={classAvatar}
                                                        type={item.sex}
                                                    />
                                                </div>
                                                <div className='ml-4'>
                                                    <p className="font-basic-black leading-[18px] font-montserrat-bold">
                                                        {item.name}
                                                    </p>
                                                    <div className='flex items-center'>
                                                        <IconEmail />
                                                        <p className="font-basic-black leading-[18px] ml-[5px]">
                                                            {item.email}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='flex items-center justify-end m-max:justify-start'>
                                                <div className='w-[10px] h-[12px] mr-2'>
                                                    <IconDate />
                                                </div>
                                                <p className='font-basic-black'>
                                                    <Trans>Dátum :</Trans><span> {formatDate(item.date_time)}</span>
                                                </p>
                                            </div>
                                            <div className='flex items-center'>
                                                <div>
                                                    <IconSubject />
                                                </div>
                                                <p className="font-basic-black leading-[18px] ml-[5px]">
                                                    {item.subject}
                                                </p>
                                            </div>
                                            <div className='flex items-center'>
                                                <span
                                                    className={`inline-block h-2 w-2 flex-shrink-0 rounded-full mr-2 ${getStatusEmail(item.status).bgDot}`}
                                                />
                                                <p className='font-basic-black'>
                                                    <Trans>Stav:</Trans>
                                                    <span className={`ml-1 ${getStatusEmail(item.status).colorText}`}>
                                                        {getStatusEmail(item.status).text}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='md:block hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                                    <button
                                        className={`btn btn-s btn-white shadow-btn-shadow font-basic font-think opacity-0 flex items-center border border-main-brown ${false && 'hidden'}`}
                                    >
                                        <IconEye />
                                        <Trans>Zobraziť detail</Trans>
                                    </button>
                                </div>
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ListEmailLogs
