import React from 'react'
import type { VoucherModel } from '../../types'
import { IconVoucher } from '../icons/IconVoucher'
import { Trans } from '@lingui/macro'
import { IconDate } from '../icons/IconDate'
import { formatDate, getStatus } from '../../helpers'
import IconPerson from '../icons/IconPerson'
import { Link } from 'react-router-dom'
interface VoucherListProps {
    listVoucher: VoucherModel[]
}

const ListVouchers: React.FC<VoucherListProps> = (props: VoucherListProps) => {
    const { listVoucher } = props

    return (
        <div>
            <ul className="divide-y divide-main-brown divide-opacity-15">
                {listVoucher.map((item, index) => (
                    <li key={index} className='flex  items-center   py-4 pl-20 lg-max:px-[25px] m-max:flex-col m-max:gap-y-2'>
                        <div className='flex w-[30%] m-max:w-full'>
                            <div className='md:w-[27px] md:h-4 md:mr-4 h-3 w-5 mr-2'>
                                <IconVoucher />
                            </div>
                            <p className='font-basic-black font-montserrat-bold'><Trans>Voucher</Trans><span> {item.number}</span></p>
                        </div>
                        <div className='flex items-center w-[25%]  m-max:w-full '>
                            <div className='w-[10px] h-[12px] mr-2'>
                                <IconPerson />
                            </div>
                            <div className='font-basic-black'><Link to={`/members/${item.client_id}`}>{item.client_name}</Link></div>
                        </div>
                        <div className='flex items-center w-1/4  m-max:w-full'>
                            <div className='w-[10px] h-[12px] mr-2'>
                                <IconDate />
                            </div>
                            <p className='font-basic-black'><Trans>Platnosť do:</Trans><span> {formatDate(item.expiration_date)}</span></p>
                        </div>
                        { <div className='flex items-center w-1/4  m-max:w-full'>
                            <span
                                className={`inline-block h-2 w-2 flex-shrink-0 rounded-full mr-2 
                                ${getStatus(item.state).bgDot}`}
                            />
                            <p className='font-basic-black '>
                                <Trans>Stav:</Trans>
                                <span
                                    className={`ml-1 ${getStatus(item.state).colorText}`}
                                >
                                    {getStatus(item.state).text}
                                </span>
                            </p>
                        </div> }
                    </li>))}

            </ul>
        </div>
    )
}

export default ListVouchers
