/* eslint-disable lingui/no-unlocalized-strings */
import React, { type ReactNode } from 'react'
import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/20/solid'

interface ModalWindowProps {
    open: boolean
    setOpen: (open: boolean) => void
    myFormComponent: ReactNode
    openModalForm?: boolean | undefined
    titleModalForm: string
    subtitleModalForm: string
}

const ModalWindowForm: React.FC<ModalWindowProps> = (props: ModalWindowProps) => {
    const { open, setOpen, myFormComponent, titleModalForm, subtitleModalForm } = props

    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-light-black bg-opacity-20  transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-20 bg-white lg:px-[60px] px-[25px] py-[30px]  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[800px] ">
                                <div className="absolute right-0 top-0  pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-main-brown transform transition duration-500 hover:scale-[1.8]"
                                        onClick={() => { setOpen(false) }}
                                    >
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div>
                                    <div className='text-center'>
                                        <h4 className='uppercase text-base text-main-brown font-montserrat-bold pb-15 tracking-1.6'>
                                            {titleModalForm}
                                        </h4>
                                        <p className='text-11 text-main-brown pb-8'>
                                            {subtitleModalForm}
                                        </p>
                                    </div>
                                    {myFormComponent }
                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalWindowForm
