/* eslint-disable lingui/no-unlocalized-strings */
import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { type statusOptions } from '../../types'

interface SelectStatusProps {
    textSelectStatus: string
    handleVoucherStatus: (status: any) => void
    options: statusOptions []
    status: string
}

function classNames(...classes: any): string {
    return classes.filter(Boolean).join(' ')
}

const Filter: React.FC<SelectStatusProps> = ({ textSelectStatus, handleVoucherStatus, options, status }) => {
    const [selected, setSelected] = useState(status)

    const handleSelectVoucher = (selected: any): void => {
        setSelected(selected)
        handleVoucherStatus(selected)
    }

    return (
        <Listbox value={status} onChange={handleSelectVoucher}>
            {({ open }) => (
                <div className='flex items-baseline'>
                    <Listbox.Label className="font-basic">
                        {textSelectStatus}
                    </Listbox.Label>
                    <div className="relative">
                        <Listbox.Button className="relative w-full cursor-pointer pl-3 pr-10 text-left text-11 font-montserrat-bold focus:outline-none">
                            <span className="flex items-center">
                                <span className={`inline-block h-2 w-2 flex-shrink-0 rounded-full bg-${options.find(option => option.id === selected)?.color}`}/>
                                <span className={`ml-1 block truncate font-montserrat-bold text-11 text-${options.find(option => option.id === selected)?.color}`}>
                                    {options.find(option => option.id === selected)?.name}
                                </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronDownIcon
                                    className={classNames(
                                        open ? 'rotate-[180deg] ' : '',
                                        'h-4 w-4 text-main-brown'
                                    )}
                                    aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white text-11 shadow-lg divide-y divide-main-brown divide-opacity-15">
                                {options?.map((option) => (
                                    <Listbox.Option
                                        key={option.id}
                                        value={option.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-light-gray ' : '',
                                                'cursor-default select-none relative py-1 pl-3 pr-9'
                                            )
                                        }
                                    >
                                        <div className="flex items-center">
                                            <span className={`inline-block h-2 w-2 flex-shrink-0 rounded-full mr-2 bg-${option.color}`} />
                                            <span className={`ml-1 block text-11 text-${option.color}`}>
                                                {option.name}
                                            </span>
                                        </div>
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export default Filter
