import React from 'react'

export const IconFemale: React.FC = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="15.187" viewBox="0 0 10.833 15.187">
            <path id="female" d="M79.661,10.765v1.053h.7a.857.857,0,0,1,0,1.715h-.7v.8a.857.857,0,1,1-1.715,0v-.8h-.7a.857.857,0,0,1,0-1.715h.7V10.765a5.417,5.417,0,1,1,1.715,0ZM75.1,5.417a3.7,3.7,0,1,0,3.7-3.7A3.706,3.706,0,0,0,75.1,5.417Z" transform="translate(-73.387 0)" fill="#c6c0b2" fillRule="evenodd" />
        </svg>

    )
}
