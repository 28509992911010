import { Trans } from '@lingui/macro'
import React from 'react'
import FormAddNewUser from '../components/forms/FormAddNewUser'

const PageAddNewUser: React.FC = () => {
    return (
        <>
            <div className='lg:mt-9 lg:mx-[50px] lg:mb-24 rounded-[10px] pt-[26px] text-center lg-max:px-[25px] lg-max:pt-[22px] lg-max:pb-8'>
                <h4 className='uppercase md:text-base text-[11px] text-main-brown font-montserrat-bold pb-15 tracking-1.6'>
                    <Trans>PRIDANIE NOVÉHO administrátora</Trans>
                </h4>
                <p className='md:text-11 text-[10px] text-main-brown pb-8'>
                    <Trans>Vyplňte údaje o administrátrovi</Trans>
                </p>
                <FormAddNewUser />
            </div>

        </>
    )
}

export default PageAddNewUser
