/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect, useRef, useState } from 'react'
import HeaderSubPages from '../components/header/HeaderSubPages'
import { type HeaderSupPage } from '../types'
import ListMember from '../components/listItems/ListMember'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import { type AppDispatch, type RootState } from '../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { selectInputValue } from '../redux/slices/searchInputSlice'
import SelectPage from '../components/global/SelectPage'
import Pagination from '../components/global/Pagination'
import Loader from '../components/global/Loader'
import { fetchPaginationClients } from '../redux/thunks/clientThunks'

const PageMembers: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const clients = useSelector((state: RootState) => state.client.data)
    const totalClients = useSelector((state: RootState) => state.client.total)
    const loading: boolean = useSelector((state: RootState) => state.client.loading)
    const lastPage = useSelector((state: RootState) => state.client.lastPage)
    const page = useSelector((state: RootState) => state.client.currentPage)
    const inputValue = useSelector(selectInputValue)
    const navigate = useNavigate()

    const [displayedClients, setDisplayedClients] = useState(clients)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [detailOpen, setDetailOpen] = useState(false)
    const topMemberRef = useRef<HTMLDivElement>(null)
    const location = useLocation()

    useEffect(() => {
        setDisplayedClients(clients)
    }, [clients, totalClients])

    useEffect(() => {
        if (inputValue !== '') {
            setCurrentPage(1)
        }
    }, [inputValue])

    useEffect(() => {
        void dispatch(fetchPaginationClients({ page: currentPage, perPage: itemsPerPage, keyword: inputValue }))
    }, [currentPage, itemsPerPage, inputValue])

    const memberText = (number: number): string => {
        if (number === 1) {
            return t`Člen`
        } else if (number > 1 && number < 5) {
            return t`Členovia`
        } else {
            return t`Členov`
        }
    }

    const dataHeader: HeaderSupPage = {
        titleHeader: t`Členovia`,
        countHeader: totalClients,
        textCountHeader: memberText(totalClients),
        textButtonHeader: t`Pridať nového člena`,
        placeHolderSearchText: t`Vyhľadať člena`,
        class: 'opacity-100'
    }
    const textSelectPage = t`Zobraziť počet členov`

    const handleAdd = (): void => {
        navigate('/addnewmember')
    }

    useEffect(() => {
        setDetailOpen(location.pathname === '/members')
    }, [location.pathname])

    const handleNextPage = (): void => {
        setCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
        setDetailOpen(true)
        navigate('/members')
    }

    const handleBackPage = (): void => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
        setDetailOpen(true)
        navigate('/members')
    }

    const handlePerPageChange = (selectNumber: any): void => {
        setItemsPerPage(selectNumber)
        setCurrentPage(1)
    }

    const onScrollToTop = (): void => {
        if (topMemberRef.current != null) {
            topMemberRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    return (
        <>
            <HeaderSubPages
                dataHeader={dataHeader}
                onAdd={handleAdd}
                hideInput = {false}

            />
            {loading
                ? <div className='flex justify-center items-center mt-5 flex-col'>
                    <Loader/>
                    <p className='font-basic-black'>
                        <Trans>Načítavam data...</Trans>
                    </p>
                </div>
                : <>
                    <div ref={topMemberRef} className={`${clients.length > 10 ? 'h-auto' : ' 3xl:h-full'} ${detailOpen ? '' : 'flex'}`}>

                        {displayedClients.length > 0
                            ? <div className={`${detailOpen ? '' : 'w-1/2 md:block hidden'}`}>
                                <div >
                                    <div className={`${detailOpen ? 'lg:mx-[50px] lg-max:px-[25px] pl-7 pr-5' : 'ml-[50px] mr-[10px] pr-1'} flex items-center flex-wrap justify-between mt-7 mb-4  lg-max:gap-y-2`}>
                                        <SelectPage
                                            textSelectPage={textSelectPage}
                                            handlePerPageChange={handlePerPageChange}
                                            itemsPerPage={itemsPerPage}
                                        />
                                        {totalClients > 10 && lastPage > 1 && (
                                            <Pagination
                                                handleNextPage = {handleNextPage}
                                                handleBackPage = {handleBackPage}
                                                totalPages = {lastPage}
                                                currentPage ={page}
                                            />
                                        )}
                                    </div>
                                </div>
                                <ListMember
                                    detailOpen={detailOpen}
                                    onScrollToTop={onScrollToTop}
                                    itemMember={displayedClients.map(c => ({
                                        id: c.id,
                                        numberRegister: c.registration_number,
                                        name: c.name,
                                        imageUrl: c.image_path,
                                        email: c.email,
                                        birthDate: c.birth_date,
                                        sex: c.sex,
                                        deletedClien: c.deleted_at
                                    })
                                    )} />
                            </div>
                            : <div className={`flex justify-center mt-12 ${detailOpen ? ' ' : 'w-1/2'}`}>
                                <p className="text-base text-main-brown">
                                    <Trans>Nenašiel sa žiaden člen</Trans>
                                </p>
                            </div>}
                        <div className={`${detailOpen ? null : 'md:w-1/2 w-full md:border-l-2  border-opacity-15 border-main-brown pt-8 pl-7 pb-9 pr-[50px] flex flex-col'}`}>
                            <Outlet />
                        </div>
                    </div>
                    {displayedClients.length > 0 && lastPage > 1 && (
                        <div className={` ${detailOpen ? 'block' : 'hidden'}`}>
                            {totalClients > 10 &&
                                <div className='flex items-center lg:mx-[50px] pl-7 pr-5 justify-end mb-6'>
                                    <Pagination
                                        handleNextPage={handleNextPage}
                                        handleBackPage={handleBackPage}
                                        totalPages={lastPage}
                                        currentPage={page} />
                                </div>
                            }
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default PageMembers
