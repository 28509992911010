import React from 'react'
interface DetailContentProps {
    textTileDetail: string
    oneItemDetailInfo?: string
    hideTitle?: boolean
}

const DetailContent: React.FC<DetailContentProps> = (props: DetailContentProps) => {
    const { textTileDetail, oneItemDetailInfo, hideTitle } = props

    return (
        <>
            { oneItemDetailInfo !== null && oneItemDetailInfo !== undefined &&
                <div className='mt-12'>

                    <div className={`${(hideTitle === true) ? 'hidden' : 'block'}`}>
                        <h4 className='font-basic font-montserrat-bold'>
                            {textTileDetail}
                        </h4>
                        <p className='font-basic-black mt-1'>
                            {oneItemDetailInfo}
                        </p>
                    </div>

                </div>
            }
        </>
    )
}

export default DetailContent
