/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect, useState } from 'react'
import HeaderSubPages from '../components/header/HeaderSubPages'
import type { HeaderSupPage } from '../types'
import { Trans, t } from '@lingui/macro'
import Filter from '../components/global/Filter'
import Pagination from '../components/global/Pagination'
import ListVouchers from '../components/listItems/ListVouchers'
import { type RootState, type AppDispatch } from '../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import SelectPage from '../components/global/SelectPage'
import Loader from '../components/global/Loader'
import { fetchPaginationVouchers } from '../redux/thunks/voucherThunks'
import { selectInputValue } from '../redux/slices/searchInputSlice'
import { useIsAdmin } from '../helpers'

const PageVouchers: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const vouchers = useSelector((state: RootState) => state.voucher.data)
    const totalVouchers = useSelector((state: RootState) => state.voucher.total)
    const loading: boolean = useSelector((state: RootState) => state.voucher.loading)
    const lastPage = useSelector((state: RootState) => state.voucher.lastPage)
    const page = useSelector((state: RootState) => state.voucher.currentPage)
    const inputValue = useSelector(selectInputValue)

    const [displayedVouchers, setDisplayedVouchers] = useState(vouchers)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [voucherStatus, setVoucherStatus] = useState('all')
    const isAdmin = useIsAdmin()
    const hideButton = true
    const statusVouchers = [
        { id: 'all', name: t`Všetky`, color: '#cccccc' },
        { id: 'unused', name: t`Aktívny`, color: 'active' },
        { id: 'expired', name: t`Expirovaný`, color: 'expired' },
        { id: 'used', name: t`Uplatnený`, color: 'claimed' }
    ]

    useEffect(() => {
        setDisplayedVouchers(vouchers)
    }, [vouchers])

    useEffect(() => {
        if (inputValue !== '') {
            setCurrentPage(1)
        }
    }, [inputValue])

    useEffect(() => {
        void dispatch(fetchPaginationVouchers({ page: currentPage, perPage: itemsPerPage, status: voucherStatus, keyword: inputValue }))
    }, [currentPage, itemsPerPage, voucherStatus, inputValue])

    const voucherText = (number: number): string => {
        if (number === 1) {
            return t`Voucher`
        } else if (number > 1 && number < 5) {
            return t`Vouchery`
        } else {
            return t`Voucherov`
        }
    }

    const dataHeader: HeaderSupPage = {
        titleHeader: t`Vouchery`,
        countHeader: totalVouchers,
        textCountHeader: voucherText(totalVouchers),
        textButtonHeader: t`Generovať nový vouchers`,
        placeHolderSearchText: t`Vyhľadať voucher`,
        class: isAdmin ? 'opacity-25' : 'opacity-100'
    }
    const textSelectStatus = t`Filtrovať podľa stavu:`
    const textSelectPage = t`Zobraziť počet voucherov:`

    const handleAdd = (): void => {
        console.log('Adding...')
    }

    const handleNextPage = (): void => {
        setCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
    }

    const handleBackPage = (): void => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
    }

    const handleVoucherStatus = (status: any): void => {
        setVoucherStatus(status)
        setCurrentPage(1)
    }

    const handlePerPageChange = (selectNumber: any): void => {
        setItemsPerPage(selectNumber)
        setCurrentPage(1)
    }

    return (
        <>
            <HeaderSubPages
                dataHeader={dataHeader}
                onAdd={handleAdd}
                hideButton={hideButton}
                hideInput = {false}
            />
            <div className='flex items-baseline lg:mx-[50px] pl-7 pr-5 justify-between mt-7 mb-4 lg-max:px-[25px] md:flex-nowrap flex-wrap md:gap-y-0 gap-y-4'>
                <div className='md:flex md:w-3/4 justify-between'>
                    <Filter
                        textSelectStatus={textSelectStatus}
                        handleVoucherStatus={handleVoucherStatus}
                        options={statusVouchers}
                        status={voucherStatus}
                    />
                    <SelectPage
                        textSelectPage={textSelectPage}
                        handlePerPageChange={handlePerPageChange}
                        itemsPerPage={itemsPerPage}
                    />
                </div>
                {totalVouchers > 10 && lastPage > 1 && (
                    <div className='md:flex md:w-1/4 justify-end'>
                        <Pagination
                            handleNextPage={handleNextPage}
                            handleBackPage={handleBackPage}
                            totalPages={lastPage}
                            currentPage={page}
                        />
                    </div>
                )}
            </div>

            {loading
                ? <div className='flex justify-center items-center mt-5 flex-col'>
                    <Loader />
                    <p className='font-basic-black'>
                        <Trans>Načítavam data...</Trans>
                    </p>
                </div>
                : <>
                    <div className={`${itemsPerPage >= 50 ? '' : 'h-full'}`}>
                        {
                            displayedVouchers.length > 0
                                ? <ListVouchers listVoucher={displayedVouchers} />
                                : <div className='flex justify-center mt-12'>
                                    <p className="text-base text-main-brown">
                                        <Trans>Nenašiel sa žiaden voucher</Trans>
                                    </p>
                                </div>
                        }
                    </div>
                    { totalVouchers > 10 && lastPage > 1 && (
                        <div className={` ${displayedVouchers.length > 0 ? 'block' : 'hidden'}`}>
                            <div className='flex items-center lg:mx-[50px] pl-7 pr-5 justify-end mb-6'>
                                <Pagination
                                    handleNextPage={handleNextPage}
                                    handleBackPage={handleBackPage}
                                    totalPages={lastPage}
                                    currentPage={page}
                                />
                            </div>
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default PageVouchers
