/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { type ModalWindowData, type InputsAddNewUser, type User } from '../../types'
import { Trans, t } from '@lingui/macro'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch, type RootState } from '../../redux/store'

import { type SubmitHandler, useForm, Controller } from 'react-hook-form'
import { fetchAllHotels } from '../../redux/thunks/hotelThunks'
import Loader from '../global/Loader'
import { fetchUpdateUserProfile } from '../../redux/thunks/loginThunks'
import FadeAnimation from '../global/FadeAnimation'
import ModalWindow from '../global/ModalWindow'
import IconWarning from '../icons/IconWarning'
import IconSuccess from '../icons/IconSuccess'
import { useIsAdmin } from '../../helpers'
interface UpdateUsersProps {
    itemId?: number
    updateForm?: boolean
    setClose?: React.Dispatch<React.SetStateAction<boolean>>
    profilUser: User | null
}

const FormUpdateProfil: React.FC<UpdateUsersProps > = (props: UpdateUsersProps) => {
    const { updateForm, profilUser, setClose } = props
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const dispatch = useDispatch<AppDispatch>()
    const [loading, setLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [errorFile, setErrorFile] = useState('')
    const [open, setOpen] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const isAdmin = useIsAdmin()

    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors }
    } = useForm<InputsAddNewUser>({
        defaultValues: {
            name: profilUser?.name,
            email: profilUser?.email,
            comment: profilUser?.comment,
            sex: profilUser?.sex
        }
    })

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    useEffect(() => {
        setValue('role', '')
    }, [setValue])

    const closeWindow = (): void => {
        setOpen(false)
        setClose?.(false)
    }

    const onSubmit: SubmitHandler<InputsAddNewUser> = (data) => {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('password', data.password)
        formData.append('hotel_id', data.hotel_id)
        if (isAdmin) {
            formData.append('role', 'admin')
        } else {
            formData.append('role', 'user')
        }

        formData.append('comment', data.comment ?? '')
        formData.append('image', data.image[0])
        formData.append('sex', data.sex)
        formData.append('address[street]', '0')
        formData.append('address[city]', '0')
        formData.append('address[postcode]', '0')
        formData.append('address[state]', '0')
        formData.append('birth_date', '01.01.2000')
        if (isAdmin) {
            formData.append('is_admin', '1')
        } else {
            formData.append('is_admin', '0')
        }
        formData.append('preferred_languange', 'en')

        setLoading(true)

        void dispatch(fetchUpdateUserProfile(formData))
            .then((response: any) => {
                if (response.payload.status === 'error_email') {
                    setDataModal({
                        title: t`Chyba`,
                        subtitle: t`Administrátor s touto e-mailovou adresou už existuje.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: closeWindow
                    })
                    setOpen(true)
                    setLoading(false)
                } else if (response.payload.message === undefined) {
                    setDataModal({
                        title: t`Úprava profilu`,
                        subtitle: t`Profil bol úspešne upravený.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconSuccess/>,
                        action: closeWindow
                    })
                    setOpen(true)
                } else {
                    setDataModal({
                        title: t`Chyba`,
                        subtitle: t`Nastala chyba pri úprave profilu.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: closeWindow
                    })
                    setOpen(true)
                }
            })

        setLoading(false)
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files?.[0]
        setFileLoading(true)
        if (file != null) {
            setFileLoading(false)
        } else {
            setErrorFile(t`Nahravanie súbra zlyhalo`)
        }
    }

    const sex = watch('sex')

    return (
        <><ModalWindow
            open={open}
            setOpen={setOpen}
            dataModalWindow={dataModal}
            closeWindow={closeWindow}
        />
        <div className='absolute top-[265px] left-1/2 '>
            <Loader loading={loading}/>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row ">
                <div className="md:w-1/2 w-full pb-3">
                    <label htmlFor="name" className="sm-font-b">
                        <Trans>Meno a priezvisko*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="text"
                            name="name"
                            id="name"
                            className="input-form py-[5px] input-focus input-transition font-basic"
                        />
                        {(errors.name != null) && (
                            <FadeAnimation type="up" key={errors.name.message}>
                                <div className="error-text">
                                    {errors.name.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>

                <div className="md:w-1/2 w-full pb-3">
                    <label htmlFor="email" className="sm-font-b">
                        <Trans>E-mailová adresa*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t`Zadajte platnú e-mailovú adresu`
                                }
                            })}
                            type="email"
                            name="email"
                            id="email"
                            className="input-form py-[5px] input-focus input-transition font-basic"
                        />
                        {(errors.email != null) && (
                            <FadeAnimation type="up" key={errors.email.message}>
                                <div className="error-text">
                                    {errors.email.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
                {updateForm === true
                    ? null
                    : <div className={'md:w-1/2 w-full'}>

                        <><><label htmlFor="password" className="sm-font-b">
                            <Trans>Heslo*</Trans>
                        </label><div className="mt-[6px]">
                            <Controller
                                name="password"
                                control={control}
                                defaultValue=""
                                rules={{
                                    required: t`Pole je povinné`,
                                    minLength: {
                                        value: 8,
                                        message: t`Heslo musí obsahovať aspoň 8 znakov`
                                    },
                                    validate: (value) => {
                                        const hasUppercase = /[A-Z]/.test(value)
                                        const hasLowercase = /[a-z]/.test(value)
                                        const hasNumber = /\d/.test(value)
                                        if (!hasUppercase) {
                                            return t`Heslo musí obsahovať aspoň jedno veľké písmeno`
                                        }
                                        if (!hasLowercase) {
                                            return t`Heslo musí obsahovať aspoň jedno malé písmeno`
                                        }
                                        if (!hasNumber) {
                                            return t`Heslo musí obsahovať aspoň jedno číslo`
                                        }
                                        return true
                                    }
                                }}
                                render={({ field }) => <input
                                    className="input-form py-[5px] input-focus input-transition font-basic"
                                    type="password" {...field} />} />
                        </div></><div>
                            <p className='error-text'>{errors.password?.message}</p>
                        </div></>

                    </div>
                }
            </div>
            <div className="flex items-center text-left lg:pl-12 pb-3">
                <legend className="sm-font-b xs:mr-7 mr-4">
                    <Trans>Pohlavie*</Trans>
                </legend>
                <div className="relative flex items-start">
                    <div className="flex h-6 items-center mr-7">
                        <Controller
                            control={control}
                            name="sex"
                            defaultValue=""
                            rules={{ required: t`Vyberte jednu z možností.` }}
                            render={({ field }) => (
                                <>
                                    <input
                                        id="male"
                                        type="checkbox"
                                        checked={field.value === 'male'}
                                        onChange={() => { setValue('sex', 'male') }}
                                        className="input-transition input-checkbox"
                                    />
                                    <label htmlFor="male" className="font-basic ml-[10px]">
                                        <Trans>Muž</Trans>
                                    </label>
                                </>
                            )}
                        />
                    </div>
                    <div className="flex h-6 items-center">
                        <Controller
                            control={control}
                            name="sex"
                            defaultValue=""
                            render={({ field }) => (
                                <>
                                    <input
                                        id="female"
                                        type="checkbox"
                                        checked={field.value === 'female'}
                                        onChange={() => { setValue('sex', 'female') }}
                                        className="input-transition input-checkbox"
                                    />
                                    <label htmlFor="female" className="font-basic ml-[10px]">
                                        <Trans>Žena</Trans>
                                    </label>
                                    <div className='ml-3'>
                                        {(field.value === '') && (
                                            <p className="error-text">{errors.sex?.message}</p>
                                        )}
                                    </div>
                                </>
                            )}
                        />
                    </div>
                    {sex === '' && (
                        <FadeAnimation type="up" key={errors.sex?.message}>
                            <div className="error-text">
                                {errors.sex?.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
            </div>
            <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row">
                <div className="md:w-1/2  w-full pb-3">
                    <label htmlFor="hotel" className="sm-font-b">
                        <Trans>Zariadenie*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <select
                            {...register('hotel_id', {
                                required: {
                                    value: true,
                                    message: t`Vyberte jednu z možností.`
                                }
                            })}
                            id="hotel"
                            name="hotel_id"
                            className="input-form py-[5px] input-focus input-transition font-basic"
                            defaultValue={profilUser?.hotel_id}
                        >
                            <option value={''} disabled ><Trans>Vyberte zariadenie</Trans></option>
                            {hotels.map(hotel => (
                                <option
                                    key={hotel.id}
                                    value={hotel.id}
                                >
                                    {hotel.name}
                                </option>
                            ))}
                        </select>
                        {(errors.hotel_id != null) && (
                            <FadeAnimation type="up" key={errors.hotel_id.message}>
                                <div className="error-text">
                                    {errors.hotel_id.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>

                <div className="md:w-1/2 w-full pb-3">
                    <label htmlFor="comment" className="sm-font-b">
                        <Trans>Poznámka</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <textarea
                            {...register('comment')}
                            name="comment"
                            id="note"
                            className="input-form py-[5px] input-focus input-transition font-basic"
                        />
                    </div>
                </div>
            </div>
            <div className='flex justify-center items-center flex-col'>
                <Loader loading={fileLoading}/>
                {fileLoading &&
                        <p className='font-basic-black'>
                            <Trans>Nahrávam súbor...</Trans>
                        </p>
                }

            </div>
            <div className="flex justify-center text-left  lg:px-[46.5px]">
                <div className="w-full">
                    <label htmlFor="image" className="sm-font-b" >
                        <Trans>Fotka</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('image')}
                            className="
                                input-form py-[5px] input-focus input-transition font-basic input-file"
                            type="file"
                            id="image"
                            name='image'
                            onChange={handleFileChange}
                        />
                        {(errorFile.length > 0) && (
                            <FadeAnimation type="up" key={errorFile}>
                                <div className="error-text">
                                    {errorFile}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
            </div>
            <button
                className='btn btn-red btn-m block mx-auto mt-5 w-max-[189px]'
                type="submit"
            >
                {(updateForm === true) ? t`Upraviť administrátora` : t`Pridať administrátora`}
            </button>
        </form>

        </>
    )
}

export default FormUpdateProfil
