import React from 'react'

export const IconDate: React.FC = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="11.312" height="11.312" viewBox="0 0 11.312 11.312">
            <g id="Layer_93" data-name="Layer 93" transform="translate(-2 -2)">
                <path id="Path_44" data-name="Path 44" d="M11.615,3.7H11.05V2.566a.566.566,0,0,0-1.131,0V3.7H5.394V2.566a.566.566,0,1,0-1.131,0V3.7H3.7A1.7,1.7,0,0,0,2,5.394v.566H13.312V5.394A1.7,1.7,0,0,0,11.615,3.7Z" fill="#c6c0b2" />
                <path id="Path_45" data-name="Path 45" d="M2,15.525a1.7,1.7,0,0,0,1.7,1.7h7.918a1.7,1.7,0,0,0,1.7-1.7V11H2Z" transform="translate(0 -3.91)" fill="#c6c0b2" />
            </g>
        </svg>

    )
}
