import React from 'react'
import { IconTrash } from '../icons/IconTrash'
import { t } from '@lingui/macro'

interface DetailFooterProps {
    handleOpenFooter?: () => void
    textDeleteButton?: string
    isDeleted?: string | null
}

export const DetailFooter: React.FC<DetailFooterProps> = (props: DetailFooterProps) => {
    const { handleOpenFooter, textDeleteButton, isDeleted } = props

    return (
        <div className='mt-7'>
            <button
                className={`${isDeleted === null ? 'text-red link-red' : 'text-green-500 link-green'} text-11  font-montserrat-bold flex items-center link-hover`}
                onClick={handleOpenFooter}
                title={t`Deaktivovať`}
            >
                {isDeleted === null &&
                <div className='mr-2'>
                    <IconTrash />
                </div>
                }
                {textDeleteButton}
            </button>
        </div>
    )
}
