import { Trans } from '@lingui/macro'
import React from 'react'
import { NavLink } from 'react-router-dom'

const PageNotFound: React.FC = () => {
    return (
        <div className='h-[calc(100vh-152px)]'>
            <div className='bg-light-gray mt-9 mx-[50px] shadow-m-shadow rounded-[10px] mb-10'>
                <div className='text-center py-[26px]'>
                    <h4 className='uppercase text-base text-main-brown font-montserrat-bold pb-15 tracking-1.6'>
                        <Trans>STRÁNKA NEEXISTUJE</Trans>
                    </h4>
                    <p className='text-11 text-main-brown pb-15'>
                        <Trans>Vráťte sa na úvodnú stránku alebo vyberte položku v ľavom menu.</Trans>
                    </p>
                    <NavLink
                        className='btn btn-red btn-m block mx-auto mt-5'
                        to={'/'}
                    >
                        <Trans>Vrátiť na úvod</Trans>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound
