/* eslint-disable react/no-children-prop */
/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect } from 'react'
import '../src/styles/App.scss'
import { Route, Routes } from 'react-router-dom'
import PageHome from './pages/PageHome'
import PageLogin from './pages/PageLogin'
import PageUsers from './pages/PageUsers'
import PageDevices from './pages/PageDevices'
import PageChangePassword from './pages/PageChangePassword'
import PageAddNewMember from './pages/PageAddNewMember'
import PageMembers from './pages/PageMembers'
import PageAddNewDevice from './pages/PageAddNewDevice'
import PageNotFound from './pages/PageNotFound'
import PageProfilUser from './pages/PageProfilUser'
import PageVouchers from './pages/PageVouchers'
import PrivateRoute from './components/utils/PrivateRoute'
import DetailComponentHotel from './components/detail/DetailComponentHotel'
import DetailComponentUser from './components/detail/DetailComponentUser'
import DetailComponentMember from './components/detail/DetailComponentMember'
import PageResetPassword from './pages/PageResetPassword'
import PageAddNewUser from './pages/PageAddNewUser'
import AdminRoute from './components/utils/AdminRoute'
import PageEmailLog from './pages/PageEmailLog'

// import { useLingui } from '@lingui/react'
// import { useSelector } from 'react-redux'
// import { type RootState } from './redux/store'

function App(): JSX.Element {
    // const lingui = useLingui()
    // const user = useSelector((state: RootState) => state.login.user)

    // useEffect(() => {
    //     if (user?.preferred_languange !== undefined) {
    //         lingui.i18n.activate(user.preferred_languange)
    //     }
    // }, [user, lingui.i18n])

    useEffect(() => {
        document.title = 'Empora'
    }, [])

    return (
        <Routes>
            <Route path='/login' element={<PageLogin />} />
            <Route path='/reset-password/*' element={<PageResetPassword/>}/>
            <Route path='/*' element={<PrivateRoute />}>
                <Route path='' element={<PageHome />} />
                <Route path='administrators/*' element={<PageUsers />}>
                    <Route
                        path=':id'
                        element={<DetailComponentUser/>}
                    >
                    </Route>
                </Route>
                <Route path='facilities/*' element={<PageDevices />}>
                    <Route
                        path=':id'
                        element={<DetailComponentHotel />}>
                    </Route>
                </Route>
                <Route path='members/*' element={<PageMembers/>}>
                    <Route
                        path=':id'
                        element={<DetailComponentMember />}>
                    </Route>
                </Route>
                <Route path='changepassword/' element={<PageChangePassword />} />
                <Route path='addnewmember/' element={<PageAddNewMember />} />
                <Route path='addnewfacility/' element={
                    <AdminRoute>
                        <PageAddNewDevice />
                    </AdminRoute>
                } />
                <Route path='addnewadministrator/' element={
                    <AdminRoute>
                        <PageAddNewUser />
                    </AdminRoute>
                } />
                <Route path='profil/' element={<PageProfilUser />} />
                <Route path='vouchers/' element={<PageVouchers />} />
                <Route path='emaillog/' element={<PageEmailLog/>} />
                <Route path='*' element={<PageNotFound />} />
            </Route>
        </Routes>
    )
}

export default App
