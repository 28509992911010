import { type EmailLogModel } from '../../types'
import { createSlice } from '@reduxjs/toolkit'
import * as emailLogThunks from '../thunks/emailLogThunks'
import { type RootState } from '../store'

interface EmailLogState {
    data: EmailLogModel[]
    loading: boolean
    error: string | null
    currentPage: number
    total: number
    lastPage: number
}

const initialState: EmailLogState = {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    total: 0,
    lastPage: 0
}

const emailLogSlice = createSlice({
    name: 'emailLog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(emailLogThunks.fetchPaginationEmailLogs.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(emailLogThunks.fetchPaginationEmailLogs.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data.map(item => {
                    let updatedStatus = item.status

                    if (item.status === 'delivery') {
                        updatedStatus = 'success'
                    } else if (item.status === 'unsuccess') {
                        updatedStatus = 'unsuccess'
                    }

                    return {
                        ...item,
                        status: updatedStatus
                    }
                })
                state.currentPage = action.payload.currentPage
                state.total = action.payload.total
                state.lastPage = action.payload.lastPage
            })
            .addCase(emailLogThunks.fetchPaginationEmailLogs.rejected, (state) => {
                state.loading = false
                state.error = 'error'
            })
    }
})

export const selectEmailLog = (state: RootState): EmailLogModel[] => state.emailLog.data
export const selectEmailLogLoading = (state: RootState): boolean => state.emailLog.loading
export const selectEmailLogError = (state: RootState): string | null => state.emailLog.error

export default emailLogSlice.reducer
