/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'

const IconAvatarWoman: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            width={'100%'}
            height={'100%'}
            fill="#c6c0b2"
            data-name="Layer 1"
            viewBox="0 0 256 256"
        >
            <g id="SVGRepo_iconCarrier">
                <defs>
                    <style>{'.cls-2{fill:#000}.cls-7{opacity:.2}'}</style>
                </defs>
                <g id="Female_1" data-name="Female 1">
                    <path
                        id="Wallpaper"
                        d="M249.16 127.72a120.25 120.25 0 0 1-28.27 77.63 123.4 123.4 0 0 1-9.2 9.79h-166a101.12 101.12 0 0 1-8.95-8.86c-.24-.27-.51-.59-.81-.95-6.93-8.26-29.37-37.55-28.21-77.61C9.33 72.21 56 7 128.44 7a120.72 120.72 0 0 1 120.72 120.72Z"
                        style={{
                            fill: '#c6c0b2'
                        }}
                    />
                    <circle
                        id="Chignon"
                        cx={128.23}
                        cy={41.64}
                        r={22.11}
                        className="cls-2"
                    />
                    <circle
                        id="ChignonShadow"
                        cx={128.23}
                        cy={41.64}
                        r={22.11}
                        style={{
                            opacity: 0.2,
                            fill: '#000'
                        }}
                    />
                    <path
                        id="Sweater"
                        d="M220.88 205.35A119.78 119.78 0 0 1 191 231a119.83 119.83 0 0 1-62.56 17.47c-25.7 0-47.17-8.93-62.88-18.6a142.57 142.57 0 0 1-19.84-14.68 99 99 0 0 1-8.93-8.87c-.24-.26-.52-.59-.83-1A111.23 111.23 0 0 1 61 186.53a130.17 130.17 0 0 1 27.42-11.3q19.91 17.6 39.78 35.18 20.11-17.59 40.18-35.18c.28.09.57.15.85.24a1.84 1.84 0 0 0 .28.09 132 132 0 0 1 26.25 10.93 112.71 112.71 0 0 1 25.12 18.86Z"
                        style={{
                            fill: '#000'
                        }}
                    />
                    <path
                        id="Neckband"
                        d="M168.4 175.23q-20.07 17.6-40.18 35.18-19.87-17.59-39.78-35.18a27 27 0 0 1 6.23-1.63q16.76 14.4 33.53 28.77 17-14.42 34-28.77a28.66 28.66 0 0 1 6.2 1.63Z"
                        style={{
                            fill: '#000'
                        }}
                    />
                    <path
                        id="Neck"
                        d="M162.17 173.6q-17 14.37-34 28.77-16.72-14.37-33.5-28.77c1.44-.35 2.94-.67 4.46-1s2.78-.54 4.13-.76a37.39 37.39 0 0 0 1.65-5.13c.22-1 .39-1.87.54-2.74a40.76 40.76 0 0 0 4.3 3.07 46.13 46.13 0 0 0 4.44 2.43 36.36 36.36 0 0 0 13.4 3.22 36.86 36.86 0 0 0 14.91-3.5 53 53 0 0 0 4.89-2.78c1.52-1 2.78-2 3.74-2.74.28 1.28.63 2.63 1.06 4s.89 2.85 1.39 4.13c1.18.2 2.37.41 3.61.67 1.72.37 3.37.74 4.98 1.13Z"
                        fill='white'
                    />
                    <path
                        id="NeckShadow"
                        d="M169.53 175.56c-.82.93-1.67 1.84-2.54 2.71a55 55 0 0 1-6.22 5.41 42.25 42.25 0 0 1-9.4 5.33 34.85 34.85 0 0 1-6.54 1.93 29.43 29.43 0 0 1-6 .52 31.6 31.6 0 0 1-11.5-2.39l-.65-.26a42.05 42.05 0 0 1-8.21-4.69 59.67 59.67 0 0 1-12.56-12.71 65.77 65.77 0 0 1-1.65-2.35c.11-.35.21-.7.3-1.07a34.18 34.18 0 0 0 .89-4c.59.48 1.18.94 1.78 1.37a42.73 42.73 0 0 0 5.07 3.18c.17.08.34.19.52.28s.41.21.63.32l.74.35.43.2c.13.06.26.13.37.17l.46.2.43.17a1 1 0 0 0 .22.09c.17.08.35.15.52.21s.44.16.67.24c.26.09.47.18.71.24l.16.07c.17 0 .34.11.52.15a6.12 6.12 0 0 0 .76.24 2 2 0 0 1 .35.09l.69.19a29.44 29.44 0 0 0 4.5.78 2.36 2.36 0 0 0 .48.05 1.51 1.51 0 0 0 .3 0h.63c.22 0 .35 0 .54.05h1.18a31 31 0 0 0 9.62-1.59h.07l.37-.13c.65-.2 1.28-.46 1.93-.7l.18-.08.82-.35c.07 0 .13-.07.22-.09a44 44 0 0 0 8.17-4.82l1.61-1.24c.3 1.35.67 2.8 1.13 4.32.41 1.37.87 2.65 1.32 3.85l1.09.2 1.09.19 1.06.2c.26.06.52.1.8.17a5 5 0 0 1 .53.11c1.34.28 2.69.56 4 .89 2.1.5 4.17 1 6.23 1.63Z"
                        className="cls-7"
                    />
                    <path
                        id="RightEar"
                        d="M185.93 119.84c-.15 5.14-3.44 10.35-7.11 12.27a7.65 7.65 0 0 1-1.56.59h-.12a4.93 4.93 0 0 1-2 .06 5 5 0 0 1-2-.87 2.92 2.92 0 0 1-.28-.21 6.78 6.78 0 0 1-1.11-1.1c-2.65-3.19-3.73-9.18-2-14a14.46 14.46 0 0 1 3.2-5 8.77 8.77 0 0 1 4.38-2.57 5.76 5.76 0 0 1 2.34 0c4.38.93 6.39 6.54 6.26 10.83Z"
                        className="cls-6"
                    />
                    <path
                        id="RightEarShadow"
                        d="M185.94 119.84c-.18 5.15-3.46 10.34-7.13 12.28a8.27 8.27 0 0 1-1.54.59h-.13a5.29 5.29 0 0 1-2 .06 5.2 5.2 0 0 1-2-.87l-.28-.21a8.13 8.13 0 0 1-1.11-1.09c-2.65-3.19-3.73-9.19-1.95-14a14.48 14.48 0 0 1 3.19-5 10.12 10.12 0 0 1 3.11-2.17 5.9 5.9 0 0 1 1.28-.39 5.63 5.63 0 0 1 2.35 0c.11 0 .24 0 .35.09 4.08 1.11 5.99 6.54 5.86 10.71Z"
                        style={{
                            opacity: 0.3
                        }}
                    />
                    <path
                        id="LeftHear"
                        d="M85.08 130.62a7.36 7.36 0 0 1-1.39 1.3 4.9 4.9 0 0 1-4 .81h-.12a7.15 7.15 0 0 1-1.56-.6c-3.67-1.91-6.95-7.12-7.11-12.26-.13-4.29 1.88-9.9 6.25-10.77 2.48-.5 4.86.7 6.73 2.58a14.51 14.51 0 0 1 3.2 5c1.73 4.75.64 10.74-2 13.94Z"
                        className="cls-6"
                    />
                    <path
                        id="Head"
                        d="M177.33 109.09c0 .39 0 .8-.06 1.21a78.32 78.32 0 0 1-4.46 21.41c-4.6 13-12.36 24.46-21.68 32l-1.61 1.24a44 44 0 0 1-8.17 4.82c-.09 0-.15.07-.22.09l-.82.35-.18.08c-.65.24-1.28.5-1.93.7l-.37.13h-.07a31 31 0 0 1-9.62 1.59H127c-.19 0-.37 0-.54-.05s-.41 0-.63 0a1.51 1.51 0 0 1-.3 0 2.36 2.36 0 0 1-.48-.05 29.44 29.44 0 0 1-4.5-.78l-.69-.19a2 2 0 0 0-.35-.09 6.12 6.12 0 0 1-.76-.24c-.18 0-.35-.11-.52-.15L118 171c-.24-.06-.45-.15-.71-.24-.23-.08-.45-.15-.67-.24s-.35-.13-.52-.21a1 1 0 0 1-.22-.09l-.43-.17-.46-.2c-.11 0-.24-.11-.37-.17l-.43-.2-.74-.35c-.22-.11-.44-.21-.63-.32s-.35-.2-.52-.28a42.73 42.73 0 0 1-5.07-3.18c-.6-.43-1.19-.89-1.78-1.37a46.22 46.22 0 0 1-4.39-3.93 53.28 53.28 0 0 1-4.1-4.48 77.71 77.71 0 0 1-13.52-24.81 80 80 0 0 1-3.78-18.08c-.09-1-.15-2-.2-3.06a66.81 66.81 0 0 1 .33-10.78c.15-1.46.37-2.89.63-4.33a54 54 0 0 1 1.22-5.28 72.94 72.94 0 0 1 4.6-12.47c4.2-8.8 10.26-17.08 17.82-23A39.21 39.21 0 0 1 128.27 45c14.75-.07 27 8.6 35.5 20.27a70.71 70.71 0 0 1 8.54 15.34c.57 1.37 1.09 2.74 1.57 4.13a69.76 69.76 0 0 1 2.54 9.75c.24 1.39.46 2.81.61 4.22a69.19 69.19 0 0 1 .3 10.38Z"
                        fill='white'
                    />
                    <path
                        id="HairsShadow"
                        d="M161.93 112.26c-4.91-.13-12.69-4.28-17-7.22-12.69-8.6-22.53-25-29.64-25.31a5.76 5.76 0 0 0-2.23.33c-3.22 1.19-4.31 5.38-5.46 9.45-1.41 5-2.35 8.26-3 10.34-.07-5.71-.18-11.93 3.28-20.77 1-2.63 2.32-5.33 4.93-6.28 1.26-.48 5.39-1.48 15.88 8.21a62.41 62.41 0 0 1 7.57 8.17c8.54 11.15 8.62 12 13 15.86 6.17 5.52 9.95 5.24 12.67 7.22Z"
                        className="cls-7"
                    />
                    <path
                        id="Hairs"
                        d="M193.78 170.41c-.46 1.21-9.13.89-16.67-3.85-8.77-5.52-15.34-16.62-15.71-28.21-.36-12.19 6.35-17 2.57-23.7a7.77 7.77 0 0 0-2-2.39c-2.72-2-6.5-1.7-12.69-7.22-4.33-3.84-4.41-4.71-13-15.86a62.41 62.41 0 0 0-7.56-8.18c-10.49-9.69-14.62-8.69-15.88-8.21-2.61 1-3.89 3.65-4.93 6.28-3.46 8.84-3.35 15.06-3.28 20.77 0 4.87.06 9.37-2.18 14.8-4.76 11.51-13.25 11.84-13.73 22-.28 5.74 2.35 7 2.2 14.48 0 2.52-.24 11.86-6.11 18.16-9.8 10.47-29.51 6.19-29.77 4s18.21-2 21.79-11.54c2.92-7.78-8-13.65-6.41-26.93.79-6.77 3.87-7.32 6.41-15.68 3.74-12.39-1.26-17-.41-28.49 1.48-20 18.86-37 34.38-43.61a49.51 49.51 0 0 1 15.06-3.85c20.68-1.76 36.24 10.89 42 16.34 4.18 4 14.63 13.95 16.35 28.51a62.86 62.86 0 0 1-1 19.56c-2.76 15-7.63 16.71-8.65 28.83-.41 4.83-1.19 14.21 4.17 22.1 5.79 8.58 15.53 10.56 15.05 11.89Z"
                        className="cls-2"
                    />
                </g>
            </g>
        </svg>
    )
}

export default IconAvatarWoman
