/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import { NavLink } from 'react-router-dom'
import IconEye from '../icons/IconEye'
import { Trans } from '@lingui/macro'
import { IconEmail } from '../icons/IconEmail'
import { IconDate } from '../icons/IconDate'
import Avatar from '../global/Avatar'
import { formatDate } from '../../helpers'

interface memberList {
    id: number
    numberRegister: string
    name: string
    imageUrl: string
    email: string
    birthDate: string
    sex: string
    deletedClien: string | null
}

interface MemberListProps {
    itemMember: memberList[]
    detailOpen: boolean
    onScrollToTop: () => void
}

const ListMember: React.FC<MemberListProps> = (props: MemberListProps) => {
    const { itemMember, detailOpen, onScrollToTop } = props
    const classAvatar = 'h-8 w-8'

    // useEffect(() => {
    //     onScrollToTop()
    // }, [itemMember])

    return (
        <div >
            <ul className="divide-y divide-main-brown divide-opacity-15">
                {itemMember.map((item, index) => (

                    <li key={index} className={`${item.deletedClien != null ? 'opacity-50' : ''}`}>
                        <NavLink
                            onClick={onScrollToTop}
                            className={({ isActive }) =>
                                `${isActive ? 'bg-light-gray block ' : ''} flex  items-center list-items gap-x-6 py-4 pl-20 lg-max:px-[25px]`
                            }
                            to={`${item.id}`}
                        >
                            <div
                                className={
                                    `flex min-w-0 gap-x-4 items-center 
                                    ${detailOpen ? 'lg:w-1/2 w-full' : 'w-full'}`
                                }
                            >
                                <Avatar
                                    imageUrl={item.imageUrl}
                                    classAvatar={classAvatar}
                                    type={item.sex}
                                />
                                <div className="min-w-0 flex-auto">
                                    <div className='flex md:flex-row flex-col'>
                                        <p className="font-basic-black leading-[18px] font-montserrat-bold">
                                            {item.numberRegister}
                                        </p>
                                        <span className='font-basic-black font-montserrat-bold mx-1 md:block hidden' >|</span>
                                        <p className="font-basic-black leading-[18px] font-montserrat-bold">
                                            {item.name}
                                        </p>
                                    </div>

                                    <div className='flex md:flex-row flex-col'>
                                        <div className='flex items-center w-3/4 lg-max:w-3/5'>
                                            <div>
                                                <IconEmail/>
                                            </div>
                                            <p className="font-basic-black leading-[18px] ml-[5px] break-all ">
                                                {item.email}
                                            </p>
                                        </div>
                                        <div className='flex items-center '>
                                            <div>
                                                <IconDate/>
                                            </div>
                                            <p className="font-basic-black leading-[18px] ml-[5px] ">
                                                {formatDate(item.birthDate)}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='md:block hidden'>
                                <button
                                    className={`btn btn-s btn-white shadow-btn-shadow font-basic font-think opacity-0 flex items-center 
                                ${detailOpen ? '' : 'hidden'} `}
                                >
                                    <IconEye />
                                    <Trans>Zobraziť detail</Trans>
                                </button>
                            </div>
                        </NavLink>
                    </li>

                ))}
            </ul>
        </div>
    )
}

export default ListMember
