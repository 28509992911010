import React from 'react'

export const IconBuild: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10.826" height="11.115" viewBox="0 0 10.826 11.115">
            <g id="Hotel" transform="translate(-2.012 -1.75)">
                <path id="Path_12" data-name="Path 12" d="M12.063,5.954H2.785v6.524a.386.386,0,0,0,.387.387H6.265a.386.386,0,0,0,.387-.387v-.966H8.2v.966a.386.386,0,0,0,.387.387h3.093a.387.387,0,0,0,.387-.387ZM5.681,10.2a.227.227,0,0,1-.226.227H4.4a.227.227,0,0,1-.226-.227V9.251A.227.227,0,0,1,4.4,9.025h1.06a.227.227,0,0,1,.226.227Zm0-2.149a.227.227,0,0,1-.226.227H4.4a.227.227,0,0,1-.226-.227V7.1A.227.227,0,0,1,4.4,6.876h1.06a.227.227,0,0,1,.226.227Zm2.5,2.149a.227.227,0,0,1-.227.227H6.9a.227.227,0,0,1-.227-.227V9.251A.227.227,0,0,1,6.9,9.025H7.954a.227.227,0,0,1,.227.227Zm0-2.149a.227.227,0,0,1-.227.227H6.9a.227.227,0,0,1-.227-.227V7.1A.227.227,0,0,1,6.9,6.876H7.954a.227.227,0,0,1,.227.227Zm2.5,2.149a.227.227,0,0,1-.227.227H9.394a.227.227,0,0,1-.226-.227V9.251a.227.227,0,0,1,.226-.227h1.06a.227.227,0,0,1,.227.227Zm0-2.149a.227.227,0,0,1-.227.227H9.394a.227.227,0,0,1-.226-.227V7.1a.227.227,0,0,1,.226-.227h1.06a.227.227,0,0,1,.227.227Zm2.158-4.423,0,1.168a.387.387,0,0,1-.387.386H2.4a.387.387,0,0,1-.387-.387l0-1.168S2,3.3,2.4,3.24H4.824a3.012,3.012,0,0,1,5.2,0h2.43a.389.389,0,0,1,.386.387Z" fill="#c6c0b2" />
            </g>
        </svg>
    )
}
