/* eslint-disable lingui/no-unlocalized-strings */
import { t } from '@lingui/macro'
import format from 'date-fns/format'
import { type CountryOption, type InputsAddNewClient, type LanguageItem, type Hotel } from './types'
import { useSelector } from 'react-redux'
import { type RootState } from './redux/store'

export const formatDate = (date: string): string => {
    const dateToFormat = new Date(date)
    const formattedDate = format(dateToFormat, 'dd.MM.yyyy')
    return formattedDate
}

export const formatDateTime = (date: string): string => {
    const dateToFormat = new Date(date)
    const formattedDate = format(dateToFormat, 'dd.MM.yyyy HH:mm')
    return formattedDate
}

export const formatPrice = (price: string, currency: string): string => {
    currency.toUpperCase()
    return parseFloat(price).toLocaleString('sk-SK', {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    })
}

export const getStatus = (status: string): any => {
    switch (status) {
        case 'unused':
            return {
                text: t`Aktívny`,
                colorText: 'text-active',
                bgDot: 'bg-active'
            }
        case 'used':
            return {
                text: t`Uplatnený`,
                colorText: 'text-claimed',
                bgDot: 'bg-claimed'
            }
        case 'expired':
            return {
                text: t`Expirovaný`,
                colorText: 'text-expired',
                bgDot: 'bg-expired'
            }
        default:
            return ''
    }
}

export const getStatusEmail = (status: string): any => {
    switch (status) {
        case 'success':
            return {
                text: t`Uspšený`,
                colorText: 'text-active',
                bgDot: 'bg-active'
            }
        case 'unsuccess':
            return {
                text: t`Neuspešný`,
                colorText: 'text-expired',
                bgDot: 'bg-expired'
            }
        default:
            return {
                text: t`Čaká sa na spracovanie`,
                colorText: 'text-claimed',
                bgDot: 'bg-claimed'
            }
    }
}

export const getLanguage = (): LanguageItem[] => {
    return [
        {
            short: 'sk',
            name: t`Slovenčina`,
            image: 'sk-flag.svg',
            class: 'rounded-t-20 hover:rounded-t-20 h-transition'
        },
        {
            short: 'cs',
            name: t`Čeština`,
            image: 'cz-flag.svg',
            class: ''
        },
        {
            short: 'en',
            name: t`Angličtina`,
            image: 'us-flag.svg',
            class: 'rounded-b-20 hover:rounded-b-20 h-transition'
        }
    ]
}

export const getActions = (status: string): any => {
    switch (status) {
        case 'POST':
            return {
                text: t`Pridanie nového`,
                iconBackground: 'bg-green-500'
            }
        case 'PUT':
            return {
                text: t`Úprava`,
                iconBackground: 'bg-gray'
            }
        case 'DELETE':
            return {
                text: t`Odstránenie`,
                iconBackground: 'bg-red'
            }
        case 'ERROR_SEND_VOUCHER':
            return {
                text: t`Chyba pri odosielaní voucheru pre`,
                iconBackground: 'bg-red'
            }
        default:
            return ''
    }
}

export const getActionsVoucher = (status: string): any => {
    switch (status) {
        case 'POST':
            return {
                text: t`Generovanie nového voucheru pre`,
                iconBackground: 'bg-green-500'
            }
        case 'PUT':
            return {
                text: t`Voucher aktivovaný`,
                iconBackground: 'bg-gray'
            }
        case 'BIRTHDAY_VOUCHER':
            return {
                text: t`Voucher k narodeninám`,
                iconBackground: 'bg-gray'
            }
        case 'DELETE':
            return {
                text: t`Odstránenie`,
                iconBackground: 'bg-red'
            }
        case 'SUCCESS_SEND_VOUCHER':
            return {
                text: t`Voucher úspešne odoslaný pre`,
                iconBackground: 'bg-green-500'
            }
        case 'ERROR_SEND_VOUCHER':
            return {
                text: t`Chyba pri odosielaní voucheru pre`,
                iconBackground: 'bg-red'
            }
        default:
            return ''
    }
}

export const useIsAdmin = (): boolean => {
    const loginUser = useSelector((state: RootState) => state.login.user)
    return loginUser?.role === 'admin'
}

export const prepateFormData = (data: InputsAddNewClient): FormData => {
    const form = new FormData()

    form.append('name', data.name)
    form.append('birth_date', data.birth_date)
    form.append('email', data.email)
    form.append('sex', data.sex)
    form.append('address[street]', data.address.street)
    form.append('address[city]', data.address.city)
    form.append('address[postcode]', data.address.postcode)
    form.append('address[state]', data.address.state)
    form.append('hotel_id', data.hotel_id)
    form.append('preferred_languange', data.preferred_languange)
    form.append('comment', data.comment ?? '')
    form.append('card_number', data.card_number ?? '')
    form.append('phone_number', data.phone_number ?? '')

    return form
}

export const formatDateInput = (dateStr: string): string => {
    if (dateStr === '') {
        return ''
    }
    const parsedDate = new Date(dateStr)
    parsedDate.setDate(parsedDate.getDate() + 1)
    const editDate = parsedDate.toISOString().split('T')[0]
    return editDate
}

export const setHttp = (url: string | null): string => {
    if (url == null) {
        return ''
    }

    if (url.includes('http://') || url.includes('https://')) {
        return url
    }
    return `http://${url}`
}

export const prioritizeCountries = (
    vals: CountryOption[],
    priorityLabels: string[] = ['Slovensko', 'Česko']
): CountryOption[] => {
    const priorityItems = priorityLabels
        .map(label => vals.find(val => val.label === label))
        .filter((item): item is CountryOption => item !== undefined)

    const filteredVals = vals.filter(val => !priorityLabels.includes(val.label))

    const orderedVals = [
        ...priorityItems,
        ...filteredVals.sort((a, b) => a.label.localeCompare(b.label))
    ]

    return orderedVals
}

export const firstSortItems = (items: Hotel[], name: string): Hotel[] => {
    const findItem = items.find((item) => item.name === name)
    const orderedItems = items.filter((item) => item.name !== name)

    if (findItem != null) {
        orderedItems.unshift(findItem)
    }

    return orderedItems
}
