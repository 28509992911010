import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type EmailLogModel } from '../../types'

import { type PaginatedResponse } from './hotelThunks'

interface paginationEmailLog {
    page: number
    perPage: number
    status: string
    keyword: string
}

export const fetchPaginationEmailLogs = createAsyncThunk(
    'emaillogs/fetchPaginationEmailLogs',
    async ({ page, perPage, status, keyword }: paginationEmailLog) => {
        const response = await axios.get<PaginatedResponse<EmailLogModel>>(`/api/emaillogs?status=${status}&page=${page}&per_page=${perPage}&keyword=${keyword}`)
        return {
            data: response.data.data,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page
        }
    }
)
