import React from 'react'

const IconPlus: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 mr-2 icon-plus">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
        </svg>
    )
}

export default IconPlus
