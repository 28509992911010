import React from 'react'

export interface DetailItem {
    icon: React.ReactElement
    title: string
    text: string | React.ReactElement
    deleteItem?: string | null
}
interface DetailInfoItemProps {
    items: DetailItem[]
    address: {
        title: string
        icon: React.ReactElement | undefined
        street: string
        city: string
        postCode: string
        state: string
    }
}

const DetailInfoItems: React.FC<DetailInfoItemProps> = (props: DetailInfoItemProps) => {
    const { items, address } = props

    return (
        <div className='mt-7 flex flex-wrap gap-x-16 gap-y-6'>
            {
                items.map((item, index) => (
                    (item.title !== '')
                        ? (
                            <div key={index} className={`w-2/5 flex ${item.deleteItem !== null ? 'opacity-50' : ''}`} >
                                <div className='relative top-[2px]'>{item.icon}</div>
                                <div className='ml-2'>
                                    <p className='font-basic font-montserrat-bold '>{item.title}</p>
                                    <p className='font-basic-black'>{item.text}</p>
                                </div>
                            </div>
                        )
                        : null
                ))
            }
            <div className='w-2/5 flex'>
                <div className='relative top-[2px]'>{address.icon}</div>
                <div className='ml-2'>
                    <p className='font-basic font-montserrat-bold '>{address.title}</p>
                    <p className='font-basic-black'>{address.street} {address.postCode}</p>
                    <p className='font-basic-black'>{address.state}</p>
                </div>
            </div>
        </div>
    )
}

export default DetailInfoItems
