import React from 'react'

const IconAvaterHotel: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width={'100%'}
            height={'100%'}
            viewBox="0 0 504 504"
        >
            <circle
                cx={252}
                cy={252}
                r={252}
                style={{
                    fill: '#c6c0b2'
                }}
            />
            <path
                d="M51.4 404.5C97.5 465 170.2 504 252 504s154.5-39 200.6-99.5H51.4z"
                style={{
                    fill: '#c6c0b2'
                }}
            />
            <path
                d="M364.6 152.8v252.3H139.4V152.8L252 93.7z"
                style={{
                    fill: '#fff'
                }}
            />
            <path
                d="M139.4 384.1h225.1v21H139.4z"
                style={{
                    fill: '#acb3ba'
                }}
            />
            <path
                d="M411.3 177.4c-1.7 3.3-5.2 5.3-8.7 5.3-1.5 0-3.1-.4-4.6-1.1L364.6 164 252 104.8l-112.6 59.1-33.4 17.6c-4.8 2.5-10.7.7-13.3-4.1-2.6-4.8-.7-10.7 4.1-13.3L247.5 85c2.9-1.5 6.3-1.5 9.1 0l150.6 79.1c4.8 2.5 6.7 8.5 4.1 13.3zM275.1 191.6h24.3V237h-24.3zM307.3 191.6h24.3V237h-24.3zM275.1 270.8h24.3v45.4h-24.3zM307.3 270.8h24.3v45.4h-24.3zM172.4 270.8h24.3v45.4h-24.3zM204.6 270.8h24.3v45.4h-24.3zM172.4 191.6h24.3V237h-24.3zM204.6 191.6h24.3V237h-24.3zM222 337.9h60v67.2h-60z"
                style={{
                    fill: '#000'
                }}
            />
            <path
                d="M226.9 342.3h50.2v62.8h-50.2z"
                style={{
                    fill: '#000'
                }}
            />
            <circle
                cx={235.7}
                cy={373.7}
                r={2.7}
                style={{
                    fill: '#000'
                }}
            />
        </svg>
    )
}

export default IconAvaterHotel
