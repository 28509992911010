import React from 'react'
import { type Hotel } from '../../types'
import { Trans } from '@lingui/macro'

interface SelectBoxProps {
    hotels: Hotel[]
    onChangeHotel: (e: any) => void
}

const SelectBox: React.FC<SelectBoxProps> = (props: SelectBoxProps) => {
    const { hotels, onChangeHotel } = props
    return (
        <div className='mx-auto w-3/4'>
            <select className='select-box input-form py-[5px] input-focus input-transition font-basic font-montserrat-bold md:font-montserrat-regular ' onChange={onChangeHotel}>
                <option value=''><Trans>Vyberte hotel</Trans></option>
                {hotels.map((hotel, index) => (
                    <option
                        disabled={hotel.deleted_at !== null}
                        label={hotel.name}
                        key={index}
                        value={hotel.id}>{hotel.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectBox
