/* eslint-disable lingui/no-unlocalized-strings */
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type InputsAddNewClient, type Client } from '../../types'
import { type RootState } from '../store'
import * as clientThunks from '../thunks/clientThunks'

interface UserState {
    data: Client[]
    loading: boolean
    error: string | null
    currentPage: number
    total: number
    lastPage: number
    updateSuccess: boolean
    formData: InputsAddNewClient | null
    clientData: {
        name: string
        id: string
    }
    errorCode: string
}

interface RejectedActionPayload {
    error_code: string
    client_name?: string
    client_id?: string
}

const initialState: UserState = {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    total: 0,
    lastPage: 0,
    updateSuccess: true,
    formData: null,
    clientData: {
        name: '',
        id: ''
    },
    errorCode: ''
}

const clientSlice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        resetUpdateSuccess(state) {
            state.updateSuccess = true
        },
        setFormData(state, action: PayloadAction<InputsAddNewClient>) {
            state.formData = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(clientThunks.fetchAllClients.pending, (state) => {
                state.loading = true
            })
            .addCase(
                clientThunks.fetchAllClients.fulfilled,
                (state, action: PayloadAction<Client[]>) => {
                    state.loading = false
                    state.data = action.payload
                }
            )
            .addCase(clientThunks.fetchAllClients.rejected, (state, action) => {
                state.loading = false
                state.error = null
            })
            .addCase(clientThunks.fetchPaginationClients.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(
                clientThunks.fetchPaginationClients.fulfilled,
                (state, action) => {
                    state.loading = false
                    state.data = action.payload.data
                    state.total = action.payload.total
                    state.currentPage = action.payload.currentPage
                    state.lastPage = action.payload.lastPage
                }
            )
            .addCase(
                clientThunks.fetchPaginationClients.rejected,
                (state, action) => {
                    state.loading = false
                    state.error =
                        action.error.message ?? 'Failed to fetch clients'
                }
            )
            .addCase(clientThunks.fetchUpdateClient.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(
                clientThunks.fetchUpdateClient.fulfilled,
                (state, action) => {
                    state.loading = false
                    state.updateSuccess = true
                    state.data = state.data.map((client) => {
                        if (client.id === action.payload.id) {
                            return action.payload.data
                        }
                        return client
                    })
                }
            )
            .addCase(
                clientThunks.fetchUpdateClient.rejected,
                (state, action) => {
                    state.loading = false
                    state.updateSuccess = false

                    const payload = action.payload as RejectedActionPayload
                    state.error =
                        payload.error_code === 'email_exists'
                            ? 'email_exists'
                            : 'Failed to update client'
                    // state.clientData = payload.client_name ?? ''
                    state.clientData = {
                        name: payload.client_name ?? '',
                        id: payload.client_id ?? ''
                    }
                }
            )
            .addCase(clientThunks.deleteClient.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((client) => {
                    if (client.id === action.payload) {
                        return {
                            ...client,
                            deleted_at: new Date().toISOString()
                        }
                    }

                    return client
                })
            })
            .addCase(clientThunks.restoreClient.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((client) => {
                    if (client.id === action.payload) {
                        return { ...client, deleted_at: null }
                    }

                    return client
                })
            })
            .addCase(clientThunks.fetchCreateClient.pending, (state) => {
                state.loading = true
                state.error = null
            }
            )
            .addCase(clientThunks.fetchCreateClient.fulfilled, (state, action) => {
                state.loading = false
                state.updateSuccess = true
            })
            .addCase(clientThunks.fetchCreateClient.rejected, (state, action) => {
                state.loading = false
                state.updateSuccess = false
                const payload = action.payload as RejectedActionPayload
                state.error =
                    payload.error_code === 'email_exists'
                        ? 'email_exists'
                        : 'Failed to create client'
                state.clientData = {
                    name: payload.client_name ?? '',
                    id: payload.client_id ?? ''
                }
            })
    }
})

export const selectClients = (state: RootState): Client[] => state.client.data
export const selectClientLoading = (state: RootState): boolean => state.client.loading
export const selectClientError = (state: RootState): string | null => state.client.error
export const { setFormData, resetUpdateSuccess } = clientSlice.actions
export default clientSlice.reducer
