import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type SystemLog } from '../slices/systemLogSlice'

export const fetchUserSystemLog = createAsyncThunk(
    'user/fetchUserSystemLog',
    async (userId: number) => {
        const response = await axios.get<SystemLog[]>(`/api/user/${userId}/systemlog`)
        return response.data
    }
)

export const fetchHotelSystemLog = createAsyncThunk(
    'hotel/fetchHotelsystemLog',
    async (hotelId: number) => {
        const response = await axios.get<SystemLog[]>(`/api/hotel/${hotelId}/systemlog`)
        return response.data
    }

)

export const fetchClientSystemLog = createAsyncThunk(
    'client/fetchClientSystemLog',
    async (clientId: number) => {
        const response = await axios.get<SystemLog[]>(`/api/client/${clientId}/systemlog`)
        return response.data
    }

)

export const fetchVocherSystemLog = createAsyncThunk(
    'voucher/fetchVocherSystemLog',
    async (voucherId: number) => {
        const response = await axios.get<SystemLog[]>(`/api/voucher/${voucherId}/voucherlog`)
        return response.data
    }
)
