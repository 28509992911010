/* eslint-disable lingui/no-unlocalized-strings */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-var-requires */
import { Trans, t } from '@lingui/macro'
import React, { useMemo, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { fetchCreateHotel, fetchUpdateHotel } from '../../redux/thunks/hotelThunks'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState, type AppDispatch } from '../../redux/store'
import ModalWindow from '../global/ModalWindow'
import { type ModalWindowData, type CreateFormInputHotel } from '../../types'
import Loader from '../global/Loader'
import FadeAnimation from '../global/FadeAnimation'
import IconSuccess from '../icons/IconSuccess'
import IconWarning from '../icons/IconWarning'
import Select from 'react-select'
import { useLingui } from '@lingui/react'
import countries from 'i18n-iso-countries'
import { useNavigate } from 'react-router-dom'
import { prioritizeCountries } from '../../helpers'

interface AddNewDeviceProps {
    updateForm?: boolean
    itemId?: number
}

const FormAddNewDevice: React.FC<AddNewDeviceProps> = (props: AddNewDeviceProps) => {
    const { updateForm, itemId } = props
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const dispatch = useDispatch<AppDispatch>()
    const [open, setOpen] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const [loading, setLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [errorFile, setErrorFile] = useState('')
    const navigate = useNavigate()
    const hotelId: number = itemId ?? 0
    const lingui = useLingui()

    const countriesOptions = useMemo(() => {
        if (lingui.i18n.locale !== '') {
            countries.registerLocale(require('i18n-iso-countries/langs/' + lingui.i18n.locale + '.json'))
        }

        const cntrs = countries.getNames(lingui.i18n.locale)
        const vals = Object.entries(cntrs).map((val, i) => {
            return {
                value: val[0],
                label: val[1]
            }
        })

        const orderedVals = prioritizeCountries(vals, ['Slovensko', 'Česko'])

        return orderedVals
    }, [lingui.i18n.locale])

    const oneHotelDetail = useMemo(() => {
        if (itemId !== undefined) {
            const foundHotel = hotels.find((hotel) => hotel.id === itemId)
            if (foundHotel !== undefined) {
                return foundHotel
            }
        }
        return {
            id: '',
            name: '',
            email: '',
            phone_number: '',
            web: '',
            hotel_id: '',
            role: '',
            comment: '',
            address: {
                street: '',
                city: '',
                postcode: '',
                state: ''
            }
        }
    }, [itemId, hotels])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<CreateFormInputHotel>({
        defaultValues: {
            name: oneHotelDetail.name,
            email: oneHotelDetail.email,
            phoneNumber: oneHotelDetail.phone_number,
            web: oneHotelDetail.web,
            address: {
                street: oneHotelDetail.address.street,
                city: oneHotelDetail.address.city,
                postcode: oneHotelDetail.address.postcode,
                state: oneHotelDetail.address.state
            }
        }
    })

    const closeWindow = (): void => {
        navigate('/facilities')
    }

    const onSubmit: SubmitHandler<CreateFormInputHotel> = (data) => {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('phone_number', data.phoneNumber)
        formData.append('email', data.email)
        formData.append('address[street]', data.address.street)
        formData.append('address[city]', data.address.city)
        formData.append('address[postcode]', data.address.postcode.toString())
        formData.append('address[state]', data.address.state)
        formData.append('web', data.web ?? '')
        formData.append('image', data.image[0])

        setLoading(true)

        if (updateForm === true) {
            void dispatch(fetchUpdateHotel({ formData, hotelId }))
        } else {
            void dispatch(fetchCreateHotel(formData))
                .then((response: any) => {
                    if (response.payload?.errors?.email != null) {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Chyba`,
                            subtitle: t`Zariadenie s touto e-mailovou adresou už existuje.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning/>,
                            action: () => { setOpen(false) }
                        })
                    } else if (response.payload.message === 'successfully') {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Prídanie nového zariadenia`,
                            subtitle: t`Úspešne ste pridali nové zariadenie.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconSuccess/>,
                            action: () => { navigate('/facilities') }
                        })
                    } else {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Chyba`,
                            subtitle: t`Nepodarilo sa pridať nové zariadenie.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning/>,
                            action: () => { navigate('/facilities') }
                        })
                    }
                })
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files?.[0]
        setFileLoading(true)
        if (file != null) {
            setFileLoading(false)
        } else {
            setErrorFile(t`Nahravanie súbra zlyhalo`)
        }
    }

    const selectStyles = {
        control: (base: any, state: any) => ({
            ...base,
            fontSize: '11px',
            height: '40px',
            borderRadius: '10px',
            border: state.isFocused === true ? '1px solid #6B6145 !important' : '0.4px solid #6B6145 !important',
            boxShadow: state.isFocused === true ? '0 0 0 1px #6B6145 !important' : 'none',
            transition: 'all 0.5s ease-in-out'
        }),
        multiValue: (base: any) => ({
            ...base,
            backgroundColor: 'blue',
            color: 'white',
            fontSize: '11px important'
        }),
        option: (base: any, { isFocused, isSelected }: any) => ({
            ...base,
            fontSize: '11px',
            padding: '0px 10px',
            color: isFocused === true || isSelected === true ? 'white' : '#6B6145'
        })
    }

    return (
        <><ModalWindow
            disabledCrossIcon={true}
            open={open}
            setOpen={setOpen}
            dataModalWindow={dataModal}
            closeWindow={closeWindow}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row">
                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="name" className="sm-font-b md:inline hidden">
                        <Trans>Názov*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('name', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="text"
                            name="name"
                            placeholder={t`Názov*`}
                            id="name"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.name != null) && (
                            <FadeAnimation type="up" key={errors.name.message}>
                                <div className="error-text">
                                    {errors.name.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>

                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="phoneNumber" className="sm-font-b md:inline hidden">
                        <Trans>Telefón*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('phoneNumber', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="tel"
                            name="phoneNumber"
                            placeholder={t`Telefón*`}
                            id="phone"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.phoneNumber != null) && (
                            <FadeAnimation type="up" key={errors.phoneNumber.message}>
                                <div className="error-text">
                                    {errors.phoneNumber.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="email" className="sm-font-b md:inline hidden">
                        <Trans>Email*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('email', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                },
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                    message: t`Zadajte platnú e-mailovú adresu`
                                }
                            })}
                            type="email"
                            name="email"
                            placeholder={t`Email*`}
                            id="email"
                            autoComplete="on"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.email != null) && (
                            <FadeAnimation type="up" key={errors.email.message}>
                                <div className="error-text">
                                    {errors.email.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row" >
                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="address.street" className="sm-font-b md:inline hidden">
                        <Trans>Ulica*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('address.street', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="text"
                            name="address.street"
                            placeholder={t`Ulica*`}
                            id="street"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.address?.street != null) && (
                            <FadeAnimation type="up" key={errors.address.street.message}>
                                <div className="error-text">
                                    {errors.address.street.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>

                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="address.city" className="sm-font-b md:inline hidden">
                        <Trans>Mesto*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('address.city', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="text"
                            name="address.city"
                            placeholder={t`Mesto*`}
                            id="city"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.address?.city != null) && (
                            <FadeAnimation type="up" key={errors.address.city.message}>
                                <div className="error-text">
                                    {errors.address.city.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="address.postcode" className="sm-font-b md:inline hidden">
                        <Trans>PSČ*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('address.postcode', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`
                                }
                            })}
                            type="number"
                            name="address.postcode"
                            placeholder={t`PSČ*`}
                            id="address.postcode"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.address?.postcode != null) && (
                            <FadeAnimation type="up" key={errors.address.postcode.message}>
                                <div className="error-text">
                                    {errors.address.postcode.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row">
                <div className="md:w-1/3 w-full pb-3 select-state">
                    <label htmlFor="address.state" className="sm-font-b md:inline hidden">
                        <Trans>Štát*</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <Select
                            {...register('address.state', {
                                required: {
                                    value: true,
                                    message: t`Pole je povinné`

                                }
                            })}
                            theme={(theme) => ({
                                ...theme,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#4f46e5',
                                    primary: '#4f46e5'
                                }
                            })}
                            defaultValue= {updateForm === true ? { label: oneHotelDetail?.address.state ?? '' } : null}
                            placeholder={t`Vyberte štát*`}
                            options={countriesOptions}
                            isSearchable={true}
                            styles={selectStyles}
                            onChange={val => {
                                if (val != null) {
                                    setValue('address.state', val.label)
                                }
                            }}
                        />
                        {(errors.address?.state !== undefined) && (
                            <FadeAnimation type="up" key={errors.address.state.message}>
                                <div className="error-text">
                                    {errors.address.state.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>

                <div className="md:w-1/3 w-full pb-3">
                    <label htmlFor="web" className="sm-font-b md:inline hidden">
                        <Trans>Web</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('web', {
                                pattern: {
                                    value: /^www\.[^ "]+$/,
                                    message: t`Zadajte platnú URL adresu`
                                }
                            })}
                            type="text"
                            placeholder={t`Web`}
                            name="web"
                            id="web"
                            className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                        />
                        {(errors.web != null) && (
                            <FadeAnimation type="up" key={errors.web.message}>
                                <div className="error-text">
                                    {errors.web.message}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
                <div className="md:w-1/3 w-full pb-3"></div>
            </div>
            <div className='flex justify-center items-center flex-col'>
                <Loader loading={fileLoading}/>
                {fileLoading &&
                    <p className='font-basic-black'>
                        <Trans>Nahrávam súbor...</Trans>
                    </p>
                }
            </div>
            <div className="flex justify-center text-left lg:px-[46.5px]">
                <div className="w-full">
                    <label htmlFor="image" className="sm-font-b" >
                        <Trans> Fotka</Trans>
                    </label>
                    <div className="mt-[6px]">
                        <input
                            {...register('image')}
                            className="
                                input-form py-[5px] input-focus input-transition font-basic input-file"
                            type="file"
                            name='image'
                            id="formFile"
                            onChange={handleFileChange}
                        />
                        {(errorFile.length > 0) && (
                            <FadeAnimation type="up" key={errorFile}>
                                <div className="error-text">
                                    {errorFile}
                                </div>
                            </FadeAnimation>
                        )}
                    </div>
                </div>
            </div>
            <button
                type='submit'
                className='btn btn-red btn-m block mx-auto  mt-5 w-max-[189px]'
            >
                {(updateForm === true) ? t`Upraviť údaje` : t`Pridať nové zariadenie` }
            </button>
        </form>
        {loading &&
                <div className='flex justify-center py-4'>
                    <Loader/>
                </div>
        }
        </>
    )
}

export default FormAddNewDevice
