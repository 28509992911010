/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable lingui/no-unlocalized-strings */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type User } from '../../types'
import { type paginationData, type CreateResponse, type PaginatedResponse } from './hotelThunks'
import { t } from '@lingui/macro'

export const fetchPaginationUsers = createAsyncThunk(
    'users/fetchPaginationUsers',
    async ({ page, perPage, keyword }: paginationData) => {
        const response = await axios.get<PaginatedResponse<User>>(`/api/users?page=${page}&per_page=${perPage}&keyword=${keyword}`)
        return {
            data: response.data.data,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page

        }
    }
)

export const fetchAllUsers = createAsyncThunk(
    'users/fetchAll',
    async () => {
        const response = await axios.get<User[]>('/api/users/all')
        return response.data
    }
)

export const fetchUserById = createAsyncThunk(
    'users/fetchById',
    async (userId: number) => {
        const response = await axios.get<User>(`/api/users/${userId}`)
        return response.data
    }
)

export const fetchCreateUser = createAsyncThunk(
    'users/fetchCreateUser',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const response = await axios.post<CreateResponse>('/api/users', formData)
            return response.data
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const fetchUpdateUser = createAsyncThunk(
    'users/fetchUpdateUser',
    async (data: { formData: FormData, userId: number }, { rejectWithValue }) => {
        try {
            data.formData.append('_method', 'PUT')
            const response = await axios.post<User>(`/api/users/${data.userId}`, data.formData)
            return {
                id: data.userId,
                data: response.data
            }
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (userId: number) => {
        try {
            await axios.delete(`/api/users/${userId}`)
            return userId
        } catch (error: Error | any) {
            if (error.response.status === 500) {
                return t`Chyba servera`
            }
        }
    }
)

export const fetchChangePassword = createAsyncThunk(
    'user/fetchChangePassword',
    async (formData: FormData) => {
        try {
            formData.append('_method', 'PUT')
            const response = await axios.post<CreateResponse>('/api/user/changePassword', formData)
            return response.data
        } catch (error: Error | any) {
            return error.response.data
        }
    }
)

export const fetchChangeAdminPassword = createAsyncThunk(
    'user/fetchchangeAdminPassword',
    async (data: { formData: FormData, userId: number }) => {
        try {
            data.formData.append('_method', 'PUT')
            const response = await axios.post<CreateResponse>(`/api/user/changeAdminPassword/${data.userId}`, data.formData)
            return response.data
        } catch (error: Error | any) {
            return error.response.data
        }
    }
)

export const restoreUser = createAsyncThunk(
    'user/restore',
    async (userId: number) => {
        try {
            await axios.put(`/api/user/restoreUser/${userId}`)
            return userId
        } catch (error: Error | any) {
            if (error.response.status === 500) {
                return t`Chyba servera`
            }
        }
    }
)
