/* eslint-disable lingui/no-unlocalized-strings */
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type Hotel } from '../../types' // Import the User interface
import { type RootState } from '../store' // Import the RootState type
import * as HotelThunks from '../thunks/hotelThunks'

interface HotelState {
    data: Hotel[]
    loading: boolean
    error: string | null
    currentPage: number
    total: number
    lastPage: number
    updateSuccess: boolean
    existEmail: string
}

interface RejectedActionPayload {
    errors: {
        email: string
    }
}

const initialState: HotelState = {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    total: 0,
    lastPage: 0,
    updateSuccess: true,
    existEmail: ''
}

const hotelSlice = createSlice({
    name: 'hotel',
    initialState,
    reducers: {
        resetUpdateSuccess(state) {
            state.updateSuccess = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(HotelThunks.fetchAllHotels.pending, (state) => {
                state.loading = true
            })
            .addCase(HotelThunks.fetchAllHotels.fulfilled, (state, action: PayloadAction<Hotel[]>) => {
                state.loading = false
                state.data = action.payload
            })
            .addCase(HotelThunks.fetchAllHotels.rejected, (state, action) => {
                state.loading = false
                state.error = null
            })
            .addCase(HotelThunks.fetchPaginationHotles.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(HotelThunks.fetchPaginationHotles.fulfilled, (state, action) => {
                state.loading = false
                state.total = action.payload.total
                state.currentPage = action.payload.currentPage
                state.lastPage = action.payload.lastPage
                state.data = action.payload.data
            })
            .addCase(HotelThunks.fetchPaginationHotles.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch users'
            })
            .addCase(HotelThunks.fetchUpdateHotel.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(HotelThunks.fetchUpdateHotel.fulfilled, (state, action) => {
                state.loading = false
                state.updateSuccess = true
                state.data = state.data.map((hotel) => {
                    if (hotel.id === action.payload.id) {
                        return action.payload.data
                    }
                    return hotel
                })
            })
            .addCase(HotelThunks.fetchUpdateHotel.rejected, (state, action) => {
                state.loading = false
                state.updateSuccess = false
                const payload = action.payload as RejectedActionPayload
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                state.existEmail = payload.errors && payload.errors.email !== '' ? 'exist_email' : ''
                state.error = action.error.message ?? 'Failed to fetch hotels'
            })
            .addCase(HotelThunks.deleteHotel.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((hotel) => {
                    if (hotel.id === action.payload) {
                        return { ...hotel, deleted_at: new Date().toISOString() }
                    }

                    return hotel
                })
            })
            .addCase(HotelThunks.restoreHotel.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((hotel) => {
                    if (hotel.id === action.payload) {
                        return { ...hotel, deleted_at: null }
                    }

                    return hotel
                })
            })
        // Add similar cases for fetchUserById, updateUser, deleteUser
    }
})

export const selectAllHotels = (state: RootState): Hotel[] => state.hotel.data
export const selectUserLoading = (state: RootState): boolean => state.hotel.loading
export const selectUserError = (state: RootState): string | null => state.hotel.error
export const { resetUpdateSuccess } = hotelSlice.actions

export default hotelSlice.reducer
