/* eslint-disable array-callback-return */
import { Trans } from '@lingui/macro'
import React from 'react'
import { IconVoucher } from '../icons/IconVoucher'

interface DetailMemberVoucherProps {
    foundVoucherNumbers: Array<{ number: string, state: string }>
}

const DetailMemberVoucher: React.FC<DetailMemberVoucherProps> = (props: DetailMemberVoucherProps) => {
    const { foundVoucherNumbers } = props

    foundVoucherNumbers.sort((a, b) => {
        if (a.state === 'unused' && b.state !== 'unused') {
            return -1
        }
        if (a.state !== 'unused' && b.state === 'unused') {
            return 1
        }
        return 0
    })

    return (
        <>
            {foundVoucherNumbers.length > 0 &&
        <div className='mt-4 mb-2'>
            <h4 className='font-basic font-montserrat-bold'>
                <Trans>Priradené vouchery</Trans>
            </h4>
            <ul className='flex gap-x-2 flex-wrap'>
                {foundVoucherNumbers.map((voucher, index) => (
                    <li key={index} >
                        <div className='flex items-center'>
                            <div className='w-4 h-2 opacity-30 mr-1'>
                                <IconVoucher/>
                            </div>
                            {voucher.state === 'expired' || voucher.state === 'used'
                                ? <p className='font-basic-black opacity-40'>{voucher.number}</p>
                                : <p className='font-basic-black'>{voucher.number}</p>}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
            }
        </>
    )
}

export default DetailMemberVoucher
