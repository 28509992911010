/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable lingui/no-unlocalized-strings */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type Hotel } from '../../types'
import { t } from '@lingui/macro'

export interface CreateResponse {
    messsage: string
}

export interface PaginatedResponse<T> {
    data: T[]
    current_page: number
    last_page: number
    total: number
}

export interface paginationData {
    page: number
    perPage: number
    keyword: string
}

export const fetchPaginationHotles = createAsyncThunk(
    'hotels/fetchPaginationHotels',
    async ({ page, perPage, keyword }: paginationData) => {
        const response = await axios.get<PaginatedResponse<Hotel>>(`/api/hotels?page=${page}&per_page=${perPage}&keyword=${keyword}`)
        return {
            data: response.data.data,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page
        }
    }
)

export const fetchAllHotels = createAsyncThunk(
    'hotels/fetchAll',
    async () => {
        const response = await axios.get<Hotel[]>('/api/hotels/all')
        return response.data
    }
)

export const fetchCreateHotel = createAsyncThunk(
    'hotels/fetchCreateHotel',
    async (formData: FormData) => {
        try {
            const response = await axios.post<CreateResponse>('/api/hotels', formData)
            return response.data
        } catch (error: Error | any) {
            return error.response.data
        }
    }
)

export const fetchUpdateHotel = createAsyncThunk(
    'hotels/fetchUpdateHotel',
    async (data: { formData: FormData, hotelId: number }, { rejectWithValue }) => {
        try {
            data.formData.append('_method', 'PUT')
            const response = await axios.post<Hotel>(`/api/hotels/${data.hotelId}`, data.formData)
            return {
                id: data.hotelId,
                data: response.data
            }
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const deleteHotel = createAsyncThunk(
    'hotels/delete',
    async (hotelId: number) => {
        try {
            await axios.delete(`/api/hotels/${hotelId}`)
            return hotelId
        } catch (error: Error | any) {
            if (error.response.status === 500) {
                return t`Chyba servera`
            }
        }
    }
)

export const restoreHotel = createAsyncThunk(
    'hotels/restore',
    async (hotelId: number) => {
        try {
            await axios.put(`/api/hotel/restoreHotel/${hotelId}`)
            return hotelId
        } catch (error: Error | any) {
            if (error.response.status === 500) {
                return t`Chyba servera`
            }
        }
    }
)
