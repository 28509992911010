/* eslint-disable lingui/no-unlocalized-strings */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type VoucherModel } from '../../types'

import { type PaginatedResponse } from './hotelThunks'
import { t } from '@lingui/macro'

interface paginationVoucher {
    page: number
    perPage: number
    status: string
    keyword: string
}

export const fetchPaginationVouchers = createAsyncThunk(
    'vouchers/fetchPaginationVouchers',
    async ({ page, perPage, status, keyword }: paginationVoucher) => {
        const response = await axios.get<PaginatedResponse<VoucherModel>>(`/api/vouchers?status=${status}&page=${page}&per_page=${perPage}&keyword=${keyword}`)
        return {
            data: response.data.data,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page
        }
    }
)

export const fetchAllVouchers = createAsyncThunk(
    'vouchers/fetchAll',
    async () => {
        const response = await axios.get<VoucherModel[]>('/api/vouchers/all')
        return response.data
    }
)

export const fetchVerifyVoucher = createAsyncThunk(
    'vouchers/verify',
    async (voucherNumber: string) => {
        try {
            const response = await axios.get<VoucherModel>(`/api/vouchers/verify/${voucherNumber}`)
            return response.data
        } catch (error: any) {
            return error.response.data
        }
    }
)

export const applyVoucher = createAsyncThunk(
    'vouchers/apply',
    async (data: { number: string, hotel_id: string }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/api/vouchers/${data.number}/apply`, { hotel_id: data.hotel_id, _method: 'PUT' })
            return response.data
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && (error.response != null)) {
                if (error.response.status === 400) {
                    return rejectWithValue(t`Nastala neočakávaná chyba.`)
                } else if (error.response.status === 404) {
                    return rejectWithValue(t`Nastala neočakávaná chyba.`)
                } else if (error.response.status === 500) {
                    return rejectWithValue(t`Nastala neočakávaná chyba.`)
                } else {
                    return rejectWithValue(error.response.data)
                }
            }
            return rejectWithValue(error?.message ?? 'Failed to login')
        }
    }
)

export const generateVoucher = createAsyncThunk(
    'vouchers/generate',
    async (id: number, { rejectWithValue }) => {
        try {
            const response = await axios.post(`/api/vouchers/${id}/generate`)
            return response.data
        } catch (error: Error | any) {
            return rejectWithValue(error?.response)
        }
    }
)
