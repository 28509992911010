import React from 'react'

const IconEye: React.FC = () => {
    return (
        <div className='w-[15px] h-[7px]'>
            <svg xmlns="http://www.w3.org/2000/svg" width="11.004" height="6.838" viewBox="0 0 11.004 6.838" className='pr-1 w-full h-full'>
                <g id="eye" transform="translate(0 -115.853)">
                    <g id="Group_40" data-name="Group 40" transform="translate(0 115.853)">
                        <path id="Path_18" data-name="Path 18" d="M10.961,119.441a.355.355,0,0,0,0-.339,6.21,6.21,0,0,0-10.918,0,.355.355,0,0,0,0,.339,6.21,6.21,0,0,0,10.918,0ZM5.5,121.894a2.622,2.622,0,1,1,2.622-2.622A2.622,2.622,0,0,1,5.5,121.894Z" transform="translate(0 -115.853)" fill="#6c6146" />
                        <path id="Path_19" data-name="Path 19" d="M233.005,231.67a1.339,1.339,0,0,0-.32.039.693.693,0,0,1-.978.978,1.337,1.337,0,1,0,1.3-1.016Z" transform="translate(-227.504 -229.588)" fill="#6c6146" />
                    </g>
                </g>
            </svg>
        </div>

    )
}

export default IconEye
