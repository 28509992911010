import { t } from '@lingui/macro'
import React, { useEffect, useState } from 'react'
import ModalWindow from '../global/ModalWindow'
import { NavLink } from 'react-router-dom'
import IconCross from '../icons/IconCross'
import DetailHeader from './DetailHeader'
import DetailContent from './DetailContent'
import DetailInfoItemUser from './DetailInfoItemUser'
import { DetailFooter } from './DetailFooter'
import type { OneItemDetail } from '../../types'
import Loader from '../global/Loader'
import DetailMemberLogs from './DetailMemberLogs'
import PageLogs from '../../pages/PageLogs'
import { type SystemLog } from '../../redux/slices/systemLogSlice'
import { useIsAdmin } from '../../helpers'
import IconWarning from '../icons/IconWarning'
import IconSuccess from '../icons/IconSuccess'

interface DetailComponentProps {
    textButtonDetail: string
    textTileDetail: string
    textDeleteButton: string
    oneItemDetail: OneItemDetail | undefined
    navigatePath: string
    toggleDeleteRestore: () => Promise<boolean>
    showButtonPassword: boolean
    buttonIcon: string
    handleOpenModalForm: () => void
    systemLog: SystemLog[]
    deleteTitle: string
    deleteText: string
    hideTitle?: boolean
    hideDeleteButton?: boolean

}

const DetailComponent: React.FC<DetailComponentProps> = (props: DetailComponentProps) => {
    const {
        textButtonDetail,
        textDeleteButton,
        oneItemDetail,
        textTileDetail,
        navigatePath,
        toggleDeleteRestore,
        showButtonPassword,
        buttonIcon,
        handleOpenModalForm,
        deleteTitle,
        deleteText,
        systemLog
    } = props

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openLog, setOpenLog] = useState(false)
    const [dataModalWindow, setDataModalWindow] = useState({
        title: '',
        subtitle: '',
        textButton: '',
        icon: <></>,
        action: () => {}
    })
    const isAdmin = useIsAdmin()

    const isDeleted = oneItemDetail?.deleted_at

    useEffect(() => {
        setOpenLog(false)
    }, [oneItemDetail])

    const closeWindow = (): void => {
        setOpen(false)
    }

    const handleOpenFooter = (): void => { setOpen(true) }

    const handleOpenLog = (value: boolean): void => { setOpenLog(value) }

    const togglefunctionDelete = async (): Promise<void> => {
        setLoading(true)
        await toggleDeleteRestore()
        setLoading(false)
    }

    // set funtion on cofrimDelete
    const confirmDelete = (): void => {
        setDataModalWindow({
            title: t`Potvrdenie vykonania akcie`,
            subtitle: t`Kliknite na deaktivovať pre potvrdenie akcie.`,
            textButton: t`Deaktivovať`,
            icon: <IconWarning/>,
            action: () => {
                void togglefunctionDelete()
            }
        })
    }

    // set funtion on cofrimActivate
    const confirmActivate = (): void => {
        setDataModalWindow({
            title: t`Potvrdenie vykonania akcie`,
            subtitle: t`Kliknite na aktivovať pre potvrdenie akcie.`,
            textButton: t`Aktivovať`,
            icon: <IconSuccess/>,
            action: () => {
                void togglefunctionDelete()
            }
        })
    }

    useEffect(() => {
        if (oneItemDetail?.deleted_at === null) {
            setDataModalWindow({
                title: deleteTitle,
                subtitle: deleteText,
                textButton: t`Deaktivovať`,
                icon: <IconWarning/>,
                action: confirmDelete
            })
        } else {
            setDataModalWindow({
                title: deleteTitle,
                subtitle: deleteText,
                textButton: t`Aktivovať`,
                icon: <IconSuccess/>,
                action: confirmActivate
            })
        }
    }, [oneItemDetail, open])

    return (
        <div className='h-full flex-col flex'>
            <Loader loading={loading}/>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModalWindow}
                closeWindow={closeWindow}
            />
            <NavLink
                className='flex justify-end mr-3'
                title={t`Zatvoriť`}
                to={navigatePath}
            >
                <IconCross />
            </NavLink>
            <DetailHeader
                textButtonDetail={textButtonDetail}
                oneItemDetail={oneItemDetail }
                buttonIcon={buttonIcon}
                handleOpenModalForm={handleOpenModalForm}
                showButtonPassword={showButtonPassword}
                openLog={openLog}
            />
            {openLog
                ? <PageLogs
                    handleOpenLog={handleOpenLog}
                    systemLog={systemLog}/>
                : <>
                    <DetailContent
                        textTileDetail={textTileDetail}
                        oneItemDetailInfo={oneItemDetail?.comment}
                        hideTitle={props.hideTitle}
                    />
                    <DetailInfoItemUser
                        items={oneItemDetail?.items ?? []}
                        address={(oneItemDetail?.address != null)
                            ? {
                                title: oneItemDetail?.address.title ?? '',
                                icon: oneItemDetail.address.icon,
                                street: oneItemDetail.address.street,
                                city: oneItemDetail.address.city,
                                postCode: oneItemDetail.address.postCode,
                                state: oneItemDetail.address.state
                            }
                            : {
                                title: '',
                                icon: undefined,
                                street: '',
                                city: '',
                                postCode: '',
                                state: ''
                            }}
                    />
                    <div className='mt-5 w-fit'>
                        <DetailMemberLogs handleOpenLog={handleOpenLog} />
                        {isAdmin &&
                            <DetailFooter
                                handleOpenFooter={handleOpenFooter}
                                textDeleteButton={textDeleteButton}
                                isDeleted={isDeleted}
                            />
                        }
                    </div>
                </>
            }
        </div>

    )
}

export default DetailComponent
