import React from 'react'

const IconUser: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.791" height="16.791" viewBox="0 0 16.791 16.791" className='mr-2'>
            <g id="user" transform="translate(-4.999 -5)">
                <path id="Path_2" data-name="Path 2" d="M13.394,5a8.4,8.4,0,1,0,8.4,8.4A8.4,8.4,0,0,0,13.394,5Zm5.648,13.187a2.445,2.445,0,0,0-.078-.878c-.327-.975-1.633-1.585-2.564-1.994-.365-.16-1.375-.431-1.5-.89-.19-.726.533-1.28.855-1.852a5.573,5.573,0,0,0,.677-2.1c.2-2.448-1.3-3.881-3.455-3.633a2.884,2.884,0,0,0-2.6,2.849,5.167,5.167,0,0,0,1.069,3.49c.264.362.541.6.5,1.033-.05.517-.6.66-1,.819a11.425,11.425,0,0,0-1.211.606c-.82.453-1.721,1-1.923,1.745a2.394,2.394,0,0,0-.082.791,7.408,7.408,0,1,1,11.308.015Z" fill="#6b6145" />
            </g>
        </svg>

    )
}

export default IconUser
