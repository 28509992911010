/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useEffect, useMemo, useState } from 'react'
import { useForm, type SubmitHandler, Controller } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import { fetchCreateUser, fetchUpdateUser } from '../../redux/thunks/userThunks'
import { type AppDispatch, type RootState } from '../../redux/store'
import Loader from '../global/Loader'
import ModalWindow from '../global/ModalWindow'
import { type InputsAddNewUser, type ModalWindowData } from '../../types'
import { selectUsers } from '../../redux/slices/userSlice'
import { fetchAllHotels } from '../../redux/thunks/hotelThunks'
import FadeAnimation from '../global/FadeAnimation'
import IconSuccess from '../icons/IconSuccess'
import IconWarning from '../icons/IconWarning'
import { useIsAdmin } from '../../helpers'
import { useNavigate } from 'react-router-dom'

interface AddNewUsersProps {
    itemId?: number
    updateForm?: boolean
}

const FormAddNewUser: React.FC<AddNewUsersProps> = (props: AddNewUsersProps) => {
    const { itemId, updateForm } = props
    const isAdmin = useIsAdmin()
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const users = useSelector(selectUsers)
    const dispatch = useDispatch<AppDispatch>()
    const [open, setOpen] = useState(false)
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const [loading, setLoading] = useState(false)
    const [fileLoading, setFileLoading] = useState(false)
    const [errorFile, setErrorFile] = useState('')
    const navigate = useNavigate()
    const userId: number = itemId ?? 0

    const oneUserDetail = useMemo(() => {
        if (itemId !== undefined) {
            const foundUser = users.find((user) => user.id === itemId)
            if (foundUser !== undefined) {
                return foundUser
            }
        }
        return { id: '', name: '', email: '', hotel_id: '', comment: '', sex: '' }
    }, [itemId, users])

    const {
        register,
        handleSubmit,
        setValue,
        control,
        watch,
        formState: { errors }
    } = useForm<InputsAddNewUser>({
        defaultValues: {
            name: oneUserDetail.name,
            email: oneUserDetail.email,
            comment: oneUserDetail.comment,
            sex: oneUserDetail.sex
        }
    })

    const sex = watch('sex')

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    const closeWindow = (): void => {
        setOpen(false)
    }

    const onSubmit: SubmitHandler<InputsAddNewUser> = (data) => {
        const formData = new FormData()

        formData.append('name', data.name)
        formData.append('email', data.email)
        formData.append('password', data.password)
        formData.append('hotel_id', data.hotel_id)
        if (isAdmin && updateForm === true) {
            formData.append('role', 'user')
            formData.append('is_admin', '0')
        } else {
            formData.append('role', 'user')
            formData.append('is_admin', '0')
        }
        formData.append('role', 'user')
        formData.append('comment', data.comment ?? '')
        formData.append('image', data.image[0])
        formData.append('sex', data.sex)
        formData.append('address[street]', '0')
        formData.append('address[city]', '0')
        formData.append('address[postcode]', '0')
        formData.append('address[state]', '0')
        formData.append('birth_date', '01.01.2000')
        formData.append('preferred_languange', 'en')

        setLoading(true)

        if (updateForm === true) {
            void dispatch(fetchUpdateUser({ formData, userId }))
        } else {
            void dispatch(fetchCreateUser(formData))
                .then((response: any) => {
                    if (response.payload?.errors?.email != null) {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Chyba`,
                            subtitle: t`Administrátor s touto e-mailovou adresou už existuje.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning/>,
                            action: () => { setOpen(false) }
                        })
                    } else if (response.payload.message === 'successfully') {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Pridanie nového administrátora`,
                            subtitle: t`Úspešne ste pridali nového administrátora.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconSuccess/>,
                            action: () => { navigate('/administrators') }
                        })
                    } else {
                        setLoading(false)
                        setOpen(true)
                        setDataModal({
                            title: t`Chyba`,
                            subtitle: t`Nepodarilo sa pridať nového administrátora.`,
                            textButton: t`Zatvoriť`,
                            icon: <IconWarning/>,
                            action: () => { navigate('/administrators') }
                        })
                    }
                })
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files?.[0]
        setFileLoading(true)
        if (file != null) {
            setFileLoading(false)
        } else {
            setErrorFile(t`Nahravanie súbra zlyhalo`)
        }
    }

    return (
        <>
            <ModalWindow
                disabledCrossIcon={true}
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                closeWindow={closeWindow}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px] md:flex-row ">
                    <div className={`pb-3 ${updateForm === true ? 'md:w-1/2 w-full' : 'md:w-1/3 w-full'}`}>
                        <label htmlFor="name" className="sm-font-b md:inline hidden">
                            <Trans>Meno a priezvisko*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('name', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    }
                                })}
                                type="text"
                                placeholder={t`Meno a priezvisko*`}
                                name="name"
                                id="name"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.name != null) && (
                                <FadeAnimation type="up" key={errors.name.message}>
                                    <div className="error-text">
                                        {errors.name.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>

                    <div className={`pb-3 ${updateForm === true ? 'md:w-1/2 w-full' : 'md:w-1/3 w-full'}`}>
                        <label htmlFor="email" className="sm-font-b md:inline hidden">
                            <Trans>E-mailová adresa*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('email', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    },
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t`Zadajte platnú e-mailovú adresu`
                                    }
                                })}
                                type="email"
                                placeholder={t`Email*`}
                                name="email"
                                id="email"
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                            {(errors.email != null) && (
                                <FadeAnimation type="up" key={errors.email.message}>
                                    <div className="error-text">
                                        {errors.email.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                    {updateForm === true
                        ? null
                        : <div className={'pb-3 md:w-1/3 w-full'}>
                            <label htmlFor="password" className="sm-font-b md:inline hidden">
                                <Trans>Heslo*</Trans>
                            </label>
                            <div className="mt-[6px]">
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: t`Pole je povinné`,
                                        minLength: {
                                            value: 8,
                                            message: t`Heslo musí obsahovať aspoň 8 znakov`
                                        },
                                        validate: (value) => {
                                            const hasUppercase = /[A-Z]/.test(value)
                                            const hasLowercase = /[a-z]/.test(value)
                                            const hasNumber = /\d/.test(value)
                                            if (!hasUppercase) {
                                                return t`Heslo musí obsahovať aspoň jedno veľké písmeno`
                                            }
                                            if (!hasLowercase) {
                                                return t`Heslo musí obsahovať aspoň jedno malé písmeno`
                                            }
                                            if (!hasNumber) {
                                                return t`Heslo musí obsahovať aspoň jedno číslo`
                                            }
                                            return true
                                        }
                                    }}
                                    render={({ field }) => <input
                                        className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                                        placeholder={t`Heslo*`}
                                        autoComplete="off"
                                        type="password" {...field} />} />
                            </div><div>
                                {(errors.password != null) && (
                                    <FadeAnimation type="up" key={errors.password.message}>
                                        <div className="error-text">
                                            {errors.password.message}
                                        </div>
                                    </FadeAnimation>
                                )}
                            </div>
                        </div>
                    }
                </div>
                <div className="flex flex-col justify-center items-baseline md:gap-[21px] text-left lg:px-[46.5px] md:flex-row mt-3">
                    <div className={`pb-3 flex flex-col  relative ${updateForm === true ? 'md:w-1/2 w-full' : 'md:w-1/3 w-full'}`}>
                        <legend className="sm-font-b xs:mr-7 mr-4">
                            <Trans>Pohlavie*</Trans>
                        </legend>
                        <div className="relative flex items-start mt-[6px] h-[40px]">
                            <div className="flex h-6 items-center mr-7">
                                <Controller
                                    control={control}
                                    name="sex"
                                    defaultValue=""
                                    rules={{ required: t`Vyberte jednu z možností.` }}
                                    render={({ field }) => (
                                        <>
                                            <input
                                                id="male"
                                                type="checkbox"
                                                checked={field.value === 'male'}
                                                onChange={() => { setValue('sex', 'male') }}
                                                className="input-transition input-checkbox"
                                            />
                                            <label htmlFor="male" className="font-basic ml-[10px]">
                                                <Trans>Muž</Trans>
                                            </label>
                                        </>
                                    )}
                                />
                            </div>
                            <div className="flex h-6 items-center">
                                <Controller
                                    control={control}
                                    name="sex"
                                    defaultValue=""
                                    render={({ field }) => (
                                        <>
                                            <input
                                                id="female"
                                                type="checkbox"
                                                checked={field.value === 'female'}
                                                onChange={() => { setValue('sex', 'female') }}
                                                className="input-transition input-checkbox"
                                            />
                                            <label htmlFor="female" className="font-basic ml-[10px]">
                                                <Trans>Žena</Trans>
                                            </label>
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className='absolute w-full bottom-4'>
                            {sex === '' && (
                                <FadeAnimation type="up" key={errors.sex?.message}>
                                    <div className="error-text">
                                        {errors.sex?.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>

                    <div className={`pb-3  ${updateForm === true ? 'md:w-1/2 w-full' : 'md:w-1/3 w-full'}`}>
                        <label htmlFor="hotel" className="sm-font-b md:inline hidden">
                            <Trans>Zariadenie*</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <select
                                {...register('hotel_id', {
                                    required: {
                                        value: true,
                                        message: t`Vyberte jednu z možností.`
                                    }
                                })}
                                id="hotel"
                                name="hotel_id"
                                className={`input-form py-[5px] input-focus input-transition font-basic font-montserrat-bold md:font-montserrat-regular 
                                ${(updateForm === true) ? 'font-montserrat-regular' : 'font-montserrat-bold md:font-montserrat-regular'}`}
                                defaultValue={oneUserDetail.hotel_id}
                            >
                                <option value={''} disabled hidden ><Trans>Vyberte zariadenie*</Trans></option>
                                {hotels.map(hotel => (
                                    <option
                                        key={hotel.id}
                                        value={hotel.id}
                                        disabled={hotel.deleted_at !== null}

                                    >
                                        {hotel.name}
                                    </option>
                                ))}
                            </select>
                            {(errors.hotel_id != null) && (
                                <FadeAnimation type="up" key={errors.hotel_id.message}>
                                    <div className="error-text">
                                        {errors.hotel_id.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>

                    </div>
                    {updateForm === true ? null : <div className="md:w-1/3 w-full pb-3 md:block hidden"></div>}

                </div>

                <div className={'flex  flex-col justify-center md:gap-[21px] text-left lg:px-[46.5px]'}>
                    <div className={'w-full pb-3'}>
                        <label htmlFor="comment" className="sm-font-b md:inline hidden">
                            <Trans>Poznámka</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <textarea
                                {...register('comment')}
                                name="comment"
                                id="note"
                                placeholder={t`Poznámka`}
                                className="input-form py-[5px] input-focus input-transition font-basic placeholder"
                            />
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center flex-col'>
                    <Loader loading={fileLoading}/>
                    {fileLoading &&
                        <p className='font-basic-black'>
                            <Trans>Nahrávam súbor...</Trans>
                        </p>
                    }

                </div>
                <div className="flex justify-center text-left lg:px-[46.5px]">
                    <div className="w-full">
                        <label htmlFor="image" className="sm-font-b" >
                            <Trans>Fotka</Trans>
                        </label>
                        <div className="mt-[6px]">
                            <input
                                {...register('image')}
                                className="
                                input-form py-[5px] input-focus input-transition font-basic input-file"
                                type="file"
                                id="image"
                                name='image'
                                onChange={handleFileChange}
                            />
                            {(errorFile.length > 0) && (
                                <FadeAnimation type="up" key={errorFile}>
                                    <div className="error-text">
                                        {errorFile}
                                    </div>
                                </FadeAnimation>
                            )}
                        </div>
                    </div>
                </div>
                <button
                    className='btn btn-red btn-m block mx-auto  mt-5 w-max-[189px]'
                    type="submit"
                >
                    {(updateForm === true) ? t`Upraviť administrátora` : t`Pridať administrátora`}
                </button>
            </form>
            {loading &&
                <div className='flex justify-center py-4'>
                    <Loader/>
                </div>
            }
        </>
    )
}

export default FormAddNewUser
