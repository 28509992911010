/* eslint-disable lingui/no-unlocalized-strings */
import { Trans } from '@lingui/macro'
import Avatar from '../global/Avatar'
import React from 'react'
import { type Client } from '../../types'

interface DetailHeaderMemberProps {
    oneItemDetail: Client
    buttonIcon: string
    handleOpenModalForm?: () => void
    openLog: boolean
}

const DetailHeaderMember: React.FC<DetailHeaderMemberProps> = (props: DetailHeaderMemberProps) => {
    const { oneItemDetail, buttonIcon, handleOpenModalForm, openLog } = props
    const classAvatar = 'h-full w-full'

    return (
        <div className={`flex sm:flex-row flex-col min-w-0 gap-x-7 ${oneItemDetail?.deleted_at === null ? 'items-end' : 'items-start'}`}>
            <div className='w-[95px] h-[95px] sm:mx0 mx-auto sm:mb-0 mb-4'>
                <Avatar
                    imageUrl={oneItemDetail?.image_path }
                    classAvatar={classAvatar}
                    type={oneItemDetail.sex}
                />
            </div>
            <div className="flex-auto sm:w-0 w-full ">
                <p className="font-basic-black leading-[18px] font-montserrat-bold">
                    {oneItemDetail?.name}
                </p>
                <div className='flex '>
                    <p className="font-basic-black leading-[18px]">
                        <Trans>Číslo registrácie:</Trans>
                        <span className='font-montserrat-bold'> {oneItemDetail?.registration_number}</span>
                    </p>
                </div>
                {oneItemDetail?.card_number !== null &&
                <div className='flex '>
                    <p className="font-basic-black leading-[18px]">
                        <Trans>Číslo členskej karty:</Trans>
                        <span className='font-montserrat-bold'> {oneItemDetail?.card_number}</span>
                    </p>
                </div>
                }
                {oneItemDetail?.deleted_at === null &&
                <button
                    className={`${openLog ? 'invisible' : 'btn btn-s btn-gray font-basic font-think flex items-center mt-4'}`}
                    onClick={handleOpenModalForm}
                >
                    <img
                        src={require('../../assets/img/icons/' + buttonIcon)}
                        alt="user"
                        className='pr-1'
                    />
                    <Trans>Upraviť údaje</Trans>
                </button>
                }
            </div>
        </div>
    )
}

export default DetailHeaderMember
