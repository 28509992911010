import React, { useState } from 'react'
import Avatar from '../global/Avatar'
import ModalWindowForm from '../global/ModalWindowForm'
import { Trans, t } from '@lingui/macro'
import FormChangePasswordAdmin from '../forms/FormChangePasswordAdmin'
import { useIsAdmin } from '../../helpers'
import ModalWindow from '../global/ModalWindow'
import IconSuccess from '../icons/IconSuccess'

interface DetailHeaderProps {
    textButtonDetail: string
    buttonIcon: string
    openLog: boolean
    oneItemDetail?: {
        title: string
        subtitle: string
        image: string | null
        id: number
        sex: string
        email: string
        deleted_at: string | null
    }
    handleOpenModalForm?: () => void
    showButtonPassword: boolean
}

const DetailHeader: React.FC<DetailHeaderProps> = (props: DetailHeaderProps) => {
    const { textButtonDetail, buttonIcon, oneItemDetail, handleOpenModalForm, showButtonPassword, openLog } = props
    const [openModalForm, setOpenModalForm] = useState(false)
    const [open, setOpen] = useState(false)
    const isAdmin = useIsAdmin()

    // eslint-disable-next-line lingui/no-unlocalized-strings
    const classAvatar = 'h-full w-full'
    const titleModalForm = t`ZMENA HESLA`
    const subtitleModalForm = t`Pre zmenu hesla zadajte nové heslo a potvrďte tlačidlom Zmeniť heslo.`
    const userId = oneItemDetail?.id ?? 0

    const closeWindow = (): void => {
        setOpen(false)
    }

    const handleOpenModalWindow = (): void => {
        setOpen(true)
    }

    const dataModal = {
        title: t`Heslo zmenené`,
        subtitle: t`Vaše heslo bolo úspešne zmenené.`,
        textButton: t`Zatvoriť`,
        icon: <IconSuccess/>,
        action: () => { setOpen(false) }
    }

    return (
        <>
            <ModalWindowForm
                open={openModalForm}
                setOpen={setOpenModalForm}
                titleModalForm={titleModalForm}
                subtitleModalForm={subtitleModalForm}
                myFormComponent={
                    <FormChangePasswordAdmin
                        userId={userId}
                        setOpenModalForm={setOpenModalForm}
                        handleOpenModalWindow={handleOpenModalWindow}
                    />}
            />
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                closeWindow={closeWindow}
            />
            <div className={`flex xs:flex-row flex-col min-w-0 gap-x-7 ${isAdmin && oneItemDetail?.deleted_at === null ? 'items-end' : 'items-start'}`}>
                <div className='w-[95px] h-[95px] xs:mx0 mx-auto xs:mb-0 mb-4'>
                    <Avatar
                        imageUrl={oneItemDetail?.image }
                        classAvatar={classAvatar}
                        type={oneItemDetail?.sex}
                    />
                </div>
                <div className="flex-auto xs:w-0 w-full">
                    <p className="font-basic-black leading-[18px] font-montserrat-bold">{oneItemDetail?.title}</p>
                    <div className='flex '>
                        <p className="font-basic-black leading-[18px]">{oneItemDetail?.subtitle}</p>
                    </div>
                    <div className='flex '>
                        <p className="font-basic-black leading-[18px]">{oneItemDetail?.email}</p>
                    </div>
                    {isAdmin && oneItemDetail?.deleted_at === null && (
                        <div>
                            <button
                                className={`${openLog ? 'invisible' : 'btn btn-s btn-gray font-basic font-think flex items-center mt-4'}`}
                                onClick={handleOpenModalForm}
                            >
                                <img
                                    src={require('../../assets/img/icons/' + buttonIcon)}
                                    alt="user"
                                    className='pr-1'
                                />

                                {textButtonDetail}
                            </button>
                            <button
                                className={showButtonPassword ? 'text-11 text-red flex mt-2  showButtonPassword link-hover' : 'hidden'}
                                onClick={() => { setOpenModalForm(true) }}>
                                <Trans>Zmeniť heslo</Trans>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default DetailHeader
