/* eslint-disable lingui/no-unlocalized-strings */
import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import { type User } from '../../types' // Import the User interface
import { type RootState } from '../store' // Import the RootState type
import * as userThunks from '../thunks/userThunks'

interface UserState {
    data: User[]
    loading: boolean
    error: string | null
    currentPage: number
    total: number
    lastPage: number
    updateSuccess: boolean
    existEmail: string
}

interface RejectedActionPayload {
    errors: {
        email: string
    }
}

const initialState: UserState = {
    data: [],
    loading: false,
    error: null,
    currentPage: 1,
    total: 0,
    lastPage: 0,
    updateSuccess: true,
    existEmail: ''
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUpdateSuccess(state) {
            state.updateSuccess = true
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(userThunks.fetchAllUsers.pending, (state) => {
                state.loading = true
            })
            .addCase(userThunks.fetchAllUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
                state.loading = false
                state.data = action.payload
            })
            .addCase(userThunks.fetchAllUsers.rejected, (state, action) => {
                state.loading = false
                state.error = null
            })
            .addCase(userThunks.fetchPaginationUsers.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(userThunks.fetchPaginationUsers.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload.data
                state.total = action.payload.total
                state.currentPage = action.payload.currentPage
                state.lastPage = action.payload.lastPage
            })
            .addCase(userThunks.fetchPaginationUsers.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch users'
            })
            .addCase(userThunks.fetchUpdateUser.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(userThunks.fetchUpdateUser.fulfilled, (state, action) => {
                state.loading = false
                state.updateSuccess = true
                state.data = state.data.map((user) => {
                    if (user.id === action.payload.id) {
                        return action.payload.data
                    }
                    return user
                })
            })
            .addCase(userThunks.fetchUpdateUser.rejected, (state, action) => {
                state.loading = false
                state.updateSuccess = false
                const payload = action.payload as RejectedActionPayload
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                state.existEmail = payload.errors && payload.errors.email !== '' ? 'exist_email' : ''
                state.error = action.error.message ?? 'Failed to fetch users'
            })
            .addCase(userThunks.deleteUser.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((user) => {
                    if (user.id === action.payload) {
                        return { ...user, deleted_at: new Date().toISOString() }
                    }

                    return user
                })
            })
            .addCase(userThunks.restoreUser.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((user) => {
                    if (user.id === action.payload) {
                        return { ...user, deleted_at: null }
                    }

                    return user
                })
            })
            .addCase(userThunks.fetchChangePassword.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(userThunks.fetchChangePassword.fulfilled, (state, action) => {
                state.loading = false
                state.data = state.data.map((user) => {
                    if (user.id === action.payload.id) {
                        return action.payload.data
                    }
                    return user
                })
            })
            .addCase(userThunks.fetchChangePassword.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed '
            })
        // Add similar cases for fetchUserById, updateUser, deleteUser
    }
})

export const selectUsers = (state: RootState): User[] => state.user.data
export const selectUserLoading = (state: RootState): boolean => state.user.loading
export const selectUserError = (state: RootState): string | null => state.user.error
export const { resetUpdateSuccess } = userSlice.actions

export default userSlice.reducer
