/* eslint-disable lingui/no-unlocalized-strings */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type User } from '../../types'
import { type CreateResponse } from './hotelThunks'
import { type ResetPasswordInputs } from '../../components/forms/FormResetPassword'
import { t } from '@lingui/macro'

export interface LoginCredentials {
    email: string
    password: string
}

export interface LoginResponse {
    messsage: string
    user: User
}

export interface ResetEmail {
    email: string
}

export const login = createAsyncThunk(
    'login/userLogin',
    async (credentials: LoginCredentials, { rejectWithValue }) => {
        try {
            const response = await axios.post<LoginResponse>('/api/login', credentials)
            return response.data.user
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && (error.response != null)) {
                return rejectWithValue(error.response.data)
            }
            return rejectWithValue(error?.message ?? 'Failed to login')
        }
    }
)

export const logout = createAsyncThunk(
    'login/userLogout',
    async (credentials: LoginCredentials, { rejectWithValue }) => {
        try {
            await axios.post<any>('/api/logout')
            return true
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && (error.response != null)) {
                return rejectWithValue(error.response.data)
            }
            return rejectWithValue(error?.message ?? 'Failed to log')
        }
    }
)

export const fetchUpdateUserProfile = createAsyncThunk(
    'users/fetchUpdateUserProfile',
    async (formData: FormData) => {
        try {
            formData.append('_method', 'PUT')
            const response = await axios.post<CreateResponse>('/api/users/updateProfil', formData)
            return {
                data: response.data
            }
        } catch (error: Error | any) {
            return error.response.data
        }
    }
)

export const fetchForgotPassword = createAsyncThunk(
    'login/forgotPassword',
    async (email: ResetEmail, { rejectWithValue }) => {
        try {
            await axios.post<LoginResponse>('/api/forgot-password', email)
            return true
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && (error.response != null)) {
                if (error.response.status === 400) {
                    return rejectWithValue(t`Opakujte akciu neskôr prosim.`)
                } else if (error.response.status === 404) {
                    return rejectWithValue(t`E-mail sa nenašiel. Prosím, skontrolujte si váš email.`)
                } else if (error.response.status === 500) {
                    return rejectWithValue(t`Nastala neočakávaná chyba.`)
                } else {
                    return rejectWithValue(error.response.data)
                }
            }
            return rejectWithValue(error?.message ?? 'Failed to login')
        }
    }
)

export const fetchResetPassword = createAsyncThunk(
    'login/restPassword',
    async (data: ResetPasswordInputs, { rejectWithValue }) => {
        try {
            await axios.post<LoginResponse>(`/api/reset-password/${data.token}`, data)
            return true
        } catch (error: Error | any) {
            if (axios.isAxiosError(error) && (error.response != null)) {
                if (error.response.status === 400) {
                    return rejectWithValue(t`Akcia sa nepodarila. Prosím, vygenerujte si nový link na obnovenie hesla`)
                } else if (error.response.status === 404) {
                    return rejectWithValue(t`E-mail sa nenašiel. Prosím, skontrolujte si váš email.`)
                } else if (error.response.status === 500) {
                    return rejectWithValue(t`Nastala neočakávaná chyba.`)
                } else {
                    return rejectWithValue(error.response.data)
                }
            }
            return rejectWithValue(error?.message ?? 'Failed to login')
        }
    }
)

export const fetchChangeLanguage = createAsyncThunk(
    'login/fetchChangeLanguage',
    async (lang: string) => {
        try {
            const response = await axios.put<CreateResponse>(`/api/user/changeLanguage/${lang}`)
            return {
                data: response.data
            }
        } catch (error: Error | any) {
            return error.response.data
        }
    }
)
