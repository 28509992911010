/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useState } from 'react'
import { type SubmitHandler, useForm, Controller } from 'react-hook-form'
import { fetchChangeAdminPassword } from '../../redux/thunks/userThunks'
import { useDispatch } from 'react-redux'
import { type AppDispatch } from '../../redux/store'
import Loader from '../global/Loader'
import FadeAnimation from '../global/FadeAnimation'

interface ChangePassword {
    newPassword: string
    confirmNewPassword: string
}
interface PropsChangePasswordAdmin {
    userId: number
    setOpenModalForm: (open: boolean) => void
    handleOpenModalWindow: () => void
}
const FormChangePasswordAdmin: React.FC<PropsChangePasswordAdmin> = (props: PropsChangePasswordAdmin) => {
    const { userId, setOpenModalForm, handleOpenModalWindow } = props
    const dispatch = useDispatch<AppDispatch>()
    const [loading, setLoading] = useState(false)

    const {
        handleSubmit,
        control,
        getValues,
        formState: { errors }
    } = useForm<ChangePassword>({ })

    const onSubmit: SubmitHandler<ChangePassword> = (data) => {
        const formData = new FormData()
        formData.append('new_password', data.newPassword)
        formData.append('confirm_password', data.confirmNewPassword)
        setLoading(true)
        void dispatch(fetchChangeAdminPassword({ formData, userId }))
            .then(() => {
                setLoading(false)
                handleOpenModalWindow()
                setOpenModalForm(false)
            })
    }
    return (
        <>
            <div className='absolute top-[265px] left-1/2 '>
                <Loader loading={loading}/>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="md:w-36 pb-3 w-full mx-auto ">
                    <label htmlFor="name" className="sm-font-b">
                        <Trans>Nové heslo*</Trans>
                    </label>
                    <Controller
                        name="newPassword"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t`Pole je povinné`,
                            minLength: {
                                value: 8,
                                message: t`Heslo musí obsahovať aspoň 8 znakov`
                            },
                            validate: (value) => {
                                const hasUppercase = /[A-Z]/.test(value)
                                const hasLowercase = /[a-z]/.test(value)
                                const hasNumber = /\d/.test(value)
                                if (!hasUppercase) {
                                    return t`Heslo musí obsahovať aspoň jedno veľké písmeno`
                                }
                                if (!hasLowercase) {
                                    return t`Heslo musí obsahovať aspoň jedno malé písmeno`
                                }
                                if (!hasNumber) {
                                    return t`Heslo musí obsahovať aspoň jedno číslo`
                                }
                                return true
                            }
                        }}
                        render={({ field }) =>
                            <input
                                className="input-form py-[5px] mt-[6px] input-focus input-transition input-text-l input-placeholder"
                                type="password" {...field}
                                placeholder='*******'
                                autoComplete="off"
                            />
                        }
                    />
                    {(errors.newPassword != null) && (
                        <FadeAnimation type="up" key={errors.newPassword.message}>
                            <div className="error-text">
                                {errors.newPassword.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <div className="md:w-36 w-full mx-auto ">
                    <label htmlFor="name" className="sm-font-b">
                        <Trans>Zopakujte nové heslo*</Trans>
                    </label>
                    <Controller
                        name="confirmNewPassword"
                        control={control}
                        defaultValue=""
                        rules={{
                            required: t`Pole je povinné`,
                            minLength: {
                                value: 8,
                                message: t`Heslo musí obsahovať aspoň 8 znakov`
                            },
                            validate: {
                                matchesNewPassword: (value) => {
                                    const newPassword = getValues('newPassword')
                                    return newPassword === value || t`Heslá sa nezhodujú`
                                },
                                hasUppercase: (value) => /[A-Z]/.test(value) || t`Heslo musí obsahovať aspoň jedno veľké písmeno`,
                                hasLowercase: (value) => /[a-z]/.test(value) || t`Heslo musí obsahovať aspoň jedno malé písmeno`,
                                hasNumber: (value) => /\d/.test(value) || t`Heslo musí obsahovať aspoň jedno číslo`
                            }
                        }}
                        render={({ field }) =>
                            <input
                                className="input-form py-[5px] mt-[6px] input-focus input-transition input-text-l input-placeholder"
                                type="password" {...field}
                                placeholder='*******'
                                autoComplete="off"
                            />
                        }
                    />
                    {(errors.confirmNewPassword != null) && (
                        <FadeAnimation type="up" key={errors.confirmNewPassword.message}>
                            <div className="error-text">
                                {errors.confirmNewPassword.message}
                            </div>
                        </FadeAnimation>
                    )}
                </div>
                <button
                    className='btn btn-red btn-m block mx-auto mt-8'
                    type="submit"
                >
                    <Trans>Zmeniť heslo</Trans>
                </button>
            </form>
        </>
    )
}

export default FormChangePasswordAdmin
