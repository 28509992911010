import React from 'react'
import { motion, type Variants } from 'framer-motion'

interface IProps {
    children: React.ReactNode
    type?: 'down' | 'up' | 'left' | 'right'
    class?: string
}

const DURATION = 0.5

const FadeAnimation: React.FC<IProps> = (props) => {
    let pageMotion: Variants = {
        initial: { opacity: 1, height: 1, zIndex: 2 },
        animate: { opacity: 1, height: 'auto', transition: { duration: DURATION }, zIndex: 2 },
        exit: { opacity: 0, height: 0, transition: { duration: DURATION / 2 } }
    }

    switch (props.type) {
        case 'down':
            pageMotion = {
                initial: { opacity: 0, y: -10 },
                animate: { opacity: 1, y: 0, transition: { duration: DURATION } },
                exit: { opacity: 0, y: 10, transition: { duration: DURATION / 2 } }
            }
            break
        case 'up':
            pageMotion = {
                initial: { opacity: 0, y: -10 },
                animate: { opacity: 1, y: -5, transition: { duration: DURATION } },
                exit: { opacity: 0, y: -10, transition: { duration: DURATION / 2 } }
            }
            break
        case 'left':
            pageMotion = {
                initial: { opacity: 0, x: 10 },
                animate: { opacity: 1, y: 0, transition: { duration: DURATION } },
                exit: { opacity: 0, x: -10, transition: { duration: DURATION / 2 } }
            }
            break
        case 'right':
            pageMotion = {
                initial: { opacity: 0, x: 10 },
                animate: { opacity: 1, y: 0, transition: { duration: DURATION } },
                exit: { opacity: 0, x: -10, transition: { duration: DURATION / 2 } }
            }
            break
    }

    return (
        <motion.div
            initial="initial"
            animate="animate"
            exit="exit"
            variants={pageMotion}
            className={`absolute fade ${props.class}`}
        >
            {props.children}
        </motion.div>
    )
}

export default FadeAnimation
