import { Trans } from '@lingui/macro'
import React from 'react'
import FormChangePassword from '../components/forms/FormChangePassword'

const PageChangePassword: React.FC = () => {
    return (
        <>
            <div className='bg-light-gray mt-9 lg:mx-[50px] lg:mb-[182px] shadow-m-shadow rounded-[10px] pt-[26px] pb-9 lg-max:px-[25px]'>
                <div className='text-center'>
                    <h4 className='uppercase md:text-base text-[11px] text-main-brown font-montserrat-bold pb-[14px] tracking-1.6'>
                        <Trans>zmena hesla</Trans>
                    </h4>
                    <p className='md:text-11 text-[10px] text-light-black sm:w-1/2 w-full mx-auto px-2 mb-[30px]'>
                        <Trans>
                            Pre zmenu hesla zadajte staré, nové heslo a potvrďte tlačidlom Zmeniť heslo.
                        </Trans>
                    </p>
                </div>
                <FormChangePassword/>
            </div>
        </>
    )
}

export default PageChangePassword
