/* eslint-disable lingui/no-unlocalized-strings */
import { createSlice } from '@reduxjs/toolkit'
import { type VoucherType, type VoucherModel } from '../../types'
import { type RootState } from '../store'
import * as voucherThunks from '../thunks/voucherThunks'

interface VoucherState {
    data: VoucherModel[]
    allData: VoucherModel[]
    loading: boolean
    error: string | null
    currentPage: number
    total: number
    lastPage: number
}

const initialState: VoucherState = {
    data: [],
    allData: [],
    loading: false,
    error: null,
    currentPage: 1,
    total: 0,
    lastPage: 0
}

const voucherSlice = createSlice({
    name: 'voucher',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(voucherThunks.fetchAllVouchers.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(voucherThunks.fetchAllVouchers.fulfilled, (state, action) => {
                state.loading = false
                state.allData = action.payload.map(voucher => {
                    const appliedAtDate = voucher.applied_at_date
                    const expirationDate = voucher.expiration_date

                    let state: VoucherType = 'unused'
                    if (appliedAtDate === null && expirationDate > new Date().toISOString()) {
                        state = 'unused'
                    } else if (appliedAtDate === null && expirationDate < new Date().toISOString()) {
                        state = 'expired'
                    } else {
                        state = 'used'
                    }

                    return {
                        id: voucher.id,
                        client_id: voucher.client_id,
                        number: voucher.number,
                        expiration_date: voucher.expiration_date,
                        created_at: voucher.created_at,
                        updated_at: voucher.updated_at,
                        applied_at_date: voucher.applied_at_date,
                        applied_by: voucher.applied_by,
                        applied_at_hotel: voucher.applied_at_hotel,
                        price: voucher.price,
                        currency: voucher.currency,
                        state,
                        client_email: voucher.client_email,
                        client_name: voucher.client_name
                    }
                })
            })
            .addCase(voucherThunks.fetchAllVouchers.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch vouchers'
            })
            .addCase(voucherThunks.fetchPaginationVouchers.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(voucherThunks.fetchPaginationVouchers.fulfilled, (state, action) => {
                state.loading = false
                state.total = action.payload.total
                state.currentPage = action.payload.currentPage
                state.lastPage = action.payload.lastPage
                state.data = action.payload.data.map(voucher => {
                    const appliedAtDate = voucher.applied_at_date
                    const expirationDate = voucher.expiration_date

                    let state: VoucherType = 'unused'
                    if (appliedAtDate === null && expirationDate > new Date().toISOString()) {
                        state = 'unused'
                    } else if (appliedAtDate === null && expirationDate < new Date().toISOString()) {
                        state = 'expired'
                    } else {
                        state = 'used'
                    }

                    return {
                        id: voucher.id,
                        client_id: voucher.client_id,
                        number: voucher.number,
                        expiration_date: voucher.expiration_date,
                        created_at: voucher.created_at,
                        updated_at: voucher.updated_at,
                        applied_at_date: voucher.applied_at_date,
                        applied_by: voucher.applied_by,
                        applied_at_hotel: voucher.applied_at_hotel,
                        price: voucher.price,
                        currency: voucher.currency,
                        state,
                        client_email: voucher.client_email,
                        client_name: voucher.client_name
                    }
                })
            })
            .addCase(voucherThunks.fetchPaginationVouchers.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch vouchers'
            })
            .addCase(voucherThunks.generateVoucher.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(voucherThunks.generateVoucher.fulfilled, (state, action) => {
                state.loading = false

                // const updatedExistingVouchers = state.allData.map(voucher => ({
                //     ...voucher,
                //     state: 'expired'
                // }))
                const appliedAtDate = action.payload.data.applied_at_date
                const expirationDate = action.payload.data.expiration_date

                let newState: VoucherType = 'unused'
                if (appliedAtDate === null && expirationDate > new Date().toISOString()) {
                    newState = 'unused'
                } else if (appliedAtDate === null && expirationDate < new Date().toISOString()) {
                    newState = 'expired'
                } else {
                    newState = 'used'
                }

                const newVoucher = {
                    ...action.payload.data,
                    state: newState
                }

                state.allData = [newVoucher, ...state.allData]
            })
            .addCase(voucherThunks.generateVoucher.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to generate voucher'
            })
    }
})

export const selectVouchers = (state: RootState): VoucherModel[] => state.voucher.data
export const selectAllVouchers = (state: RootState): VoucherModel[] => state.voucher.allData
export const selectVoucherLoading = (state: RootState): boolean => state.voucher.loading
export const selectVoucherError = (state: RootState): string | null => state.voucher.error

export default voucherSlice.reducer
