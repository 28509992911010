import React from 'react'

export const IconEmail: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.597" height="9.435" viewBox="0 0 12.597 9.435">
            <g id="email" transform="translate(0 -64.266)">
                <path id="Path_21" data-name="Path 21" d="M7.351,174.9a1.895,1.895,0,0,1-2.106,0L.084,171.461c-.029-.019-.057-.039-.084-.059v5.639a1.159,1.159,0,0,0,1.16,1.16H11.438a1.159,1.159,0,0,0,1.16-1.16V171.4q-.041.031-.084.06Z" transform="translate(0 -104.5)" fill="#c6c0b2" />
                <path id="Path_22" data-name="Path 22" d="M.493,66.347l5.162,3.441a1.157,1.157,0,0,0,1.287,0L12.1,66.347a1.105,1.105,0,0,0,.493-.922,1.161,1.161,0,0,0-1.159-1.159H1.159A1.161,1.161,0,0,0,0,65.426a1.1,1.1,0,0,0,.493.921Z" fill="#c6c0b2" />
            </g>
        </svg>
    )
}
