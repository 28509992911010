import React from 'react'

const IconHelp: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14.677" height="14.678" viewBox="0 0 14.677 14.678">
            <g id="help-web-button" transform="translate(-0.008)">
                <path id="Path_14" data-name="Path 14" d="M7.347,0a7.339,7.339,0,1,0,7.338,7.335A7.337,7.337,0,0,0,7.347,0ZM7.87,11.419a.864.864,0,0,1-.592.228.894.894,0,0,1-.606-.225.788.788,0,0,1-.259-.629.81.81,0,0,1,.251-.6.845.845,0,0,1,.614-.245.836.836,0,0,1,.848.848A.8.8,0,0,1,7.87,11.419ZM10,6.15a2.6,2.6,0,0,1-.467.629q-.27.265-.97.89a4.016,4.016,0,0,0-.31.31,1.263,1.263,0,0,0-.174.245,1.179,1.179,0,0,0-.088.222q-.031.111-.094.39a.633.633,0,0,1-.677.592.693.693,0,0,1-.5-.193.76.76,0,0,1-.2-.575,2.118,2.118,0,0,1,.148-.828,2.092,2.092,0,0,1,.392-.615,9,9,0,0,1,.66-.629q.364-.319.526-.481a1.627,1.627,0,0,0,.273-.361.87.87,0,0,0,.111-.432,1,1,0,0,0-.338-.768,1.236,1.236,0,0,0-.874-.313,1.208,1.208,0,0,0-.922.316,2.52,2.52,0,0,0-.5.93q-.194.643-.734.643A.722.722,0,0,1,4.72,5.9.687.687,0,0,1,4.5,5.41a2.059,2.059,0,0,1,.347-1.1A2.671,2.671,0,0,1,5.861,3.4a3.186,3.186,0,0,1,1.553-.364,3.314,3.314,0,0,1,1.457.3,2.361,2.361,0,0,1,.976.828A2.032,2.032,0,0,1,10.191,5.3,1.752,1.752,0,0,1,10,6.15Z" fill="#6b6145" />
            </g>
        </svg>

    )
}

export default IconHelp
