/* eslint-disable @typescript-eslint/no-misused-promises */
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState, type AppDispatch } from '../../redux/store'
import { applyVoucher, fetchVerifyVoucher } from '../../redux/thunks/voucherThunks'
import ModalWindow from '../global/ModalWindow'
import { type ModalWindowData } from '../../types'
import Loader from '../global/Loader'
import { IconVoucher } from '../icons/IconVoucher'
import FadeAnimation from '../global/FadeAnimation'
import IconWarning from '../icons/IconWarning'
import IconSuccess from '../icons/IconSuccess'
import { formatDate } from '../../helpers'
import { fetchAllHotels } from '../../redux/thunks/hotelThunks'
import SelectBox from '../global/SelectBox'
import IconInfo from '../icons/IconInfo'

interface IFormInput {
    voucher: string
}

interface PayloadStatus {
    idClient: string
    status: string
    client: string

}

const FormCodeVerification: React.FC = () => {
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const user = useSelector((state: RootState) => state.login.user)
    const [open, setOpen] = useState(false)
    const [voucher, setVoucher] = useState('')
    const [hotelName, setHotelName] = useState('')
    const selectedHotel = useRef('')
    const dispatch = useDispatch<AppDispatch>()
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })
    const [hideSelect, setHideSelect] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [payloadStatus, setPayloadStatus] = useState<PayloadStatus>()

    const handleInputChange = (e: any): void => {
        let value = e.target.value.toUpperCase()
        value = value.replace(/[^\w]/gi, '')
        const dashCount = Math.floor((value.length - 1) / 4)

        if (value.length + dashCount > 14) {
            value = value.substr(0, 14 - dashCount)
        }
        value = value.replace(/(.{4})/g, '$1-').trim()

        if ((value as string).endsWith('-')) {
            value = value.slice(0, -1)
        }

        setValue('voucher', value, { shouldValidate: true })
        setVoucher(value)
    }

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors }
    } = useForm<IFormInput>({})

    const closeWindow = (): void => {
        setOpen(false)
        setHideSelect(false)
    }

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    const onChangeHotel = (e: any): void => {
        selectedHotel.current = e.target.value
        setDisabledButton(false)
        const selectedOption = e.target.options[e.target.selectedIndex]
        setHotelName(selectedOption.text)
    }

    const handleApplyVoucher = useCallback((data: IFormInput): void => {
        setLoading(true)
        setVoucher('')
        setDisabledButton(true)
        void dispatch(applyVoucher({
            number: data.voucher,
            hotel_id: selectedHotel.current
        }))
            .then((response: any) => {
                setDisabledButton(false)
                setLoading(false)
                setDataModal({
                    title: t`Voucher aktivovaný`,
                    subtitle: t`Zvolený kód vouchera bol úspešne aktivovaný.`,
                    textButton: t`Zatvoriť`,
                    icon: <IconSuccess />,
                    action: () => { window.location.reload() }
                })
                if (response.error !== undefined) {
                    setLoading(false)
                    setDataModal({
                        title: t`Voucher neaktivovaný`,
                        subtitle: t`Zvolený kód vouchera sa nepodarilo aktivovať. Skúste to prosím znova.`,
                        textButton: t`Späť na overenie`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                }
            })
    }, [selectedHotel])

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        setLoading(true)

        void dispatch(fetchVerifyVoucher(data.voucher))
            .then((response: any) => {
                setLoading(false)
                setOpen(true)
                if (response.payload.status === 'ErrorApplied') {
                    setDataModal({
                        title: t`Voucher uplatnený`,
                        subtitle:
                            <>
                                {`${t`Voucher bol uplatnený dňa`} ${formatDate(response.payload.applied_at_date)}  ${t`v zariadení`} ${response.payload.applied_at_hotel}.`}
                            </>,
                        textButton: t`Späť na overenie`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                } else if (response.payload.status === 'ErrorNotFound') {
                    setDataModal({
                        title: t`Voucher neplatný`,
                        subtitle: t`Zvolený kód vouchera je neplatný. Zadajte iný kód vouchera.`,
                        textButton: t`Späť na overenie`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                } else if (response.payload.status === 'ErrorExpired') {
                    setDataModal({
                        title: t`Voucher expirovaný`,
                        subtitle: t`Zvolený kód vouchera je expirovaný. Zadajte iný kód vouchera.`,
                        textButton: t`Späť na overenie`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                } else if (response.payload.status === 'Success') {
                    if (user?.hotel_id === null) {
                        setDisabledButton(true)
                    }

                    setPayloadStatus({
                        idClient: response.payload.data.client.id,
                        status: response.payload.status,
                        client: response.payload.data.client.name
                    })
                    setDataModal({
                        title: t`Voucher je platný`,
                        subtitle: t`Ste si istý, že chcete zadaný kód voucheru uplatniť? Akcia sa nedá vrátiť späť.`,
                        textButton: t`Uplatniť voucher`,
                        icon: <IconInfo />,
                        action: () => {
                            setHideSelect(true)
                            setDataModal({
                                title: t`Potvrdenie uplatnenia voucheru`,
                                subtitle: t`Kliknite na Uplatniť voucher na definitívne uplatnenie kódu.`,
                                textButton: t`Uplatniť voucher`,
                                icon: <IconInfo />,
                                action: () => { handleApplyVoucher(data) }
                            })
                        }
                    })
                } else {
                    setHideSelect(true)
                    setDataModal({
                        title: t`Chyba servera`,
                        subtitle: t`Nastala chyba servera. Skúste to prosím neskôr.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning />,
                        action: () => { setOpen(false) }
                    })
                }
            })
    }

    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                disabledButton={disabledButton}
                closeWindow={closeWindow}
            >
                {user?.hotel_id === null && payloadStatus?.status === 'Success' &&
                    <>
                        {!hideSelect
                            ? <>
                                <p className='font-basic-black py-2 text-center'>
                                    <Trans>Voucher je platny pre člena</Trans>
                                    <strong className='ml-1'>
                                        <a
                                            target='_blank'
                                            className='underline'
                                            href={`members/${payloadStatus.idClient}`} rel="noreferrer">
                                            {payloadStatus.client}
                                        </a>
                                    </strong>
                                </p>
                                <p className='font-basic-black py-2 text-center'>
                                    <Trans>Vybete prosím hotel pre uplatenie vouchera</Trans>
                                </p>
                                <SelectBox
                                    hotels={hotels}
                                    onChangeHotel={onChangeHotel}
                                />
                            </>
                            : <div>
                                <p className='font-basic-black py-2 text-center'>
                                    <Trans>Voucher bude uplatnený v zariadení:</Trans><strong className='ml-1'>{hotelName}</strong>
                                </p>
                            </div>
                        }
                    </>
                }
            </ModalWindow>
            <div className='absolute lg:top-[136px] z-10 top-[100px] left-1/2 '>
                <Loader loading={loading} />
            </div><div className='bg-light-gray shadow-m-shadow rounded-[10px]'>
                <div className='text-center py-[26px] lg-max:px-[25px]'>
                    <h4 className='uppercase md:text-base text-11 text-main-brown font-montserrat-bold pb-15 tracking-1.6'>
                        <Trans>overenie kódu vouchera</Trans>
                    </h4>
                    <p className='md:text-11 text-[10px] text-main-brown pb-15'>
                        <Trans>Zadajte kód vouchera</Trans>
                    </p>
                    <form className="sm:w-[280px]  w-full mx-auto" onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="voucher" className=' relative'>
                            <span className='voucher-icon'>
                                <IconVoucher />
                            </span>
                            <input
                                {...register('voucher', {
                                    required: {
                                        value: true,
                                        message: t`Pole je povinné`
                                    }
                                })}
                                name="voucher"
                                placeholder='ER2R-24TC-34FB'
                                type='text'
                                className='input-form py-[5px] input-focus input-transition input-text-l input-placeholder uppercase text-center tracking-1.6'
                                value={voucher}
                                onChange={handleInputChange} />
                            {(errors.voucher != null) && (
                                <FadeAnimation type="up" key={errors.voucher.message}>
                                    <div className="error-text">
                                        {errors.voucher.message}
                                    </div>
                                </FadeAnimation>
                            )}
                        </label>
                        <button
                            type="submit"
                            className='btn btn-red btn-m  block mx-auto mt-5'>
                            <Trans>Overiť kód</Trans>
                        </button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default FormCodeVerification
