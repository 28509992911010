/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import { IconEmail } from '../../components/icons/IconEmail'
import IconPadLock from '../../components/icons/IconPadLock'
import { IconBuild } from '../../components/icons/IconBuild'
import { IconGroup } from '../../components/icons/IconGroup'
import { Trans } from '@lingui/macro'
import { NavLink } from 'react-router-dom'

interface ProfilItemProps {
    profilUserEmail: string | undefined
    profilUserHotel: string | undefined
    profilUserRole: string | undefined
    profilUserComment: string | undefined
}
const ProfilItemInfo: React.FC<ProfilItemProps> = (props: ProfilItemProps) => {
    const { profilUserEmail, profilUserHotel, profilUserRole, profilUserComment } = props

    return (
        <>
            <div className='mt-[22px] flex gap-10 flex-wrap'>
                <div className='flex items-start'>
                    <div className='relative top-[4px]'>
                        <IconEmail />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Email</Trans></p>
                        <p className='font-basic-black'>{profilUserEmail}</p>
                    </div>
                </div>
                <div className='flex items-baseline'>
                    <div className='relative top-[2px]'>
                        <IconPadLock />
                    </div>
                    <div className='ml-2 link-hover link-red'>
                        <p className='font-basic font-montserrat-bold '><Trans>Heslo</Trans></p>
                        <p className='font-basic-black'>************</p>
                        <NavLink
                            to={'/changepassword'}
                            className={'text-[9px] text-red flex'}
                        >
                            <Trans>Zmeniť heslo</Trans>
                        </NavLink>
                    </div>
                </div>
                <div className='flex items-baseline'>
                    <div className='relative top-[3px]'>
                        <IconBuild />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Zariadenie</Trans></p>
                        <p className='font-basic-black'>{profilUserHotel}</p>
                    </div>
                </div>
                <div className='flex items-baseline'>
                    <div className='relative top-[3px]'>
                        <IconGroup />
                    </div>
                    <div className='ml-2'>
                        <p className='font-basic font-montserrat-bold '><Trans>Rola</Trans></p>
                        <p className='font-basic-black'>{profilUserRole}</p>
                    </div>
                </div>
            </div>
            {profilUserComment !== null &&
                <div className='pt-10'>
                    <div className='sm:w-1/2 w-full'>
                        <p className='font-basic font-montserrat-bold '><Trans>Poznámka</Trans></p>
                        <p className='font-basic-black'>{profilUserComment}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default ProfilItemInfo
