import React from 'react'

export const IconTrash: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="11.333" height="11.94" viewBox="0 0 11.333 11.94">
            <g id="Solid" transform="translate(-2 -1.5)">
                <path id="Path_37" data-name="Path 37" d="M10.107,2.714h4.048a.607.607,0,0,0,0-1.214H10.107a.607.607,0,0,0,0,1.214Z" transform="translate(-4.464)" fill="#b14242" fillRule="evenodd" />
                <path id="Path_38" data-name="Path 38" d="M3.214,7.714v6.679a2.024,2.024,0,0,0,2.024,2.024H10.1a2.024,2.024,0,0,0,2.024-2.024V7.714h.666a.61.61,0,0,0,0-1.214H2.548a.61.61,0,0,0,0,1.214ZM6.048,9.131v4.857a.4.4,0,0,0,.81,0V9.131a.4.4,0,1,0-.81,0Zm2.429,0v4.857a.4.4,0,0,0,.81,0V9.131a.4.4,0,1,0-.81,0Z" transform="translate(0 -2.976)" fill="#b14242" fillRule="evenodd" />
            </g>
        </svg>
    )
}
