import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch, type RootState } from '../../redux/store'
import PageLogin from '../../pages/PageLogin'
import { Outlet, useNavigate } from 'react-router-dom'
import Header from '../header/Header'
import SideBar from '../global/SideBar'
import Languages from '../global/Languages'
import axios from 'axios'
import { logout } from '../../redux/thunks/loginThunks'

interface PrivateRouteProps {
    path?: string
}

const PrivateRoute: React.FC<PrivateRouteProps> = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()
    const loginUser = useSelector((state: RootState) => state.login.user)
    const [toggleMenu, setToggleMenu] = useState(true)

    const handleToggleMenu = (): void => {
        setToggleMenu(!toggleMenu)
    }

    axios.interceptors.response.use(
        (response: any) => response,
        async (error: { response: { status: number } }) => {
            if ((Boolean(error.response)) && error.response.status === 401) {
                void dispatch(logout({
                    email: '',
                    password: ''
                })).then(() => {
                    navigate('/', { replace: true })
                })
            }
            return await Promise.reject(error)
        }
    )

    if (loginUser !== null) {
        return (
            <div >
                <Header handleToggleMenu={handleToggleMenu} toggleMenu={toggleMenu}/>
                <div className='lg-max:px-[30px] '>
                    <div className="container bg-white lg:rounded-20 flex  lg:shadow-lg-shadow relative rounded-[10px]">
                        <div className={`left-sidebar ${toggleMenu ? '' : 'show '}`}>
                            <SideBar handleToggleMenu={handleToggleMenu} />
                        </div>
                        <div className='w-full flex flex-col relative custom-height'>
                            <Outlet />
                        </div>
                    </div>
                </div>
                <Languages/>
            </div>
        )
    }

    return <PageLogin />
}

export default PrivateRoute
