/* eslint-disable lingui/no-unlocalized-strings */
import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

interface SelectPageProps {
    textSelectPage: string
    handlePerPageChange: (number: any) => void
    itemsPerPage: number
}

function classNames (...classes: any): string {
    return classes.filter(Boolean).join(' ')
}
const SelectPage: React.FC<SelectPageProps> = (props: SelectPageProps) => {
    const { textSelectPage, handlePerPageChange, itemsPerPage } = props
    const [selected, setSelected] = useState(itemsPerPage)

    const handleSelectChange = (selected: any): void => {
        setSelected(selected)
        handlePerPageChange(selected)
    }

    return (
        <Listbox value={selected} onChange={handleSelectChange}>
            {({ open }) => (
                <div className='flex items-baseline'>
                    <Listbox.Label className="font-basic">
                        {textSelectPage}
                    </Listbox.Label>
                    <div className="relative">
                        <Listbox.Button className="relative w-full cursor-pointer pl-1 pr-8 text-left text-11 font-montserrat-bold focus:outline-none">
                            <span className="ml-1 block truncate font-basic font-montserrat-bold ">{selected}</span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 ">
                                <ChevronDownIcon
                                    className={classNames(
                                        open ? 'rotate-[180deg] ' : '',
                                        'h-4 w-4 text-main-brown'
                                    )}
                                    aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white rin  text-11 shadow-lg divide-y divide-main-brown divide-opacity-15 ">
                                <Listbox.Option
                                    value={10}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-light-gray ' : '',
                                            'cursor-default  py-1 pl-3 pr-9'
                                        )
                                    }
                                >
                                    <div className="flex items-center">
                                        <span className='ml-1 block text-claimed'>
                                            10
                                        </span>
                                    </div>
                                </Listbox.Option>
                                <Listbox.Option
                                    value={50}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-light-gray ' : '',
                                            'cursor-default  py-1 pl-3 pr-9'
                                        )
                                    }
                                >
                                    <div className="flex items-center">
                                        <span className='ml-1 block text-claimed'>
                                            50
                                        </span>
                                    </div>
                                </Listbox.Option>
                                <Listbox.Option
                                    value={100}
                                    className={({ active }) =>
                                        classNames(
                                            active ? 'bg-light-gray ' : '',
                                            'cursor-default  py-1 pl-3 pr-9'
                                        )
                                    }
                                >
                                    <div className="flex items-center">
                                        <span className='ml-1 block text-claimed'>
                                            100
                                        </span>
                                    </div>
                                </Listbox.Option>
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export default SelectPage
