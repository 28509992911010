import React from 'react'

export const IconAddress: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.974" height="13.002" viewBox="0 0 12.974 13.002">
            <g id="location" transform="translate(-31)">
                <g id="Group_49" data-name="Group 49" transform="translate(32.444)">
                    <g id="Group_48" data-name="Group 48">
                        <path id="Path_26" data-name="Path 26" d="M94.97,0a3.968,3.968,0,0,0-3.227,6.279l2.923,4.561a.361.361,0,0,0,.608,0l2.936-4.576A3.97,3.97,0,0,0,94.97,0Zm0,5.774a1.8,1.8,0,1,1,1.8-1.8A1.806,1.806,0,0,1,94.97,5.774Z" transform="translate(-91)" fill="#c6c0b2" />
                    </g>
                </g>
                <g id="Group_51" data-name="Group 51" transform="translate(31 8.293)">
                    <g id="Group_50" data-name="Group 50">
                        <path id="Path_27" data-name="Path 27" d="M39.234,344.7l-1.817,2.841a1.193,1.193,0,0,1-2.008,0l-1.82-2.841c-1.6.37-2.589,1.049-2.589,1.859,0,1.406,2.789,2.165,5.413,2.165s5.413-.759,5.413-2.165C41.826,345.744,40.838,345.065,39.234,344.7Z" transform="translate(-31 -344.695)" fill="#c6c0b2" />
                    </g>
                </g>
            </g>
        </svg>

    )
}
