import React from 'react'
import FormSearch from '../forms/FormSearch'
import IconPlus from '../icons/IconPlus'
import type { HeaderSupPage } from '../../types'

interface HeaderSubPagesProps {
    dataHeader: HeaderSupPage
    onAdd?: () => void
    hideButton?: boolean
    hideInput: boolean
}

const HeaderSubPages: React.FC<HeaderSubPagesProps> = (props: HeaderSubPagesProps) => {
    const { dataHeader, onAdd, hideButton, hideInput } = props

    return (
        <div className='bg-light-gray lg:mt-9 lg:mx-[50px] shadow-m-shadow rounded-[10px]'>
            <div className='text-center py-[26px] pl-7 pr-5 flex m:flex-row flex-col items-center m:space-x-12 m-max:gap-y-3 lg-max:px-[25px]'>
                <div className='flex flex-col items-start m:w-1/6'>
                    <h4 className='md:text-base text-[11px] text-main-brown font-montserrat-bold pb-[5px] tracking-0.8'>
                        {dataHeader.titleHeader}
                    </h4>
                    <p className='md:text-11 text-[10px] text-main-brown'>
                        {dataHeader.countHeader} {dataHeader.textCountHeader}
                    </p>
                </div>
                {
                    (!hideInput) &&
                    <div className='md:w-1/2 w-full'>
                        <FormSearch
                            placeHolderSearchText={dataHeader.placeHolderSearchText}
                        />
                    </div>
                }
                <div className={`${(hideButton === true) ? 'hidden' : ''} w-1/3 m-max:w-full`}>
                    <button
                        className={`btn btn-red py-[5.5px] whitespace-nowrap m-max:mx-auto w-full xs:w-auto px-2 xs:px-7 flex items-center justify-center cursor-pointer ${dataHeader.class}`}
                        onClick={onAdd}
                    >
                        <IconPlus />
                        {dataHeader.textButtonHeader}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default HeaderSubPages
