import React, { useEffect, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import Filter from '../components/global/Filter'
import SelectPage from '../components/global/SelectPage'
import Pagination from '../components/global/Pagination'
import ListEmailLogs from '../components/listItems/ListEmailLogs'
import { useDispatch, useSelector } from 'react-redux'
import { type RootState, type AppDispatch } from '../redux/store'
import { fetchPaginationEmailLogs } from '../redux/thunks/emailLogThunks'
import Loader from '../components/global/Loader'
import HeaderSubPages from '../components/header/HeaderSubPages'
import { selectInputValue } from '../redux/slices/searchInputSlice'
import { type HeaderSupPage } from '../types'

const PageEmailLog: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const emailLogs = useSelector((state: RootState) => state.emailLog.data)
    const totalEmailLogs = useSelector((state: RootState) => state.emailLog.total)
    const loading = useSelector((state: RootState) => state.emailLog.loading)
    const lastPage = useSelector((state: RootState) => state.emailLog.lastPage)
    const page = useSelector((state: RootState) => state.emailLog.currentPage)
    const inputValue = useSelector(selectInputValue)

    const [displayedEmailLogs, setDisplayedEmailLogs] = useState(emailLogs)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [emailLogStatus, setEmailLogStatus] = useState('all')
    const textSelectStatus = t`Filtrovať podľa stavu:`
    const textSelectPage = t`Zobraziť počet logov:`
    const emailLogText = (number: number): string => {
        if (number === 1) {
            return t`Log`
        } else if (number > 1 && number < 5) {
            return t`Logy`
        } else {
            return t`Logov`
        }
    }
    const dataHeader: HeaderSupPage = {
        titleHeader: t`Email logy`,
        countHeader: totalEmailLogs,
        textCountHeader: emailLogText(totalEmailLogs),
        textButtonHeader: t`Generovať nový vouchers`,
        placeHolderSearchText: t`Vyhľadať email log`,
        class: 'opacity-100'
    }

    const statusEmailLog = [
        { id: 'all', name: t`Všetky`, color: '' },
        { id: 'success', name: t`Uspšený`, color: 'active' },
        { id: 'unsuccess', name: t`Neuspešný`, color: 'expired' }
    ]

    useEffect(() => {
        setDisplayedEmailLogs(emailLogs)
    }, [emailLogs])

    useEffect(() => {
        if (inputValue !== '') {
            setCurrentPage(1)
        }
    }, [inputValue])

    useEffect(() => {
        void dispatch(fetchPaginationEmailLogs({ page: currentPage, perPage: itemsPerPage, status: emailLogStatus, keyword: inputValue }))
    }, [currentPage, itemsPerPage, emailLogStatus, inputValue])

    const handleNextPage = (): void => {
        setCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
    }

    const handleBackPage = (): void => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
    }

    const handleEmailLogStatus = (status: any): void => {
        setEmailLogStatus(status)
        setCurrentPage(1)
    }

    const handlePerPageChange = (selectNumber: any): void => {
        setItemsPerPage(selectNumber)
        setCurrentPage(1)
    }

    return (
        <>
            <HeaderSubPages
                dataHeader={dataHeader}
                hideButton= {true}
                hideInput = {false}
            />
            {loading
                ? <div className='flex justify-center items-center mt-10 flex-col'>
                    <Loader />
                    <p className='font-basic-black'>
                        <Trans>Načítavam data...</Trans>
                    </p>
                </div>
                : <>
                    <div className='flex items-baseline lg:mx-[50px] pl-7 pr-5 justify-between mt-7 mb-4 lg-max:px-[25px] md:flex-nowrap flex-wrap md:gap-y-0 gap-y-4'>
                        <div className='md:flex md:w-3/4 justify-between'>
                            <Filter
                                textSelectStatus={textSelectStatus}
                                handleVoucherStatus={handleEmailLogStatus}
                                options={statusEmailLog}
                                status={emailLogStatus}
                            />
                            <SelectPage
                                textSelectPage={textSelectPage}
                                handlePerPageChange={handlePerPageChange}
                                itemsPerPage={itemsPerPage} />
                        </div>
                        {totalEmailLogs > 10 && lastPage > 1 && (
                            <div className='md:flex md:w-1/4 justify-end'>
                                <Pagination
                                    handleNextPage={handleNextPage}
                                    handleBackPage={handleBackPage}
                                    totalPages={lastPage}
                                    currentPage={page} />
                            </div>
                        )}
                    </div>
                    <div className={`${itemsPerPage >= 50 ? '' : 'h-full'}`}>
                        {
                            displayedEmailLogs.length > 0
                                ? <ListEmailLogs listLogs={displayedEmailLogs} />
                                : <div className='flex justify-center mt-12'>
                                    <p className="text-base text-main-brown">
                                        <Trans>Nenašili sa žiadne dáta</Trans>
                                    </p>
                                </div>
                        }
                    </div>
                    { totalEmailLogs > 10 && lastPage > 1 && (
                        <div className='flex items-center lg:mx-[50px] pl-7 pr-5 justify-end mb-6'>
                            <Pagination
                                handleNextPage={handleNextPage}
                                handleBackPage={handleBackPage}
                                totalPages={lastPage}
                                currentPage={page}
                            />
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default PageEmailLog
