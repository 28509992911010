/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useMemo, useState } from 'react'
import { type AppDispatch, type RootState } from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate, Link } from 'react-router-dom'
import DetailComponent from './DetailComponent'
import { t } from '@lingui/macro'
import { IconEmail } from '../icons/IconEmail'
import { deleteUser, restoreUser } from '../../redux/thunks/userThunks'
import FormAddNewUser from '../forms/FormAddNewUser'
import ModalWindowForm from '../global/ModalWindowForm'
// import { selectUserSystemLog } from '../../redux/slices/systemLogSlice'
import { fetchUserSystemLog } from '../../redux/thunks/systemLogThunks'
import { fetchAllHotels } from '../../redux/thunks/hotelThunks'
import ModalWindow from '../global/ModalWindow'
import IconWarning from '../icons/IconWarning'
import { IconBuild } from '../icons/IconBuild'
import { resetUpdateSuccess } from '../../redux/slices/userSlice'
import { type User, type ModalWindowData } from '../../types'
import axios from 'axios'

const DetailComponentHotel: React.FC = () => {
    const hotels = useSelector((state: RootState) => state.hotel.data)
    const updateSuccess = useSelector((state: RootState) => state.user.updateSuccess)
    const existEmail = useSelector((state: RootState) => state.user.existEmail)

    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()
    const systemLog = useSelector((state: RootState) => state.systemLog.userData)

    const [updateForm, setUpdateForm] = useState(false)
    const [openModalForm, setOpenModalForm] = useState(false)
    const [open, setOpen] = useState(false)
    const [oneUserDetail, setOneUserDetail] = useState<User>()
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })

    useEffect(() => {
        if (!updateSuccess) {
            setOpen(true)
            if (existEmail === 'exist_email') {
                setDataModal(
                    {
                        title: t`Chyba`,
                        subtitle: t`Zadaný email už existuje v systéme.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: () => { setOpen(false) }
                    }
                )
            } else {
                setDataModal(
                    {
                        title: t`Chyba`,
                        subtitle: t`Nastala chyba pri ukladaní údajov`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: () => { setOpen(false) }
                    }
                )
            }
        }
    }, [updateSuccess])

    useEffect(() => {
        dispatch(resetUpdateSuccess())
    }, [dispatch])

    const { id } = useParams<{ 'id': string | undefined }>()
    const numericId = id !== undefined ? parseInt(id) : undefined

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response = await axios.get(`/api/users/${numericId}`)
                setOneUserDetail(response.data.data)
            } catch (error) {
            }
        }
        void fetchData()
    }, [numericId])

    const titleModalForm = t`Upraviť údaje`
    const subtitleModalForm = t`Vyplňte údaje`
    const buttonIcon = 'user-edit.svg'
    const textButtonDetail = t`Upraviť údaje`
    const textTileDetail = t`Informácie o administrátorovi`
    const textDeleteButton = oneUserDetail?.deleted_at === null
        ? t`Deaktivovať administrátora`
        : t`Aktivovať administrátora`
    const navigatePath = '/administrators'
    const showButtonPassword = true
    const deleteTitle = oneUserDetail?.deleted_at === null
        ? t`Deaktivovať administrátora`
        : t`Aktivovať administrátora`
    const deleteText = oneUserDetail?.deleted_at === null
        ? t`Ste si istý, že chcete deaktivovať tohto administrátora? Akcia sa nedá vrátiť späť.`
        : t`Ste si istý, že chcete aktivovať tohto administrátora? Akcia sa nedá vrátiť späť.`

    const closeWindow = (): void => {
        setOpen(false)
    }

    const hotelDetail = useMemo(() => {
        const foundHotel = hotels.find((hotel) => hotel.id === oneUserDetail?.hotel_id)
        if (foundHotel) {
            return {
                name: foundHotel.name,
                deletedAt: foundHotel.deleted_at
            }
        } else {
            return {
                name: t`Nie je žiaden záznam`,
                deletedAt: null
            }
        }
    }, [hotels, oneUserDetail?.hotel_id])

    const itemId = oneUserDetail?.id ?? 0

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    useEffect(() => {
        void dispatch(fetchUserSystemLog(itemId))
    }, [itemId])

    const toggleDeleteRestore = async (): Promise<boolean> => {
        return await new Promise((resolve) => {
            if (numericId === undefined) {
                resolve(false)
                return
            }

            if (oneUserDetail?.deleted_at === null) {
                // Delete
                void dispatch(deleteUser(numericId))
                    .then(() => {
                        navigate(navigatePath)
                        resolve(true)
                    })
            } else {
                // Restore
                void dispatch(restoreUser(numericId))
                    .then(() => {
                        navigate(navigatePath)
                        resolve(true)
                    })
            }
        })
    }

    const detail = useMemo(() => {
        if (oneUserDetail !== undefined) {
            return {
                id: oneUserDetail.id,
                title: oneUserDetail.name,
                subtitle: '',
                image: oneUserDetail.image_path,
                comment: oneUserDetail.comment,
                email: oneUserDetail.email,
                sex: oneUserDetail.sex,
                deleted_at: oneUserDetail.deleted_at,
                website: '',
                address: {
                    title: '',
                    icon: undefined,
                    street: '',
                    city: '',
                    postCode: '',
                    state: ''
                },
                items: [
                    {
                        title: t`Prihlasovací email`,
                        text: <Link to={`mailto:${oneUserDetail.email}`}>{oneUserDetail.email}</Link>,
                        icon: <IconEmail/>,
                        deleteItem: null
                    },
                    {
                        title: t`Zariadenie`,
                        text: hotelDetail.name,
                        icon: <IconBuild/>,
                        deleteItem: hotelDetail.deletedAt
                    }
                ]
            }
        }
    }, [oneUserDetail])

    const handleOpenModalForm = (): void => {
        setOpenModalForm(true)
        setUpdateForm(true)
    }

    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                closeWindow={closeWindow}
            />
            <ModalWindowForm
                open={openModalForm}
                setOpen={setOpenModalForm}
                titleModalForm={titleModalForm}
                subtitleModalForm={subtitleModalForm}
                myFormComponent={
                    <FormAddNewUser
                        itemId={itemId}
                        updateForm={updateForm}
                    />
                } />
            <DetailComponent
                oneItemDetail={detail}
                textButtonDetail={textButtonDetail}
                textTileDetail={textTileDetail}
                textDeleteButton={textDeleteButton}
                navigatePath={navigatePath}
                toggleDeleteRestore={toggleDeleteRestore}
                showButtonPassword={showButtonPassword}
                buttonIcon={buttonIcon}
                handleOpenModalForm={handleOpenModalForm}
                systemLog={systemLog}
                deleteTitle={deleteTitle}
                deleteText={deleteText}
            />
        </>
    )
}

export default DetailComponentHotel
