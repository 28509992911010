/* eslint-disable lingui/no-unlocalized-strings */
import React, { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import type { ModalWindowData } from '../../types'
import { XMarkIcon } from '@heroicons/react/20/solid'
import Loader from './Loader'
interface ModalWindowProps {
    open: boolean
    setOpen: (val: boolean) => void
    dataModalWindow: ModalWindowData
    disabledCrossIcon?: boolean
    disabledButton?: boolean
    loading?: boolean
    children?: React.ReactNode
    closeWindow?: () => void
}
const ModalWindow: React.FC<ModalWindowProps> = (props: ModalWindowProps) => {
    const { open, setOpen, dataModalWindow, disabledCrossIcon, disabledButton, loading, children, closeWindow } = props

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-light-black bg-opacity-20  transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-20 bg-white px-[60px] py-[60px]  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[400px] ">
                                <div className={(disabledCrossIcon === true) ? 'hidden' : 'absolute right-0 top-0  pr-4 pt-4 sm:block'}>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-main-brown transform transition duration-500 hover:scale-[1.8]"
                                        onClick={closeWindow}
                                    >
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div>
                                    <div className="mx-auto flex items-center justify-center">
                                        {dataModalWindow.icon}
                                    </div>
                                    <div className="mt-[18px] text-center">
                                        <Dialog.Title as="h3" className="text-baseleading-5 tracking-0.8 font-montserrat-bold text-main-brown">
                                            {dataModalWindow.title}
                                        </Dialog.Title>
                                        <div className="mt-[14px]">
                                            <p className="font-basic-black">
                                                {dataModalWindow.subtitle}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {children}
                                <div className="mt-5 flex space-x-[10px] justify-center mb-">
                                    <button
                                        disabled={disabledButton}
                                        type="button"
                                        className=" btn btn-brown btn-xs  w-1/2"
                                        onClick={dataModalWindow.action}
                                        ref={cancelButtonRef}
                                    >
                                        {dataModalWindow.textButton}
                                    </button>
                                </div>
                                {(loading === true) &&
                                <div className='absolute bottom-2 left-[45%]'>
                                    <Loader />
                                </div>
                                }
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default ModalWindow
