import React from 'react'

const IconError: React.FC = () => {
    return (

        <svg height="32" viewBox="0 0 32 32" width="32" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg" ><g><g id="Incorrect_Symbol_1_"><g id="Incorrect_Symbol"><circle cx="16" cy="16" id="BG" r="16" fill="#FF3939"/><polygon id="Cross_x5F_Symbol" points="24,10.1 21.9,8 16,13.9 10.1,8 8,10.1 13.9,16 8,21.9 10.1,24 16,18.1 21.9,24 24,21.9 18.1,16 " fill="#FFFFFF"/></g></g></g>
        </svg>
    )
}

export default IconError
