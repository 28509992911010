/* eslint-disable lingui/no-unlocalized-strings */
import { createSlice } from '@reduxjs/toolkit'
import { type RootState } from '../store' // Import the RootState type
import * as systemLogThunks from '../thunks/systemLogThunks'

export interface SystemLog {
    id: number
    action: string
    date_time: string
    entity_id: number
    entity_name: string
    client_name: string
    user_id: number
    payload: string
    name: string
    user: {
        name: string
    }
    voucher: string
}

interface SystemLogState {
    data: SystemLog[]
    voucherData: SystemLog[]
    clientData: SystemLog[]
    userData: SystemLog[]
    hotelData: SystemLog[]
    loading: boolean
    error: string | null

}

const initialState: SystemLogState = {
    data: [],
    voucherData: [],
    clientData: [],
    userData: [],
    hotelData: [],
    loading: false,
    error: null
}

const SystemLogSlice = createSlice({
    name: 'systemLog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(systemLogThunks.fetchUserSystemLog.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(systemLogThunks.fetchUserSystemLog.fulfilled, (state, action) => {
                state.loading = false
                const logs = action.payload.map((log) => {
                    const parsedPayload = JSON.parse(log.payload)
                    return {
                        ...log,
                        payload: parsedPayload,
                        name: parsedPayload.name ?? '',
                        registration_number: parsedPayload.registration_number ?? '',
                        user_name: ((log.user?.name) !== null) || ' '
                    }
                })
                state.userData = logs
            })
            .addCase(systemLogThunks.fetchUserSystemLog.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch logs'
            })
            .addCase(systemLogThunks.fetchHotelSystemLog.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(systemLogThunks.fetchHotelSystemLog.fulfilled, (state, action) => {
                state.loading = false
                const logs = action.payload.map((log) => {
                    const parsedPayload = JSON.parse(log.payload)
                    return {
                        ...log,
                        payload: parsedPayload,
                        name: parsedPayload.name ?? '',
                        user_name: log.user ?? ''
                    }
                })
                state.hotelData = logs
            })
            .addCase(systemLogThunks.fetchHotelSystemLog.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch logs'
            })
            .addCase(systemLogThunks.fetchClientSystemLog.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(systemLogThunks.fetchClientSystemLog.fulfilled, (state, action) => {
                state.loading = false
                const logs = action.payload.map((log) => {
                    const parsedPayload = JSON.parse(log.payload)
                    return {
                        ...log,
                        payload: parsedPayload,
                        name: parsedPayload.name ?? parsedPayload.client.name ?? '',
                        user_name: log.user?.name ?? null,
                        voucher: parsedPayload.voucher_number ?? null

                    }
                })
                state.clientData = logs
            })
            .addCase(systemLogThunks.fetchClientSystemLog.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch logs'
            })
            .addCase(systemLogThunks.fetchVocherSystemLog.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(systemLogThunks.fetchVocherSystemLog.fulfilled, (state, action) => {
                state.loading = false
                const logs = action.payload.map((log) => {
                    const parsedPayload = JSON.parse(log.payload)
                    return {
                        ...log,
                        payload: parsedPayload,
                        name: parsedPayload.name ?? '',
                        user_name: log.user?.name ?? null,
                        voucher: parsedPayload.number ?? parsedPayload.voucher_number ?? '',
                        client_name: parsedPayload?.client?.name ?? parsedPayload.name ?? ''
                    }
                })
                state.voucherData = logs
            })
            .addCase(systemLogThunks.fetchVocherSystemLog.rejected, (state, action) => {
                state.loading = false
                state.error = action.error.message ?? 'Failed to fetch logs'
            })
    }
})

export const selectSystemLog = (state: RootState): SystemLog[] => state.systemLog.data
export const selectVoucherSystemLog = (state: RootState): SystemLog[] => state.systemLog.voucherData
export const selectUserSystemLog = (state: RootState): SystemLog[] => state.systemLog.userData
export const selectClientSystemLog = (state: RootState): SystemLog[] => state.systemLog.clientData
export const selectHotelSystemLog = (state: RootState): SystemLog[] => state.systemLog.hotelData
export const selectSystemLogLoading = (state: RootState): boolean => state.systemLog.loading
export const selectystemLogError = (state: RootState): string | null => state.systemLog.error

export default SystemLogSlice.reducer
