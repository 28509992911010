/* eslint-disable lingui/no-unlocalized-strings */
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { i18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { messages as skMessages } from './locales/sk/messages'
import { messages as csMessages } from './locales/cs/messages'
import { messages as enMessages } from './locales/en/messages'
import axios from 'axios'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { persistStore } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'

i18n.load({
    sk: skMessages,
    cs: csMessages,
    en: enMessages
})
i18n.activate('sk')

const persistor = persistStore(store)

export async function setupAxios(): Promise<void> {
    axios.defaults.withCredentials = true
    const response = await axios.get('/sanctum/csrf-cookie')
    console.log('csrf response: ', response)
}

void setupAxios()

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    // <React.StrictMode>
    <I18nProvider i18n={i18n}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </I18nProvider>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
