import React, { useEffect, useMemo, useState } from 'react'
import { type AppDispatch, type RootState } from '../../redux/store'
import { useParams, useNavigate, Link } from 'react-router-dom'
import DetailComponent from './DetailComponent'
import { t } from '@lingui/macro'
import { IconEmail } from '../icons/IconEmail'
import { IconPhone } from '../icons/IconPhone'
import { IconWeb } from '../icons/IconWeb'
import { IconBuild } from '../icons/IconBuild'
import { useDispatch, useSelector } from 'react-redux'
import { deleteHotel, restoreHotel } from '../../redux/thunks/hotelThunks'
import FormAddNewDevice from '../forms/FormAddNewDevice'
import ModalWindowForm from '../global/ModalWindowForm'
import { fetchHotelSystemLog } from '../../redux/thunks/systemLogThunks'
import { selectHotelSystemLog } from '../../redux/slices/systemLogSlice'
import ModalWindow from '../global/ModalWindow'
import IconWarning from '../icons/IconWarning'
import { resetUpdateSuccess } from '../../redux/slices/hotelSlice'
import { type Hotel, type ModalWindowData } from '../../types'
import axios from 'axios'
import { setHttp } from '../../helpers'

const DetailComponentHotel: React.FC = () => {
    const updateSuccess = useSelector((state: RootState) => state.hotel.updateSuccess)
    const existEmail = useSelector((state: RootState) => state.hotel.existEmail)
    const systemLog = useSelector(selectHotelSystemLog)
    const dispatch = useDispatch<AppDispatch>()
    const navigate = useNavigate()

    const [openModalForm, setOpenModalForm] = useState(false)
    const [open, setOpen] = useState(false)
    const [updateForm, setUpdateForm] = useState(false)
    const [oneHotelDetail, setOneHotelDetail] = useState<Hotel>()
    const [dataModal, setDataModal] = useState<ModalWindowData>({ title: '', subtitle: '', textButton: '', icon: <></> })

    const { id } = useParams<{ 'id': string | undefined }>()
    const numericId = id !== undefined ? parseInt(id) : undefined

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            try {
                const response = await axios.get(`/api/hotels/${numericId}`)
                setOneHotelDetail(response.data.data)
            } catch (error) {
            }
        }
        void fetchData()
    }, [numericId])

    const itemId = oneHotelDetail?.id ?? 0

    useEffect(() => {
        if (!updateSuccess) {
            setOpen(true)
            if (existEmail === 'exist_email') {
                setDataModal(
                    {
                        title: t`Chyba`,
                        subtitle: t`Zadaný email už existuje v systéme.`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: () => { setOpen(false) }
                    }
                )
            } else {
                setDataModal(
                    {
                        title: t`Chyba`,
                        subtitle: t`Nastala chyba pri ukladaní údajov`,
                        textButton: t`Zatvoriť`,
                        icon: <IconWarning/>,
                        action: () => { setOpen(false) }
                    }
                )
            }
        }
    }, [updateSuccess])

    useEffect(() => {
        dispatch(resetUpdateSuccess())
    }, [dispatch])

    const closeWindow = (): void => {
        setOpen(false)
    }

    const textButtonDetail = t`Upraviť údaje`
    const textTileDetail = t`Informácie o ubytovacom zariadení`
    const textDeleteButton = oneHotelDetail?.deleted_at === null
        ? t`Deaktivovať zariadenie`
        : t`Aktivovať zariadenie`
    const navigatePath = '/facilities'

    const titleModalForm = t`Upraviť údaje`
    const subtitleModalForm = t`Vyplňte údaje`
    const buttonIcon = 'house.svg'
    const showButtonPassword = false
    const hideTitle = true

    const deleteTitle = oneHotelDetail?.deleted_at === null
        ? t`Deaktivovať zariadenie`
        : t`Aktivovať zariadenie`
    const deleteText = oneHotelDetail?.deleted_at === null
        ? t`Ste si istý, že chcete deaktivovať toto zariadenie? Akcia sa nedá vrátiť späť.`
        : t`Ste si istý, že chcete aktivovať toto zariadenie? Akcia sa nedá vrátiť späť.`

    useEffect(() => {
        void dispatch(fetchHotelSystemLog(itemId))
    }, [itemId])

    const toggleDeleteRestore = async (): Promise<boolean> => {
        return await new Promise((resolve) => {
            if (numericId === undefined) {
                resolve(false)
                return
            }

            if (oneHotelDetail?.deleted_at === null) {
                // Delete
                void dispatch(deleteHotel(numericId))
                    .then(() => {
                        navigate(navigatePath)
                        resolve(true)
                    })
            } else {
                // Restore
                void dispatch(restoreHotel(numericId))
                    .then(() => {
                        navigate(navigatePath)
                        resolve(true)
                    })
            }
        })
    }

    const detail = useMemo(() => {
        if (oneHotelDetail !== undefined) {
            return {
                id: oneHotelDetail.id,
                title: oneHotelDetail.name,
                website: oneHotelDetail.web,
                subtitle: oneHotelDetail.address.city,
                image: oneHotelDetail.image_path,
                comment: oneHotelDetail.comment,
                email: '',
                sex: 'hotel',
                deleted_at: oneHotelDetail.deleted_at,
                address: {
                    title: t`Adresa`,
                    icon: <IconBuild/>,
                    street: oneHotelDetail.address.street,
                    city: oneHotelDetail.address.city,
                    postCode: oneHotelDetail.address.postcode,
                    state: oneHotelDetail.address.state
                },
                items: [
                    {
                        title: t`Email`,
                        text: <Link to={`mailto:${oneHotelDetail.email}`}>{oneHotelDetail.email}</Link>,
                        icon: <IconEmail/>,
                        deleteItem: null
                    },
                    {
                        title: t`Telefón`,
                        text: oneHotelDetail.phone_number,
                        icon: <IconPhone/>,
                        deleteItem: null
                    },
                    {
                        title: oneHotelDetail.web === null ? '' : t`Web`,
                        text: <a href={`${setHttp(oneHotelDetail.web)}`} target='_blank' rel="noreferrer">{oneHotelDetail.web}</a>,
                        icon: <IconWeb/>,
                        deleteItem: null
                    }
                ]
            }
        }
    }, [oneHotelDetail])

    const handleOpenModalForm = (): void => {
        setOpenModalForm(true)
        setUpdateForm(true)
    }

    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen}
                dataModalWindow={dataModal}
                closeWindow={closeWindow}
            />
            <ModalWindowForm
                open={openModalForm}
                setOpen={setOpenModalForm}
                titleModalForm={titleModalForm}
                subtitleModalForm={subtitleModalForm}
                myFormComponent={<FormAddNewDevice
                    itemId={itemId}
                    updateForm={updateForm}
                />} />
            <DetailComponent
                oneItemDetail={detail}
                textButtonDetail={textButtonDetail}
                textTileDetail={textTileDetail}
                hideTitle={hideTitle}
                textDeleteButton={textDeleteButton}
                navigatePath={navigatePath}
                toggleDeleteRestore={toggleDeleteRestore}
                showButtonPassword={showButtonPassword}
                deleteTitle={deleteTitle}
                deleteText={deleteText}
                buttonIcon={buttonIcon}
                handleOpenModalForm={handleOpenModalForm}
                systemLog={systemLog}
            />
        </>
    )
}

export default DetailComponentHotel
