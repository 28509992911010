import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { i18n } from '@lingui/core'
interface LanguageState {
    lang: string
}

const initialState: LanguageState = {
    lang: 'en'
}

export const langSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setLanguage: (state, action: PayloadAction<string>) => {
            i18n.activate(action.payload)
            state.lang = action.payload
        }
    }
})

export const { setLanguage } = langSlice.actions
export default langSlice.reducer
