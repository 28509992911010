/* eslint-disable array-callback-return */
/* eslint-disable lingui/no-unlocalized-strings */
import React, { useEffect, useRef, useState } from 'react'
import HeaderSubPages from '../components/header/HeaderSubPages'
import ListItems from '../components/listItems/ListItems'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { Trans, t } from '@lingui/macro'
import { type HeaderSupPage } from '../types'
import { useSelector, useDispatch } from 'react-redux'
import { type AppDispatch, type RootState } from '../redux/store'
import Pagination from '../components/global/Pagination'
import SelectPage from '../components/global/SelectPage'
import { fetchPaginationUsers } from '../redux/thunks/userThunks'
import { selectInputValue } from '../redux/slices/searchInputSlice'
import Loader from '../components/global/Loader'
import { useIsAdmin } from '../helpers'

const PageUsers: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const users = useSelector((state: RootState) => state.user.data)
    const totalUsers = useSelector((state: RootState) => state.user.total)
    const isAdmin = useIsAdmin()
    const lastPage = useSelector((state: RootState) => state.user.lastPage)
    const page = useSelector((state: RootState) => state.user.currentPage)
    const loading: boolean = useSelector((state: RootState) => state.user.loading)
    const inputValue = useSelector(selectInputValue)
    const navigate = useNavigate()

    const [displayedUsers, setDisplayedUsers] = useState(users)
    const [currentPage, setCurrentPage] = useState(1)
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [detailOpen, setDetailOpen] = useState(false)
    const topItemRef = useRef<HTMLDivElement>(null)

    const userText = (number: number): string => {
        if (number === 1) {
            return t`Administrátor`
        } else if (number > 1 && number < 5) {
            return t`Administrátori`
        } else {
            return t`Administrátorov`
        }
    }

    const location = useLocation()
    const locationUrl = location.pathname
    const type = 'user'
    const dataHeader: HeaderSupPage = {
        titleHeader: t`Administrátori `,
        countHeader: totalUsers,
        textCountHeader: userText(totalUsers),
        textButtonHeader: t`Pridať nového administrátora`,
        placeHolderSearchText: t`Vyhľadať administrátora`,
        class: isAdmin ? 'opacity-100' : 'hidden'
    }

    const textSelectPage = t`Zobraziť počet Administrátov:`

    useEffect(() => {
        setDisplayedUsers(users)
    }, [users, totalUsers])

    useEffect(() => {
        if (inputValue !== '') {
            setCurrentPage(1)
        }
    }, [inputValue])

    useEffect(() => {
        void dispatch(fetchPaginationUsers({ page: currentPage, perPage: itemsPerPage, keyword: inputValue }))
    }, [currentPage, itemsPerPage, inputValue])

    useEffect(() => {
        setDetailOpen(location.pathname === '/administrators')
    }, [location.pathname])

    const handleAdd = (): void => {
        navigate('/addnewadministrator')
    }

    const handleNextPage = (): void => {
        setCurrentPage(currentPage < lastPage ? currentPage + 1 : currentPage)
        setDetailOpen(true)
        navigate('/administrators')
    }

    const handleBackPage = (): void => {
        setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
        setDetailOpen(true)
        navigate('/administrators')
    }

    const handlePerPageChange = (selectNumber: any): void => {
        setItemsPerPage(selectNumber)
        setCurrentPage(1)
    }

    const onScrollToTop = (): void => {
        if (topItemRef.current != null) {
            topItemRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    return (
        <>
            <HeaderSubPages
                dataHeader={dataHeader}
                onAdd={handleAdd}
                hideInput = {false}
            />
            {loading
                ? <div className='flex justify-center items-center mt-5 flex-col'>
                    <Loader/>
                    <p className='font-basic-black'>
                        <Trans>Načítavam data...</Trans>
                    </p>
                </div>
                : <>
                    <div ref={topItemRef} className={`${users.length > 10 ? 'h-auto' : ' 3xl:h-full'} ${detailOpen ? '' : 'flex'}`}>

                        {
                            displayedUsers.length > 0
                                ? <div className={`${detailOpen ? '' : 'md:w-1/2 md:block hidden '}`}>
                                    <div>
                                        <div className={`${detailOpen ? 'lg:mx-[50px] lg-max:px-[25px] pl-7 pr-5' : 'ml-[50px] mr-[10px] pr-1'} flex items-center flex-wrap justify-between mt-7 mb-4  lg-max:gap-y-2`}>
                                            <SelectPage
                                                textSelectPage={textSelectPage}
                                                handlePerPageChange={handlePerPageChange}
                                                itemsPerPage={itemsPerPage}
                                            />
                                            {totalUsers > 10 && lastPage > 1 && (
                                                <Pagination
                                                    handleNextPage = {handleNextPage}
                                                    handleBackPage = {handleBackPage}
                                                    totalPages = {lastPage}
                                                    currentPage ={page}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <ListItems
                                        onScrollToTop={onScrollToTop}
                                        locationUrl={locationUrl}
                                        loading={false}
                                        type={type}
                                        itemList={displayedUsers.map(u => ({
                                            id: u.id,
                                            title: u.name,
                                            imageUrl: u.image_path,
                                            sex: u.sex,
                                            email: u.email,
                                            deleteItem: u.deleted_at
                                        })
                                        )} />
                                </div>
                                : <div className={`flex justify-center mt-12 ${detailOpen ? ' ' : 'w-1/2'}`}>
                                    <p className="text-base text-main-brown">
                                        <Trans>Nenašiel sa žiaden administrátor</Trans>
                                    </p>
                                </div>
                        }
                        <div className={`${detailOpen ? null : 'md:w-1/2 w-full md:border-l-2   border-opacity-15 border-main-brown pt-8 pl-7 pb-9 pr-[50px] flex-col flex'}`}>
                            <Outlet />
                        </div>
                    </div>
                    {displayedUsers.length > 0 && lastPage > 1 && (
                        <div className={` ${detailOpen ? 'block' : 'hidden'}`}>
                            { totalUsers > 10 &&
                            <div className='flex items-center lg:mx-[50px] pl-7 pr-5 justify-end mb-6'>
                                <Pagination
                                    handleNextPage={handleNextPage}
                                    handleBackPage={handleBackPage}
                                    totalPages={lastPage}
                                    currentPage={page}
                                />
                            </div>
                            }
                        </div>
                    )}
                </>
            }
        </>
    )
}

export default PageUsers
