import React from 'react'

export const IconPhone: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12.974" height="13.002" viewBox="0 0 12.974 13.002">
            <g id="phone-call" transform="translate(-0.539)">
                <g id="Group_43" data-name="Group 43" transform="translate(0.539 0)">
                    <path id="Path_23" data-name="Path 23" d="M13.173,9.542,11.359,7.727a1.207,1.207,0,0,0-2.009.454,1.235,1.235,0,0,1-1.426.778,5.6,5.6,0,0,1-3.37-3.37,1.174,1.174,0,0,1,.778-1.426,1.207,1.207,0,0,0,.454-2.009L3.972.34a1.294,1.294,0,0,0-1.75,0L.991,1.571c-1.231,1.3.13,4.73,3.175,7.776s6.48,4.471,7.776,3.175l1.231-1.231A1.294,1.294,0,0,0,13.173,9.542Z" transform="translate(-0.539 0)" fill="#c6c0b2" />
                </g>
            </g>
        </svg>

    )
}
