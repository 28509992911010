/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import IconLog from '../components/icons/IconLog'
import { Trans, t } from '@lingui/macro'
import { ChevronLeftIcon, UserIcon } from '@heroicons/react/20/solid'
import { IconDate } from '../components/icons/IconDate'
import { type SystemLog } from '../redux/slices/systemLogSlice'
import { formatDateTime, getActions, getActionsVoucher } from '../helpers'

import IconWarning from '../components/icons/IconWarning'
import { IconVoucher } from '../components/icons/IconVoucher'

interface PageLogsProps {
    systemLog?: SystemLog[]
    vocherLog?: SystemLog[]
    handleOpenLog?: (value: boolean) => void
}

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const PageLogs: React.FC<PageLogsProps> = (props: PageLogsProps) => {
    const { systemLog, handleOpenLog, vocherLog } = props

    const handleClick = () => {
        (handleOpenLog != null) && handleOpenLog(false)
    }

    const systemLogs = systemLog ?? []
    const vocherLogs = vocherLog ?? []
    const typeEntity = (name: string): any => {
        switch (name) {
            case 'hotels':
                return {
                    name: t`hotela`
                }
            case 'users':
                return {
                    name: t`admimnistátora`
                }
            case 'clients':
                return {
                    name: t`člena`
                }
            default:
                return ''
        }
    }

    return (
        <>
            <div className='xs:mt-12 mt-2 mb-2 '>
                <div className='flex'
                >
                    <IconLog/>
                    <p className='ml-2 font-basic font-montserrat-bold'><Trans>Logy</Trans></p>
                </div>
                <div className={`flow-root mt-6 ${systemLogs.length > 8 ? 'overflow-y-auto h-[450px]' : ''}`}>
                    {systemLogs.length > 0 || vocherLogs.length > 0
                        ? (
                            <ul role="list" className="-mb-8">
                                {systemLogs.map((log, eventIdx) => (
                                    <li key={log.id}>
                                        <div className="relative pb-8">
                                            {vocherLogs.length > 0 || eventIdx !== systemLogs.length - 1
                                                ? (
                                                    <span className="absolute left-[10px] top-4 -ml-px h-full op w-[0.4px] bg-gray bg-opacity-40" aria-hidden="true" />
                                                )
                                                : null}
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    <span
                                                        className={classNames(
                                                            getActions(log.action).iconBackground,
                                                            'h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white'
                                                        )}
                                                    >
                                                        {
                                                            log.action === 'ERROR_SEND_VOUCHER'
                                                                ? <IconWarning/>
                                                                : <UserIcon className="h-3 w-3 text-white" aria-hidden="true" />
                                                        }

                                                    </span>
                                                </div>
                                                <div className="flex flex-col min-w-0 flex-1 justify-between  pt-1.5">
                                                    <div>
                                                        <p className="font-basic font-montserrat-bold">
                                                            {getActions(log.action).text } {typeEntity(log.entity_name).name}:
                                                            <span className='font-basic-black ml-1'>
                                                                {log.name}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className='flex sm:flex-row flex-col mt-1 sm:items-center items-start sm:gap-y-0 gap-y-2 gap-x-2'>
                                                        <div className='flex items-center'>
                                                            <div className='mr-2'>
                                                                <IconDate/>
                                                            </div>
                                                            <p className='font-basic-black'>{formatDateTime(log.date_time)}</p>
                                                        </div>
                                                    </div>
                                                    {log.voucher !== null &&
                                                        <div className='flex sm:flex-row flex-col mt-1 sm:items-center items-start sm:gap-y-0 gap-y-2 gap-x-2'>
                                                            <div className='flex items-center'>
                                                                <div className='mr-2'>
                                                                    <IconVoucher/>
                                                                </div>
                                                                <p className='font-basic-black'>{log.voucher}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {log.user !== null &&
                                                        <div className='mt-2'>
                                                            <p className='font-basic font-montserrat-bold'>
                                                                <Trans>Editoval:</Trans>
                                                                <span className='font-basic-black ml-1'>
                                                                    {log.user?.name}
                                                                </span>
                                                            </p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                {vocherLogs.map((vLog, eventIdx) => (
                                    <li key={vLog.id}>
                                        <div className="relative pb-8">
                                            {eventIdx !== vocherLogs.length - 1
                                                ? (
                                                    <span className="absolute left-[10px] top-4 -ml-px h-full op w-[0.4px] bg-gray bg-opacity-40" aria-hidden="true" />
                                                )
                                                : null}
                                            <div className="relative flex space-x-3">
                                                <div>
                                                    <span
                                                        className={classNames(
                                                            getActionsVoucher(vLog.action).iconBackground,
                                                            'h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white'
                                                        )}
                                                    >
                                                        <UserIcon className="h-3 w-3 text-white" aria-hidden="true" />
                                                    </span>
                                                </div>
                                                <div className="flex flex-col min-w-0 flex-1 justify-between  pt-1.5">
                                                    <div>
                                                        <p className="font-basic font-montserrat-bold">
                                                            {getActionsVoucher(vLog.action).text}
                                                            <span className='font-basic-black ml-1'>
                                                                {vLog.client_name}
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <div className='flex sm:flex-row flex-col mt-1 sm:items-center items-start sm:gap-y-0 gap-y-2 gap-x-2'>
                                                        <div className='flex items-center'>
                                                            <div className='mr-2'>
                                                                <IconDate/>
                                                            </div>
                                                            <p className='font-basic-black'>{formatDateTime(vLog.date_time)}</p>
                                                        </div>
                                                    </div>
                                                    {vLog.voucher !== null &&
                                                        <div className='flex sm:flex-row flex-col mt-1 sm:items-center items-start sm:gap-y-0 gap-y-2 gap-x-2'>
                                                            <div className='flex items-center'>
                                                                <div className='mr-2'>
                                                                    <IconVoucher/>
                                                                </div>
                                                                <p className='font-basic-black'>{vLog.voucher}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className='mt-2'>
                                                        <p className='font-basic font-montserrat-bold'>
                                                            <Trans>Generoval:</Trans>
                                                            <span className='font-basic-black ml-1'>
                                                                {vLog.user?.name ?? t`Automatický systém`}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        )
                        : (
                            <div className='font-basic'><Trans>Nie sú zaznamené žiadne logy</Trans></div>
                        )}
                </div>

            </div>
            <div className='mt-5 w-fit'>
                <div
                    onClick={handleClick}
                    className='btn btn-s btn-gray font-basic font-think flex items-center mt-4 cursor-pointer'>
                    <ChevronLeftIcon
                        className="h-4 w-4 text-main-brown cursor-pointer transform transition duration-500 hover:scale-[1.8]"
                        aria-hidden="true"
                        title={t`Späť na profil člena`}
                    />
                    <Trans>Späť na profil</Trans>
                </div>
            </div>
        </>
    )
}

export default PageLogs
