import { Trans, t } from '@lingui/macro'
import React from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

interface PaginationProps {
    handleNextPage: () => void
    handleBackPage: () => void
    totalPages: number
    currentPage: number

}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
    const { handleNextPage, handleBackPage, totalPages, currentPage } = props
    return (
        <div className='flex items-center'>
            <p className="font-basic">
                <span className="font-medium mr-1">{currentPage}</span>
                <Trans>zo</Trans>
                <span className="font-medium ml-1">{totalPages}</span>
            </p>
            <ChevronLeftIcon
                className="h-4 w-4 text-main-brown cursor-pointer transform transition duration-500 hover:scale-[1.8]"
                aria-hidden="true"
                onClick={handleBackPage}
                title={t`Predchádzajúca strana`}
            />
            <ChevronRightIcon
                className="h-4 w-4 text-main-brown cursor-pointer transform transition duration-500 hover:scale-[1.8]"
                aria-hidden="true"
                onClick={handleNextPage}
                title={t`Ďalšia strana`}
            />
        </div>
    )
}

export default Pagination
