import React from 'react'

const IconPadLock: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="9.508" height="12.677" viewBox="0 0 9.508 12.677" >
            <path id="padlock" d="M11.32,4.754h-.4V3.169a3.169,3.169,0,0,0-6.339,0V4.754h-.4A1.19,1.19,0,0,0,3,5.943v5.546a1.19,1.19,0,0,0,1.189,1.189H11.32a1.19,1.19,0,0,0,1.189-1.189V5.943A1.19,1.19,0,0,0,11.32,4.754ZM5.641,3.169a2.113,2.113,0,0,1,4.226,0V4.754H5.641ZM8.282,8.833v1.2a.528.528,0,0,1-1.056,0v-1.2a1.056,1.056,0,1,1,1.056,0Z" transform="translate(-3)" fill="#c6c0b2" />
        </svg>

    )
}

export default IconPadLock
