import React, { useEffect, useMemo, useState } from 'react'
import { Trans, t } from '@lingui/macro'
import ModalWindow from '../components/global/ModalWindow'
import ProfilHeader from '../components/profilUser/ProfilHeader'
import ProfilItemInfo from '../components/profilUser/ProfilItemInfo'
import { useDispatch, useSelector } from 'react-redux'
import { type AppDispatch, type RootState } from '../redux/store'
import { fetchAllHotels } from '../redux/thunks/hotelThunks'
import ModalWindowForm from '../components/global/ModalWindowForm'
import FormUpdateProfil from '../components/forms/FormUpdateProfil'

const PageProfilUser: React.FC = () => {
    const textDeleteButton = t`Vymazať  člena`
    const [open, setOpen] = useState(false)
    const profilUser = useSelector((state: RootState) => state.login.user)
    const dispatch = useDispatch<AppDispatch>()
    const allHotels = useSelector((state: RootState) => state.hotel.data)
    const [openModalForm, setOpenModalForm] = useState(false)
    const updateForm = true

    const titleModalForm = t`Upraviť údaje`
    const subtitleModalForm = t`Vyplňte údaje`

    useEffect(() => {
        void dispatch(fetchAllHotels())
    }, [dispatch])

    const hotelName = useMemo(() => {
        return allHotels.find((hotel) => {
            return hotel.id !== undefined && hotel.id === profilUser?.hotel_id
        })
    }, [allHotels, profilUser])

    const dataModalWindow =
    {
        title: textDeleteButton,
        subtitle: t`Ste si istý, že chcete vymazať tohto administrátora? Akcia sa nedá vrátiť späť.`,
        textButton: t`Vymazať`
    }

    const handleOpenModalForm = (): void => {
        setOpenModalForm(true)
    }

    return (
        <>
            <ModalWindow
                open={open}
                setOpen={setOpen} dataModalWindow={dataModalWindow}
            />
            <ModalWindowForm
                open={openModalForm}
                setOpen={setOpenModalForm}
                titleModalForm={titleModalForm}
                subtitleModalForm={subtitleModalForm}
                myFormComponent={<FormUpdateProfil
                    profilUser={profilUser}
                    setClose={setOpenModalForm}
                    updateForm={updateForm}
                />}
            />
            <div className=' mt-9 mx-[50px]  mb-10 h-screen'>
                <ProfilHeader
                    profilUserName={profilUser?.name}
                    profilUserRegister={profilUser?.created_at}
                    handleOpenModalForm={handleOpenModalForm}
                    imageUrl={profilUser?.image_path}
                    type={profilUser?.sex}
                />
                <div className='mt-12'>
                    <h4 className='font-basic font-montserrat-bold'>
                        <Trans>Informácie o administrátorovi</Trans>
                    </h4>
                </div>

                <ProfilItemInfo
                    profilUserEmail={profilUser?.email}
                    profilUserHotel={hotelName?.name}
                    profilUserRole={profilUser?.role}
                    profilUserComment={profilUser?.comment}
                />
            </div>
        </>
    )
}

export default PageProfilUser
