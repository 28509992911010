import React, { type ReactNode } from 'react'
// import { Navigate } from 'react-router-dom'
import { useIsAdmin } from '../../helpers'
import ModalWindow from '../global/ModalWindow'
import { t } from '@lingui/macro'
import IconWarning from '../icons/IconWarning'
import { useNavigate } from 'react-router-dom'

interface AdminRouteProps {
    children: ReactNode
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
    const isAdmin = useIsAdmin
    const [open, setOpen] = React.useState(true)
    const navigate = useNavigate()

    const closeWindow = (): void => {
        setOpen(false)
        navigate('/')
    }
    const dataModalWindow =
    {
        title: t`Oprávnenie`,
        subtitle: t`Nemáte oprávnenie na zobrazenie tejto stránky.`,
        textButton: t`Zavrieť`,
        icon: <IconWarning/>,
        action: () => { setOpen(false) }
    }

    if (isAdmin()) {
        return <>{children}</>
    }

    return <ModalWindow
        open={open}
        setOpen={setOpen}
        dataModalWindow={dataModalWindow}
        closeWindow={closeWindow}
    />
}

export default AdminRoute
