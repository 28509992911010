import React from 'react'

const IconWarning: React.FC = () => {
    return (
        <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g id="exclamation">
                <circle id="background" cx="12" cy="12" r="12" fill="#b14242" />
                <g id="Group_52" data-name="Group 52" transform="translate(9.872 5.317)">
                    <path id="Path_39" data-name="Path 39" d="M217.244,124.763a1.645,1.645,0,0,1-1.644-1.579l-.217-5.474a1.645,1.645,0,0,1,1.644-1.71h.434a1.645,1.645,0,0,1,1.644,1.71l-.217,5.474a1.645,1.645,0,0,1-1.644,1.579Z" transform="translate(-215.382 -116)" fill="#fff" />
                    <rect id="Rectangle_28" data-name="Rectangle 28" width="3.284" height="3.284" rx="1.642" transform="translate(0.219 9.549)" fill="#fff" />
                </g>
            </g>
        </svg>
    )
}

export default IconWarning
