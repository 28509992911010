/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable lingui/no-unlocalized-strings */
import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { type Client } from '../../types'
import { type PaginatedResponse, type paginationData } from '../thunks/hotelThunks'

export const fetchPaginationClients = createAsyncThunk(
    'clients/fetchPaginationClients',
    async ({ page, perPage, keyword }: paginationData) => {
        const response = await axios.get<PaginatedResponse<Client>>(`/api/clients?page=${page}&per_page=${perPage}&keyword=${keyword}`)
        return {
            data: response.data.data,
            currentPage: response.data.current_page,
            total: response.data.total,
            lastPage: response.data.last_page
        }
    }
)

export const fetchAllClients = createAsyncThunk(
    'clients/fetchAll',
    async () => {
        const response = await axios.get<Client[]>('/api/clients/all')
        return response.data
    }
)

export const fetchCreateClient = createAsyncThunk(
    'clients/fetchCreateClient',
    async (formData: FormData, { rejectWithValue }) => {
        try {
            const response = await axios.post<Client>('/api/clients', formData)
            return response.data
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }

)
export const fetchUpdateClient = createAsyncThunk(
    'clients/fetchUpdateClient',
    async (data: { formData: FormData, clientId: number }, { rejectWithValue }) => {
        try {
            data.formData.append('_method', 'PUT')
            const response = await axios.post<Client>(`/api/clients/${data.clientId}`, data.formData)
            return {
                id: data.clientId,
                data: response.data
            }
        } catch (error: any) {
            if (error.response) {
                return rejectWithValue(error.response.data)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
)

export const deleteClient = createAsyncThunk(
    'clients/deleteClient',
    async (userId: number) => {
        await axios.delete(`/api/clients/${userId}`)
        return userId
    }
)

export const restoreClient = createAsyncThunk(
    'clients/restoreClient',
    async (userId: number) => {
        await axios.put(`/api/client/restoreClient/${userId}`)
        return userId
    }
)
