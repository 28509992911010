import React from 'react'
import { RotatingLines } from 'react-loader-spinner'

interface LoadingProps {
    loading?: boolean
}

const Loader: React.FC<LoadingProps> = (props: LoadingProps) => {
    const { loading } = props
    return (
        <RotatingLines
            strokeColor="#6B6145"
            strokeWidth="3"
            animationDuration="1"
            width="40"
            visible={loading}
        />
    )
}

export default Loader
