import React from 'react'

export const IconWeb: React.FC = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="11.557" height="11.27" viewBox="0 0 11.557 11.27">
            <g id="web" transform="translate(-1.001 -1.374)">
                <path id="Path_28" data-name="Path 28" d="M14.764,5.082C14.358,3.194,13.491,2,12.689,2S11.02,3.194,10.614,5.082Z" transform="translate(-5.909 -0.385)" fill="#c6c0b2" />
                <path id="Path_29" data-name="Path 29" d="M10,13.538a11.571,11.571,0,0,0,.1,1.543l4.417,0a11.6,11.6,0,0,0,0-3.084L10.1,12a11.563,11.563,0,0,0-.1,1.541Z" transform="translate(-5.532 -6.529)" fill="#c6c0b2" />
                <path id="Path_30" data-name="Path 30" d="M10.614,22c.406,1.888,1.273,3.082,2.075,3.082S14.358,23.888,14.764,22Z" transform="translate(-5.909 -12.679)" fill="#c6c0b2" />
                <path id="Path_31" data-name="Path 31" d="M20.883,4.7h2.427a5.8,5.8,0,0,0-4.026-3.323A6.021,6.021,0,0,1,20.883,4.7Z" transform="translate(-11.24)" fill="#c6c0b2" />
                <path id="Path_32" data-name="Path 32" d="M26.333,12H23.752a12.5,12.5,0,0,1,0,3.083h2.581a5.741,5.741,0,0,0,0-3.082Z" transform="translate(-13.985 -6.532)" fill="#c6c0b2" />
                <path id="Path_33" data-name="Path 33" d="M19.285,25.323A5.8,5.8,0,0,0,23.311,22H20.884A6.027,6.027,0,0,1,19.285,25.323Z" transform="translate(-11.24 -12.679)" fill="#c6c0b2" />
                <path id="Path_34" data-name="Path 34" d="M4.693,22H2.266a5.8,5.8,0,0,0,4.026,3.323A6.016,6.016,0,0,1,4.693,22Z" transform="translate(-0.778 -12.679)" fill="#c6c0b2" />
                <path id="Path_35" data-name="Path 35" d="M6.29,1.374A5.8,5.8,0,0,0,2.264,4.7H4.691a6.027,6.027,0,0,1,1.6-3.323Z" transform="translate(-0.776 0)" fill="#c6c0b2" />
                <path id="Path_36" data-name="Path 36" d="M3.7,13.539A12.462,12.462,0,0,1,3.793,12H1.212a5.741,5.741,0,0,0,0,3.082H3.793a12.457,12.457,0,0,1-.1-1.541Z" transform="translate(0 -6.53)" fill="#c6c0b2" />
            </g>
        </svg>

    )
}
